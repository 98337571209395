import { http } from "@/providers/http";
export class FlagApplicantApi {
  private static INSTANCE = new FlagApplicantApi();

  static get instance() {
    return this.INSTANCE;
  }

  public peersFlag(data: any) {
    const endpoint = "cfss/peers/flag";
    const headers = http.getTokenHeaders();
    return http
      .post(endpoint, headers, data)
      .then((response) => {
        return response;
      })
      .catch((err) => {
        return err;
      });
  }

  public peersFlagHistory(id: any) {
    const endpoint = "cfss/peers/flag";
    const headers = http.getTokenHeaders();
    return http
      .get(endpoint, headers, id)
      .then((response) => {
        return response;
      })
      .catch((err) => {
        return err;
      });
  }
  public FlagCertificateRevoke(data: any) {
    const endpoint = "cfss/certificate/revoke";
    const headers = http.getTokenHeaders();
    return http
      .put(endpoint, headers, data)
      .then((response) => {
        return response;
      })
      .catch((err) => {
        return err;
      });
  }
  public peersDisable(data: any) {
    const endpoint = "cfss/peers/disable";
    const headers = http.getTokenHeaders();
    return http
      .put(endpoint, headers, data)
      .then((response) => {
        return response;
      })
      .catch((err) => {
        return err;
      });
  }
}
export const flagApplicantApi = FlagApplicantApi.instance;
