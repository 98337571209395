import { Component, Prop, Vue } from "vue-property-decorator";
import { flagApplicantApi } from "@/providers/apis/peers-flag-applicant";
import AppLoaderComponent from "../app-loader/app-loader.component.vue";
@Component({
  components: {
    AppLoaderComponent,
  },
})
export default class FlagApplicantPopupComponent extends Vue {
  @Prop()
  public flagApplicantGrapqlRevoke: any = {};
  @Prop() public RevokeCertificatePopupClose: any;
  @Prop() public flagHistoryDataApi: any = {};
  @Prop() public profileData: any = [];
  @Prop() public certificationData: any = [];

  @Prop() public peerApplicationDisable: any;
  @Prop() public peerRevokeUnRevoke: any;
  @Prop() public revokeUnrevokeCertificate: any;
  public flagApplicantRevokeFormData: any = [];
  public flagApplicantRevokeNameGet: any = [];
  public revokeCertificateFlag: any = [];
  public revokeCertificatemessageInput: any = "";
  public errorMsgCheckBox = false;
  public flagLengthEmpty = false;
  public applicantFlagged = false;
  public FlagIsLoading = false;
  public revokeCertification = true;
  public revokeCertificationSuccessfully = false;
  public lengthCheck = "";
  public flagAplicantEvery = false;
  @Prop() public disablePeerProfileEnable: any;
  public enableFlagHistoryData: any = [];
  public created() {
    this.lengthCheck =
      this.flagHistoryDataApi.data.flags.length == 0
        ? this.flagHistoryDataApi.data.flags.length
        : this.flagHistoryDataApi.data.flags[0].flags.length;
    this.revokeCertificateFlag =
      this.flagHistoryDataApi.data.flags.length == 0
        ? this.revokeCertificateFlag
        : this.flagHistoryDataApi.data.flags[0].flags;

    this.flagApplicantRevokeFormData =
      this.flagApplicantGrapqlRevoke.data.peerFlagApplicant.data.attributes.form_fields.formInputs;
    this.flagHistoryApi();
  }

  public flagApplicantCheckBox() {
    this.flagApplicantRevokeNameGet =
      this.flagApplicantRevokeFormData[0][0].propBind.options.filter(
        (person: any) => this.revokeCertificateFlag.includes(person.item)
      );
    this.checkBoxGroupValidation();
  }
  public checkBoxGroupValidation() {
    if (this.revokeCertificateFlag.length == 0) {
      this.errorMsgCheckBox = true;
    } else {
      this.errorMsgCheckBox = false;
    }
  }
  public certificateRevokeApi(idGet: any) {
    flagApplicantApi
      .FlagCertificateRevoke({
        certificateNumber: this.certificationData[0].certificateNumber,
        flagId: idGet,
        revoke:
          this.peerRevokeUnRevoke == null ? true : !this.peerRevokeUnRevoke,
      })
      .then((res) => {
        if (res.status == 204) {
          this.FlagIsLoading = false;
          this.revokeCertificationSuccessfully = true;
          this.revokeCertification = false;
        }
      })
      .catch((error) => {
        this.FlagIsLoading = false;
      });
  }
  public revokeCertificatePopupClose() {
    this.$bvModal.hide("revoke-certificate-modal");
  }

  public disablePeerProfileAdmin(idGet: any) {
    this.FlagIsLoading = true;
    flagApplicantApi
      .peersDisable({
        id: this.$route.path.split("/")[3],
        flagId: idGet,
        disable:
          this.peerApplicationDisable == null
            ? true
            : !this.peerApplicationDisable,
      })
      .then((res) => {
        if (res.status == 204) {
          this.FlagIsLoading = false;
          this.revokeCertificationSuccessfully = true;
          this.revokeCertification = false;
        }
      })
      .catch((error) => {
        this.FlagIsLoading = false;
      });
  }
  public flagHistoryApi() {
    for (
      let index = 0;
      index < this.flagHistoryDataApi.data.flags.length;
      index++
    ) {
      this.flagHistoryDataApi.data.flags[index]["flagsData"] =
        this.flagApplicantRevokeFormData[0][0].propBind.options.filter(
          (person: any) =>
            this.flagHistoryDataApi.data.flags[index].flags.includes(
              person.item
            )
        );
      this.enableFlagHistoryData.push(
        this.flagHistoryDataApi.data.flags[index]
      );
    }
  }
  public submitEnableDisableRevoke() {
    this.$bvModal.hide("enable-disable-conformation-popup");
    this.FlagIsLoading = true;
    if (this.flagHistoryDataApi.data.flags.length > 0) {
      this.flagAplicantEvery =
        this.revokeCertificateFlag.length == this.lengthCheck &&
        this.revokeCertificateFlag.every((val: any) =>
          this.flagHistoryDataApi.data.flags[0].flags.includes(val)
        );
    }

    if (this.flagAplicantEvery) {
      const idGetFlags = this.flagHistoryDataApi.data.flags[0].id;
      if (this.disablePeerProfileEnable) {
        this.disablePeerProfileAdmin(idGetFlags);
      } else {
        this.certificateRevokeApi(idGetFlags);
      }
    } else {
      flagApplicantApi
        .peersFlag({
          id: this.$route.path.split("/")[3],
          flags: this.revokeCertificateFlag,
          comment: this.revokeCertificatemessageInput,
        })
        .then((res) => {
          if (res.status == 201) {
            const idGet = parseInt(res.headers.location);
            if (this.disablePeerProfileEnable) {
              this.disablePeerProfileAdmin(idGet);
            } else {
              this.certificateRevokeApi(idGet);
            }
          }
        })
        .catch((error) => {
          this.FlagIsLoading = false;
        });
    }
  }
  public cancelEnableDisableRevoke() {
    this.$bvModal.hide("enable-disable-conformation-popup");
  }
  public revokeCertificate() {
    this.checkBoxGroupValidation();
    this.$validator.validate().then((validate: any) => {
      if (validate) {
        if (this.revokeCertification) {
          if (this.revokeCertificateFlag.length == 0) {
            this.errorMsgCheckBox = true;
          } else {
            this.$bvModal.show("enable-disable-conformation-popup");
          }
        }
      }
    });
  }
}
