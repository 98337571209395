import { Component, Prop, Vue } from "vue-property-decorator";

@Component({})
export default class SuccessPopupComponent extends Vue {
  @Prop()
  public componentName!: string;
  @Prop()
  public successMessage!: string;

  public created() {
    this.$nextTick(() => {
      if (this.successMessage) {
        this.$bvModal.show("success-modal-center");
      }
    });
  }
  public closePopup() {
    this.$emit("closedSuccessPopup", true);
    this.$bvModal.hide("success-modal-center");
  }
}
