export const FOOTER_QUERY = `FooterMenu {  
    footerMenus(sort: "id:ASC"){
      data {
        id
        attributes {
            contactUs{
              label
              content
            }
            copyrights {
              policy
              links {
                label
                url
                slug
              }
            }
            Menus {
                label
                url
                slug
                sub_menus {
                  label
                  url
                  slug
                }
              }
        }
      }
    }
  }`;

export const FOOTER_METADATA_QUERY = `FooterMetadata {  
  footerMetadatas(sort: "id:ASC"){
      data {
        id
        attributes {
          logo {
            data {
              attributes {
                url
                name
              }
            }
          }
          TN_certified_logo {
               data {
                attributes {
                    name
                    url
                }
               }
          }
        }
      }
    }
  }`;
