import { HEADER_METADATA_QUERY, HEADER_QUERY } from "@/graphql/header-query";
import { utils } from "@/utils/utils";
import gql from "graphql-tag";
import { Component, Vue } from "vue-property-decorator";
import { HeaderMenu, HeaderLogo, HeaderButton } from "./cfss-header.interface";
import { loginApi } from "@/providers/apis/login";
import { appConfigService } from "@/providers/services/app/app-config";

@Component
export default class CfssHeader extends Vue {
  public tooglePrograms = false;
  public toogleAgencies = false;
  public tooglePeerSpecialists = false;
  public headerDatas: HeaderMenu[] = [];
  public HeaderLogoData: HeaderLogo[] = [];
  public headerLogoSrc = "";
  public hoverMenuSlug = "home";
  public headerLoginButton: HeaderButton = {
    label: "",
    url: "",
  };
  public headerLogoUrl = "";
  public isScroll = false;
  public isClickMenu = false;
  public isMobileView = false;
  public isShowMenu = false;
  public isRoute = false;
  public isShowSideBar = false;
  public isAlreadyExitsUser = false;
  public isReviewer = false;
  public loginDetails: any;
  public userDetails: any;
  public toogleStatus = false;
  public APP_CONFIG = "APP_CONFIG";
  public cadreUrl = "";
  public S3Path = "";
  public profileDetail: any;
  constructor() {
    super();
  }

  public async created() {
    this.S3Path = appConfigService.getS3FilesPathUrl();
    this.userDetails = utils.getUserDetails();
    const token = localStorage.getItem("access_token");
    const appConfig = localStorage.getItem(this.APP_CONFIG);
    if (appConfig) {
      this.cadreUrl = JSON.parse(appConfig)?.cadre_url;
    }
    this.profileDetail = utils.getProfileDetails();
    if (this.userDetails && token) {
      this.loginDetails = this.userDetails;
      this.isAlreadyExitsUser = true;
      const rolesData = this.loginDetails.roles;
      this.isReviewer = rolesData && rolesData.indexOf("REVIEWER") !== -1;
    }
    if (utils.checkIsMobileView() || utils.checkIsTabView()) {
      this.isMobileView = true;
    } else {
      this.isMobileView = false;
    }
    if (this.$router.currentRoute.path == "/home") {
      this.isRoute = true;
    } else {
      this.isRoute = false;
    }
    await this.$apollo
      .query({
        query: gql`query ${HEADER_QUERY}`,
      })
      .then((result) => {
        this.headerDatas = result.data.headerMenus.data;
      });
    await this.$apollo
      .query({
        query: gql`query ${HEADER_METADATA_QUERY}`,
      })
      .then((result) => {
        this.HeaderLogoData =
          result.data.headerMetadatas.data[0].attributes.logo.data.attributes;
        this.headerLogoUrl = result.data.headerMetadatas.data[0].attributes.url;
        this.headerLogoSrc =
          result.data.headerMetadatas.data[0].attributes.logo.data.attributes.url;
        this.headerLoginButton = {
          label: result.data.headerMetadatas.data[0].attributes.button.label,
          url: result.data.headerMetadatas.data[0].attributes.button.url,
        };
      });
  }

  public updated() {
    window.document.onscroll = () => {
      const navbar = document.getElementById("main-container");
      if (navbar) {
        if (window.scrollY > 55) {
          this.isScroll = true;
          this.isClickMenu = false;
          this.hoverMenuSlug = "";
        } else if (window.scrollY < 55) {
          this.isScroll = false;
        }
      }
      const navbarApplication = document.getElementById("sticky");
      if (navbarApplication) {
        if (window.scrollY > 55) {
          navbarApplication.className = "stick";
        } else if (window.scrollY < 55) {
          navbarApplication.className = "";
        }
      }
    };

    window.document.onclick = (event) => {
      this.outSideClick(event);
    };
  }

  public outSideClick(event: any) {
    const getDropDownDiv = document.getElementsByClassName(
      "header-updates-status-container"
    )[0];
    const getListsDiv = document.getElementsByClassName("menu-items")[0];
    if (getDropDownDiv && !getDropDownDiv.contains(event.target)) {
      this.toogleStatus = false;
    }

    if (getListsDiv && !getListsDiv.contains(event.target)) {
      this.isClickMenu = false;
      this.hoverMenuSlug = "";
      if (this.isMobileView) {
        this.isShowMenu = false;
      }
    }
  }

  public onClickMenu(menuSlug: string, index: number) {
    if (this.hoverMenuSlug === menuSlug) {
      this.isClickMenu = false;
      this.hoverMenuSlug = "";
    } else {
      this.isClickMenu = true;
      this.hoverMenuSlug = menuSlug;
      if (!this.headerDatas[index].attributes.menus.sub_menus.length) {
        if (this.headerDatas[index].attributes.menus.url.startsWith("http")) {
          window.location.href = this.headerDatas[index].attributes.menus.url;
        } else {
          this.$router
            .push(
              this.headerDatas[index].attributes.menus.url.includes("/", 0)
                ? this.headerDatas[index].attributes.menus.url
                : "/" + this.headerDatas[index].attributes.menus.url
            )
            .catch((err) => {
              console.error(err);
            });
        }
      }
    }
  }

  public cadreHomePage() {
    window.location.href = this.cadreUrl;
  }

  public logout() {
    loginApi
      .logOut()
      .then(() => {
        utils.deleteLocalStorage();
        this.isAlreadyExitsUser = false;
        this.$router.push("/home").catch(() => {
          //todo
        });
      })
      .catch((error) => {
        return error;
      });
  }
}
