export const RENEWAL_EMPLOYMENT_SUMMARY_FORM_QUERY = `EmploymentVolunteerSummaryForRenewal {  
    employmentVolunteerSummaryForRenewals(sort: "id:ASC"){
        data{
            id
            attributes{
                main_title
                application_name
                form_fields{
                    id
                    parent_slug
                    fields
                }
            }
        }
    }
}`;
