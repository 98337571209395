import { DEMOGRAPHIC_QUERY } from "@/graphql/demographic-query";
import { DemographicData } from "@/models/demographic/demographic.interface";
import gql from "graphql-tag";
import { Component, Prop, Vue } from "vue-property-decorator";
import RadioButtonComponent from "../radio-button/radio-button.component.vue";
import { utils } from "@/utils/utils";

@Component({
  name: "demographic-form",
  components: {
    "b-form-radio-group": RadioButtonComponent,
  },
})
export default class DemographicDataComponent extends Vue {
  public demographicData: DemographicData[] = [];

  @Prop()
  public demographicKey!: {
    payloadName: string;
    payloadSignatureKey: string;
  };
  @Prop()
  public demographic!: any;
  @Prop()
  public formType!: string;
  @Prop()
  public editForm!: boolean;

  public demographicPayload: any = {
    hispanicorLatino: {
      hispanicorLatino: "",
    },
    ethnicGroupYourself: {
      centralAmerican: "",
      cuban: "",
      dominican: "",
      mexican: "",
      southAmerican: "",
      others: "",
    },
    raceYourself: {
      blackAmerican: "",
      asian: "",
      nativeHawaiian: "",
      pacificIslander: "",
      white: "",
      americanIndian: "",
      dob: "",
    },
  };
  public currentRoute = "";
  public isDisable = false;

  public async created() {
    this.currentRoute = this.$route.path;
    if (this.currentRoute.split("/")[1] === "form") {
      let payloadStorage: any = localStorage.getItem(
        this.demographicKey.payloadName
      );
      payloadStorage = JSON.parse(payloadStorage);
      if (
        this.demographicKey.payloadSignatureKey &&
        payloadStorage &&
        payloadStorage[this.demographicKey.payloadSignatureKey].demographic
      ) {
        this.demographicPayload =
          payloadStorage[this.demographicKey.payloadSignatureKey].demographic;
      }
    } else {
      this.demographicPayload = this.demographic;
    }
    await this.$apollo
      .query({
        query: gql`query ${DEMOGRAPHIC_QUERY}`,
      })
      .then((result) => {
        this.demographicData = result.data.demographicDatas.data;
        this.isDisable = utils.disableField(this.editForm, this.formType);
      });
  }

  public updated() {
    if (
      this.demographicPayload.hispanicorLatino.hispanicorLatino === "No" ||
      this.demographicPayload.hispanicorLatino.hispanicorLatino === "Refused"
    ) {
      Object.keys(this.demographicPayload.ethnicGroupYourself).forEach(
        (key) => {
          this.demographicPayload.ethnicGroupYourself[key] = "";
        }
      );
    }
    this.$emit("input", this.demographicPayload);
  }

  public fieldUpdate(groupName: string) {
    if (groupName === "ethnicGroupYourself") {
      if (
        this.demographicPayload.hispanicorLatino.hispanicorLatino === "" ||
        this.demographicPayload.hispanicorLatino.hispanicorLatino === "No" ||
        this.demographicPayload.hispanicorLatino.hispanicorLatino === "Refused"
      ) {
        return true;
      } else {
        return false;
      }
    }
  }
}
