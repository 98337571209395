import { Component, Vue } from "vue-property-decorator";
import CfssHeader from "../cfss-header/cfss-header.vue";
import CfssFooterComponent from "../cfss-footer/cfss-footer.component.vue";
@Component({
  components: {
    CfssHeader,
    CfssFooterComponent,
  },
})
export default class BaseLayout extends Vue {
  public headerHeight = 0;
  mounted() {
    const getHeaderElement = document.getElementById("header");
    this.headerHeight = getHeaderElement?.scrollHeight as number;
  }
}
