export const MAIN_CONTENT_QUERY = `CfssContents {
  cfssContents(sort: "id:ASC") {
      data {
        id
        attributes {
          title
          description
        }
      }
    }
  }`;

export const HANDBOOK_QUERY = `CfssHandbooks {  
    cfssHandbooks(sort: "id:ASC"){
      data {
        id
        attributes {
            card_component{
                id
                title
                description
                url
                docKeyName
                slug
                uploadedFileName
            }
        }
      }
    }
  }`;
