var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{attrs:{"id":"certification-preview-form-container","tabindex":"0"}},[_c('div',{staticClass:"form-content"},[_vm._l((_vm.formData),function(formContent,index){return _c('div',{key:index,staticClass:"preview-form container-form",class:formContent.parent_slug},[(formContent && index < _vm.limitationList)?_c('div',[(
            (_vm.isReviewerPreviewForm &&
              formContent.fields.parentSlug === _vm.tabSelect) ||
            (_vm.isReviewerPreviewForm && _vm.isMobileView) ||
            !_vm.isReviewerPreviewForm
          )?_c('b-form',{staticClass:"preview-form-container",class:formContent.fields.class},[(
              formContent.parent_slug !==
                'certificationPartTwoRefererenceInformation' &&
              formContent.parent_slug !==
                'certificationPartTwoSupervisorInformation' &&
              _vm.originalData[formContent.parent_slug]
            )?_c('div',[(!_vm.isReviewerPreviewForm && !_vm.isUserApplication)?_c('div',{staticClass:"form-edit"},[_c('div',{staticClass:"description"},[_c('h1',{staticClass:"form-title"},[_vm._v(" "+_vm._s(formContent.fields.formHeader)+" ")])]),(_vm.currentRoute.split('/')[1] === 'form')?_c('div',{staticClass:"description"},[_c('h1',{staticClass:"form-title edit",on:{"click":function($event){return _vm.editForm(formContent.parent_slug)}}},[_vm._v(" Edit "),_c('b-icon',{staticClass:"icon-pen",attrs:{"icon":"pencil-fill"}})],1)]):_vm._e()]):_vm._e(),(
                _vm.isReviewerPreviewForm && _vm.isMobileView
                  ? _vm.isMigrationForm
                    ? formContent.parent_slug !== 'certificationSignature'
                    : true
                  : false
              )?_c('div',{staticClass:"form-edit"},[_c('div',{staticClass:"description"},[_c('h1',{staticClass:"form-title"},[_vm._v(" "+_vm._s(formContent.fields.formHeader)+" ")])])]):_vm._e(),(
                (_vm.isReviewerPreviewForm &&
                  _vm.isMobileView &&
                  formContent.parent_slug ===
                    'certificationPartTwoApplicationForm') ||
                (!_vm.isMobileView &&
                  _vm.isReviewerPreviewForm &&
                  (_vm.progressStatus === 'RECEIVED' ||
                    _vm.progressStatus === 'PROCESSING' ||
                    _vm.progressStatus === 'NEED MORE INFO' ||
                    _vm.progressStatus === 'WAITING FOR TRAINING' ||
                    _vm.progressStatus === 'COMPLETED TRAINING'))
              )?_c('div',{staticClass:"reviewer-form-edit"},[(
                  _vm.isEditReviewerForm &&
                  _vm.tabSelect !== 'certification-supervisor-details' &&
                  _vm.tabSelect !== 'certification-references' &&
                  _vm.tabSelect != 'scope-activities' &&
                  _vm.tabSelect !== 'certification-signature'
                )?_c('b-button',{staticClass:"next-btn btn",attrs:{"variant":"outline-primary","type":"button"},on:{"click":function($event){return _vm.onCancelEdit()}}},[_c('b-icon',{staticClass:"check-icon",attrs:{"icon":"x-lg"}}),_vm._v(" Cancel ")],1):_vm._e(),(
                  !_vm.isEditReviewerForm &&
                  _vm.tabSelect !== 'certification-supervisor-details' &&
                  _vm.tabSelect !== 'certification-references' &&
                  _vm.tabSelect != 'scope-activities' &&
                  _vm.tabSelect !== 'certification-signature'
                )?_c('b-button',{staticClass:"next-btn btn",attrs:{"variant":"outline-primary","type":"button"},on:{"click":function($event){return _vm.onEditReference(index)}}},[_c('b-icon',{staticClass:"check-icon",attrs:{"icon":"pencil-fill"}}),_vm._v(" Edit ")],1):_vm._e(),(
                  _vm.isEditReviewerForm &&
                  _vm.tabSelect !== 'certification-supervisor-details' &&
                  _vm.tabSelect !== 'certification-references' &&
                  _vm.tabSelect != 'scope-activities' &&
                  _vm.tabSelect !== 'certification-signature'
                )?_c('b-button',{staticClass:"next-btn btn",attrs:{"variant":"outline-primary","type":"button"},on:{"click":function($event){return _vm.onCilckSave()}}},[_c('b-icon',{staticClass:"check-icon",attrs:{"icon":"file-earmark-medical"}}),_vm._v(" Save ")],1):_vm._e()],1):_vm._e(),_vm._l((formContent.fields
                .formInputs),function(formInputDatas,indexi){return _c('div',{key:indexi},[(
                  !_vm.isReviewerPreviewForm ||
                  (_vm.isReviewerPreviewForm &&
                    (_vm.isMobileView
                      ? _vm.isMigrationForm
                        ? formContent.parent_slug !== 'certificationSignature'
                        : true
                      : true || formContent.fields.parentSlug === _vm.tabSelect))
                )?[_c('div',{class:[
                    'fields-main-container',
                    formInputDatas[0].mainClass,
                  ]},[_c('div',{staticClass:"small-content form-div",class:[
                      formInputDatas.length == 2
                        ? 'double-input'
                        : formInputDatas.length > 2
                        ? 'multi-input'
                        : '',
                    ]},_vm._l((formInputDatas),function(formInput,indexj){return _c('div',{key:indexj,staticClass:"form-label",class:[
                        _vm.isMigrationForm &&
                        !_vm.originalData[formContent.parent_slug][formInput.name]
                          ? 'display-none'
                          : '',
                        _vm.fieldShowAndHide(formInput.name) === true
                          ? 'display-none'
                          : ' ',
                        formInput.subClass,
                        formInput.field === 'b-form-textarea'
                          ? 'textarea-field'
                          : '',
                        _vm.classNameChooser(formInput?.class),
                      ]},[(!_vm.hideDiv.includes(formInput.name))?[(!formInput.label)?_c('div',{class:'label-container'},[_c('span',{staticClass:"visiblity-hidden label"},[_vm._v(" "+_vm._s(formInput.label)+" ")])]):_vm._e(),(formInput.label)?_c('div',{class:formInput.label !== null
                              ? 'label-container'
                              : 'display-label'},[(formInput.label)?_c('span',{staticClass:"label",domProps:{"innerHTML":_vm._s(formInput.label + `&nbsp;&nbsp;`)}}):_vm._e(),(
                              formInput.label &&
                              (formInput?.validate?.required === true ||
                                formInput?.fileValid?.veeValidate ===
                                  'required')
                            )?_c('span',{staticClass:"text-danger"},[_vm._v("*")]):_vm._e(),(formInput.labelDescription)?_c('label',{staticClass:"label-span"},[_vm._v(_vm._s(formInput.labelDescription))]):_vm._e()]):_vm._e(),_c(formInput.field,_vm._b({directives:[{name:"validate",rawName:"v-validate",value:(formInput?.validate?.veeValidate),expression:"formInput?.validate?.veeValidate"}],key:_vm.isEditReviewerForm,tag:"component",class:[formInput.class],attrs:{"name":formInput?.validate?.name,"editForm":_vm.isEditReviewerForm === true &&
                            _vm.tabSelect !==
                              'certification-supervisor-details' &&
                            _vm.tabSelect !== 'certification-references' &&
                            formInput.field !== 'scope-of-activity' &&
                            formContent.parent_slug !==
                              'certificationPartTwoSupervisorInformation' &&
                            formContent.parent_slug !==
                              'certificationPartTwoRefererenceInformation' &&
                            formContent.parent_slug !==
                              'certificationSignature'
                              ? true
                              : false,"formType":'preview',"experienceInformationData":_vm.previewPayload &&
                            _vm.previewPayload[
                              'certificationPartTwoApplicationForm'
                            ].experienceInformation,"experienceKey":{
                            payloadName: 'certificationFormPayload',
                            payloadSignatureKey:
                              'certificationPartTwoApplicationForm',
                          },"signature":_vm.previewData &&
                            _vm.previewData['certificationSignature'],"signatureType":_vm.currentRoute.split('/')[1] === 'form'
                              ? {
                                  payloadName: 'certificationFormPayload',
                                  payloadSignatureKey:
                                    'certificationSignature',
                                  resetButton: false,
                                }
                              : {},"signatureLabelAndValidate":{
                            label: formInput?.signatureLabel,
                            validate: formInput?.signatureValidate,
                          },"demographicKey":{
                            payloadName: 'certificationFormPayload',
                            payloadSignatureKey: 'certificationSignature',
                          },"demographic":_vm.previewPayload &&
                            _vm.previewPayload['certificationSignature']
                              .demographic,"previewScopeOfActivityData":{
                            payloadName:
                              _vm.currentRoute.split('/')[1] === 'form'
                                ? 'partTwoCertificatePayload'
                                : '',
                            data: _vm.previewPayload[formContent.parent_slug][
                              'handbook'
                            ],
                          },"attachmentData":{
                            files:
                              _vm.previewPayload[formContent.parent_slug][
                                formInput.name
                              ],
                            payloadName: '',
                            authorizationDoc: formInput.authorizationDoc
                              ? formInput.authorizationDoc
                              : null,
                          },"autocomplete":"off","textAreaMaxlength":formInput?.propBind?.maxlength,"isEditReviewerForm":_vm.isEditReviewerForm,"isPreviewForm":true,"formIndex":indexj,"payloadDataAll":_vm.previewPayload,"document":formInput.name,"documentName":formInput.title,"isEditFile":_vm.isEditReviewerForm,"keyName":formInput.name},on:{"input":function($event){return _vm.fieldUpdated(formInput.name)},"isNotUploadedKeyName":function($event){return _vm.notFileUploaded($event)},"isUploadedKeyName":function($event){return _vm.allFilesUploaded($event)}},nativeOn:{"keypress":function($event){_vm.eventBind[formInput?.propBind?.eventName]
                              ? _vm.fieldValidation(
                                  $event,
                                  formInput?.propBind?.eventName,
                                  formInput?.propBind.type,
                                  formInput?.validate?.maxLength,
                                  formInput?.name
                                )
                              : _vm.emptyEvent}},model:{value:(
                            _vm.previewPayload[formContent.parent_slug][
                              formInput.name
                            ]
                          ),callback:function ($$v) {_vm.$set(_vm.previewPayload[formContent.parent_slug], 
                              formInput.name
                            , $$v)},expression:"\n                            previewPayload[formContent.parent_slug][\n                              formInput.name\n                            ]\n                          "}},'component',{
                            ...formInput.propBind,
                            disabled:
                              _vm.isEditReviewerForm === true &&
                              _vm.tabSelect !==
                                'certification-supervisor-details' &&
                              _vm.tabSelect !== 'certification-references' &&
                              formInput.field !== 'scope-activities' &&
                              formContent.parent_slug !==
                                'certificationPartTwoSupervisorInformation' &&
                              formContent.parent_slug !==
                                'certificationPartTwoRefererenceInformation' &&
                              formContent.parent_slug !==
                                'certificationSignature'
                                ? false
                                : true,
                          },false)),(_vm.isEditReviewerForm && formInput.subLabel)?_c('label',{class:formInput.subLabel !== null ||
                            formInput.subLabel !== ''
                              ? 'label-span'
                              : 'display-none'},[_vm._v(_vm._s(formInput.subLabel))]):_vm._e(),(
                            formInput.field === 'b-form-textarea' &&
                            formInput.propBind.maxlength &&
                            _vm.isEditReviewerForm &&
                            _vm.isReviewerPreviewForm
                          )?_c('div',{staticClass:"word-count-div"},[_c('p',{staticClass:"word-count"},[_vm._v(" "+_vm._s(_vm.previewPayload[formContent.parent_slug][ formInput.name ].length)+"/"+_vm._s(formInput.propBind.maxlength)+" ")])]):_vm._e(),(
                            _vm.checkValidation &&
                            _vm.$validator.errors.has(formInput?.validate?.name)
                          )?_c('p',{staticClass:"is-invalid"},[_vm._v(" "+_vm._s(formInput.propBind.type === "email" || formInput.name === "socialSecurityNumber" || formInput.name === "zip" ? _vm.$validator.errors.first( formInput?.validate?.name ) : formInput.propBind.type === "date" ? "Please enter valid date" : formInput.name === "state" ? "This field must be 2 letter" : "This field is required")+" ")]):_vm._e()]:_vm._e()],2)}),0)])]:_vm._e()],2)})],2):_vm._e(),(
              formContent.parent_slug ===
                'certificationPartTwoSupervisorInformation' &&
              _vm.originalData[formContent.parent_slug]
            )?_c('div',[(!_vm.isReviewerPreviewForm && !_vm.isUserApplication)?_c('div',{staticClass:"form-edit"},[_c('div',{staticClass:"description"},[_c('h1',{staticClass:"form-title"},[_vm._v(" "+_vm._s(formContent.fields.formHeader)+" ")])]),(_vm.currentRoute.split('/')[1] === 'form')?_c('div',{staticClass:"description"},[_c('h1',{staticClass:"form-title edit",on:{"click":function($event){return _vm.editForm(formContent.parent_slug)}}},[_vm._v(" Edit "),_c('b-icon',{staticClass:"icon-pen",attrs:{"icon":"pencil-fill"}})],1)]):_vm._e()]):_vm._e(),_vm._l((_vm.supervisorFormFields),function(formInputData,idx){return _c('div',{key:idx},[_c('div',{staticClass:"supervisor-title title-loop"},[_c('p',{staticClass:"supervisor-word-count"},[_vm._v(" "+_vm._s(formInputData.title + " " + (idx + 1))+" ")]),(
                    _vm.isUserApplication &&
                    _vm.supervisorList[idx].email ===
                      _vm.previewPayload[formContent.parent_slug][idx][
                        'supervisorEmail'
                      ]
                  )?_c('div',{staticClass:"status",class:_vm.statusWordChange(_vm.supervisorList[idx].status),domProps:{"innerHTML":_vm._s(_vm.supervisorList[idx].status.toLocaleLowerCase())}}):_vm._e(),(
                    _vm.progressStatus === 'RECEIVED' ||
                    _vm.progressStatus === 'PROCESSING' ||
                    _vm.progressStatus === 'NEED MORE INFO' ||
                    _vm.progressStatus === 'WAITING FOR TRAINING' ||
                    _vm.progressStatus === 'COMPLETED TRAINING'
                  )?_c('div',{staticClass:"edit-container"},[(_vm.editIndex !== idx)?_c('b-button',{staticClass:"edit-btn btn",attrs:{"variant":"outline-primary","type":"button"},on:{"click":function($event){return _vm.onEditReference(idx)}}},[_c('b-icon',{staticClass:"check-icon",attrs:{"icon":"pencil-fill"}}),_vm._v(" Edit ")],1):_vm._e(),(_vm.isEditReviewerForm && _vm.editIndex === idx)?_c('b-button',{staticClass:"next-btn btn",attrs:{"variant":"outline-primary","type":"button"},on:{"click":_vm.onCancelEdit}},[_c('b-icon',{staticClass:"check-icon",attrs:{"icon":"x-lg"}}),_vm._v(" Cancel ")],1):_vm._e(),(_vm.isEditReviewerForm && _vm.editIndex === idx)?_c('b-button',{staticClass:"save-btn btn",attrs:{"variant":"outline-primary","type":"button"},on:{"click":_vm.onCilckSave}},[_c('b-icon',{staticClass:"check-icon",attrs:{"icon":"file-earmark-medical"}}),_vm._v(" Save ")],1):_vm._e()],1):_vm._e()]),_vm._l((formInputData.formInputs),function(formInputs,indexj){return _c('div',{key:indexj,class:['fields-main-container', formInputs[0].mainClass]},[_c('div',{staticClass:"small-content form-div",class:[
                    formInputs.length == 2
                      ? 'double-input'
                      : formInputs.length > 2
                      ? 'multi-input'
                      : '',
                  ]},_vm._l((formInputs),function(formInput,indexk){return _c('div',{key:indexk,staticClass:"form-label",class:[
                      _vm.fieldShowAndHide(
                        formInput.name,
                        'certificationPartTwoSupervisorInformation'
                      ) === true
                        ? 'display-none'
                        : '',
                      formInput.subClass,
                      _vm.isMigrationForm &&
                      !_vm.originalData[formContent.parent_slug][idx][
                        formInput.name
                      ]
                        ? 'display-none'
                        : '',
                      formInput.field === 'b-form-textarea'
                        ? 'textarea-field'
                        : '',
                      _vm.classNameChooser(formInput?.class),
                    ]},[(
                        formInput.name === 'startDate'
                          ? _vm.supervisorStartDateShowIdx.includes(idx)
                          : formInput.name === 'endDate'
                          ? _vm.supervisorEndDateShowIdx.includes(idx)
                          : true
                      )?[(!formInput.label)?_c('div',{class:'label-container'},[_c('span',{staticClass:"visiblity-hidden label"},[_vm._v(" "+_vm._s(formInputs[0].label)+" ")])]):_vm._e(),(formInput.label)?_c('div',{class:formInput.label !== null
                            ? 'label-container'
                            : 'display-label'},[(formInput.label)?_c('span',{staticClass:"label",domProps:{"innerHTML":_vm._s(formInput.label + `&nbsp;&nbsp;`)}}):_vm._e(),(
                            formInput.label &&
                            (formInput?.validate?.required === true ||
                              formInput?.fileValid?.veeValidate ===
                                'required')
                          )?_c('span',{staticClass:"text-danger"},[_vm._v("*")]):_vm._e(),(formInput.labelDescription)?_c('label',{staticClass:"label-span"},[_vm._v(_vm._s(formInput.labelDescription))]):_vm._e()]):_vm._e(),_c(formInput.field,_vm._b({directives:[{name:"validate",rawName:"v-validate",value:(formInput?.validate?.veeValidate),expression:"formInput?.validate?.veeValidate"}],key:_vm.isEditReviewerForm,tag:"component",class:[
                          formInput.class,
                          'edit-form-disable',
                          formInput.name === 'supervisorEmail'
                            ? _vm.previewPayload[formContent.parent_slug][idx][
                                'status'
                              ] === 'PENDING'
                              ? ''
                              : 'disable'
                            : '',
                        ],attrs:{"formIndex":idx,"name":formInput?.name + idx,"autocomplete":"off","textAreaMaxlength":formInput?.propBind?.maxlength,"formInput":formInput.propBind,"editForm":_vm.isEditReviewerForm === true ? true : false,"formType":'preview',"disabled":_vm.disabled,"tabindex":formInput.name === 'supervisorEmail'
                            ? _vm.previewPayload[formContent.parent_slug][idx][
                                'status'
                              ] === 'PENDING'
                              ? ''
                              : -1
                            : ''},on:{"input":function($event){return _vm.fieldUpdated(formInput.name, idx)}},nativeOn:{"keypress":function($event){_vm.eventBind[formInput?.propBind?.eventName]
                            ? _vm.fieldValidation(
                                $event,
                                formInput?.propBind?.eventName,
                                formInput?.propBind.type,
                                formInput?.validate?.maxLength,
                                formInput?.name
                              )
                            : _vm.emptyEvent}},model:{value:(
                          _vm.previewPayload[formContent.parent_slug][idx][
                            formInput.name
                          ]
                        ),callback:function ($$v) {_vm.$set(_vm.previewPayload[formContent.parent_slug][idx], 
                            formInput.name
                          , $$v)},expression:"\n                          previewPayload[formContent.parent_slug][idx][\n                            formInput.name\n                          ]\n                        "}},'component',{
                          ...formInput.propBind,
                          disabled: true,
                        },false)),_c('label',{staticClass:"label-span"},[_vm._v(_vm._s(formInput.subLabel))]),(
                          _vm.checkValidation &&
                          _vm.$validator.errors.has(formInput?.name + idx) &&
                          _vm.editIndex === idx
                        )?_c('p',{staticClass:"is-invalid"},[_vm._v(" "+_vm._s(formInput.propBind.type === "email" ? _vm.$validator.errors.first(formInput?.name + idx) : "This field is required")+" ")]):_vm._e()]:_vm._e()],2)}),0)])})],2)})],2):_vm._e(),(
              formContent.parent_slug ===
                'certificationPartTwoRefererenceInformation' &&
              _vm.originalData[formContent.parent_slug]
            )?_c('div',[(
                !_vm.isReviewerPreviewForm ||
                (_vm.isReviewerPreviewForm && _vm.isMobileView)
              )?_c('div',{staticClass:"form-edit"},[_c('div',{staticClass:"description"},[_c('h1',{staticClass:"form-title"},[_vm._v(" "+_vm._s(formContent.fields.formHeader)+" ")])]),(_vm.currentRoute.split('/')[1] === 'form')?_c('div',{staticClass:"description"},[_c('h1',{staticClass:"form-title edit",on:{"click":function($event){return _vm.editForm(formContent.parent_slug)}}},[_vm._v(" Edit "),_c('b-icon',{staticClass:"icon-pen",attrs:{"icon":"pencil-fill"}})],1)]):_vm._e()]):_vm._e(),_vm._l((_vm.addReferenceForm),function(addReferenceFormList,indexi){return _c('div',{key:indexi,staticClass:"add-reference-div"},[(!_vm.isReviewerPreviewForm)?_c('div',{staticClass:"reference-header title-loop"},[_c('p',{staticClass:"reference-word-count"},[_vm._v(" Reference "+_vm._s(indexi + 1)+" ")])]):_vm._e(),(_vm.isReviewerPreviewForm)?_c('div',{staticClass:"reference-header title-loop"},[_c('p',{staticClass:"reference-word-count"},[_vm._v(" Reference "+_vm._s(indexi + 1)+" ")]),(
                    _vm.isUserApplication &&
                    _vm.referenceList[indexi].email ===
                      _vm.previewPayload[formContent.parent_slug][indexi][
                        'referenceEmailAddress'
                      ]
                  )?_c('div',{staticClass:"reference-status status",class:_vm.statusWordChange(_vm.referenceList[indexi].status),domProps:{"innerHTML":_vm._s(_vm.referenceList[indexi].status.toLocaleLowerCase())}}):_vm._e(),(
                    _vm.progressStatus === 'RECEIVED' ||
                    _vm.progressStatus === 'PROCESSING' ||
                    _vm.progressStatus === 'NEED MORE INFO' ||
                    _vm.progressStatus === 'WAITING FOR TRAINING' ||
                    _vm.progressStatus === 'COMPLETED TRAINING'
                  )?_c('div',{staticClass:"edit-container"},[(_vm.editIndex !== indexi)?_c('b-button',{staticClass:"edit-btn btn",attrs:{"variant":"outline-primary","type":"button"},on:{"click":function($event){return _vm.onEditReference(indexi)}}},[_c('b-icon',{staticClass:"check-icon",attrs:{"icon":"pencil-fill"}}),_vm._v(" Edit ")],1):_vm._e(),(_vm.isEditReference && _vm.editIndex === indexi)?_c('b-button',{staticClass:"next-btn btn",attrs:{"variant":"outline-primary","type":"button"},on:{"click":_vm.onCancelEdit}},[_c('b-icon',{staticClass:"check-icon",attrs:{"icon":"x-lg"}}),_vm._v(" Cancel ")],1):_vm._e(),(_vm.isEditReference && _vm.editIndex === indexi)?_c('b-button',{staticClass:"save-btn btn",attrs:{"variant":"outline-primary","type":"button"},on:{"click":_vm.onCilckSave}},[_c('b-icon',{staticClass:"check-icon",attrs:{"icon":"file-earmark-medical"}}),_vm._v(" Save ")],1):_vm._e()],1):_vm._e()]):_vm._e(),_vm._l((addReferenceFormList.formInputs),function(formInputData,indexj){return _c('div',{key:indexj,class:['fields-main-container', formInputData[0].mainClass]},[_c('div',{staticClass:"small-content form-div",class:[
                    formInputData.length == 2
                      ? 'double-input'
                      : formInputData.length > 2
                      ? 'multi-input'
                      : '',
                  ]},_vm._l((formInputData),function(formInput,indexk){return _c('div',{key:indexk,staticClass:"form-label",class:[
                      _vm.fieldShowAndHide(
                        formInput.name,
                        'certificationPartTwoRefererenceInformation'
                      ) === true
                        ? 'display-none'
                        : '',
                      formInput.subClass,
                      _vm.isMigrationForm &&
                      !_vm.originalData[formContent.parent_slug][indexi][
                        formInput.name
                      ]
                        ? 'display-none'
                        : '',
                      formInput.field === 'b-form-textarea'
                        ? 'textarea-field'
                        : '',
                      _vm.classNameChooser(formInput?.class),
                    ]},[[(!formInput.label)?_c('div',{class:'label-container'},[_c('span',{staticClass:"visiblity-hidden label"},[_vm._v(" "+_vm._s(formInputData[0].label)+" ")])]):_vm._e(),(formInput.label)?_c('div',{class:formInput.label !== null
                            ? 'label-container'
                            : 'display-label'},[(formInput.label)?_c('span',{staticClass:"label",domProps:{"innerHTML":_vm._s(formInput.label + `&nbsp;&nbsp;`)}}):_vm._e(),(formInput?.validate?.required === true)?_c('span',{staticClass:"text-danger"},[_vm._v("*")]):_vm._e()]):_vm._e(),_c('div',[_c(formInput.field,_vm._b({directives:[{name:"validate",rawName:"v-validate",value:(formInput?.validate?.veeValidate),expression:"formInput?.validate?.veeValidate"}],key:_vm.isEditReviewerForm,tag:"component",class:[
                            formInput.name === 'referenceEmailAddress'
                              ? _vm.previewPayload[formContent.parent_slug][
                                  indexi
                                ]['status'] === 'PENDING'
                                ? ''
                                : 'disable'
                              : '',
                            formInput.class,
                            'edit-form-disable',
                          ],attrs:{"name":formInput?.validate?.name + ' ' + indexi,"formInput":formInput.propBind,"keyName":formInput.name,"autocomplete":"off","formIndex":indexi,"textAreaMaxlength":formInput?.propBind?.maxlength,"editForm":_vm.isEditReviewerForm === true &&
                            _vm.tabSelect !== 'partTwo-code-of-ethics' &&
                            _vm.tabSelect !== 'partTwo-signature'
                              ? true
                              : false,"formType":'preview',"disabled":_vm.editIndex === indexi ? false : true,"isPreviewForm":true,"isEditReviewerForm":_vm.isEditReviewerForm,"tabindex":[
                            formInput.name === 'referenceEmailAddress'
                              ? _vm.previewPayload[formContent.parent_slug][
                                  indexi
                                ]['status'] === 'PENDING'
                                ? ''
                                : -1
                              : '',
                          ]},nativeOn:{"keypress":function($event){_vm.eventBind[formInput?.propBind?.eventName]
                              ? _vm.fieldValidation(
                                  $event,
                                  formInput?.propBind?.eventName,
                                  formInput?.propBind.type,
                                  formInput?.validate?.maxLength,
                                  formInput?.name
                                )
                              : _vm.emptyEvent}},model:{value:(
                            _vm.previewPayload[formContent.parent_slug][indexi][
                              formInput.name
                            ]
                          ),callback:function ($$v) {_vm.$set(_vm.previewPayload[formContent.parent_slug][indexi], 
                              formInput.name
                            , $$v)},expression:"\n                            previewPayload[formContent.parent_slug][indexi][\n                              formInput.name\n                            ]\n                          "}},'component',{ ...formInput.propBind, disabled: true },false)),(formInput.subLabel)?_c('label',{staticClass:"label-span"},[_vm._v(_vm._s(formInput.subLabel))]):_vm._e(),(
                            _vm.checkValidation &&
                            _vm.$validator.errors.has(
                              formInput?.validate?.name + ' ' + indexi
                            ) &&
                            _vm.editIndex === indexi
                          )?_c('p',{staticClass:"is-invalid"},[_vm._v(" "+_vm._s(formInput.propBind.type === "email" ? _vm.$validator.errors.first( formInput?.validate?.name + " " + indexi ) : "This field is required")+" ")]):_vm._e()],1)]],2)}),0)])})],2)})],2):_vm._e()]):_vm._e()],1):_vm._e()])}),(_vm.editApplication)?_c('EditHistoryComponent',{attrs:{"previewPayload":_vm.previewPayload,"formData":_vm.formData,"tabSelect":_vm.tabSelect,"isClickedSave":_vm.isClickSavebtn,"originalData":_vm.originalData,"editIndex":_vm.editIndex},on:{"editedData":function($event){return _vm.editedPreviewPayloadData($event)},"isConfirmChanges":function($event){return _vm.isConfirmChanges($event)},"isRevertedChanges":function($event){return _vm.isRevertedChanges($event)}}}):_vm._e()],2),(_vm.errorMessage)?_c('ErrorPopupComponent',{attrs:{"componentName":'-error-popup',"errorMessage":_vm.errorMessage},on:{"closdedErrorPopup":function($event){return _vm.closdedErrorPopup($event)}}}):_vm._e(),(_vm.isLoading)?_c('span',{staticClass:"loading-container"},[_c('p',[_vm._v("Loading...")])]):_vm._e(),(_vm.isLargeLoading)?_c('AppLoaderComponent',{attrs:{"type":'large'}}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }