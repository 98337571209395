import { notAcceptedEmailApi } from "@/providers/apis/not-accepted-email-form";
import { Component, Prop, Vue } from "vue-property-decorator";
import PreviewPopupComponent from "../preview-popup/preview-popup.component.vue";
import { utils } from "@/utils/utils";
import { appConfigService } from "@/providers/services/app/app-config";
import moment from "moment";

@Component({
  name: "email-modal-popup",
  components: {
    PreviewPopupComponent,
  },
})
export default class EmailModalPopupComponent extends Vue {
  @Prop()
  public applicationEmailData: any;

  @Prop()
  public emailData: any;

  public applicationId: any;
  public toEmail: any;
  public chips: any = [];
  public file: any = null;
  public messageInput = "";
  public subjectInput = "";
  public fileName = "";
  public sentEmailHistory: any = [];
  public isSending = false;
  public previewFile!: any;
  public files: File[] = [];
  public set = {
    type: true,
    default: true,
  };
  public isLoading = true;
  public checkValid = false;
  public previewFileData: any[] = [];
  public showPreviewPopup = false;
  public imgType = ["jpg", "png", "jpeg", "tiff"];
  public S3Path = "";

  public created() {
    this.S3Path = appConfigService.getS3FilesPathUrl();
    if (this.applicationEmailData) {
      const applicantDetials = this.applicationEmailData.item;
      if (applicantDetials.applicationName === "PART TWO") {
        this.applicationEmailData.item.email =
          this.applicationEmailData.item.data.certificationPartTwoApplicationForm.email;
      }
      if (applicantDetials.applicationName === "RENEWAL") {
        this.applicationEmailData.item.email =
          this.applicationEmailData.item.data.renewalApplicationInformation.email;
      }
      if (applicantDetials.applicationName === "INACTIVE") {
        this.applicationEmailData.item.email =
          this.applicationEmailData.item.data.certificationInActiveBasicDetails.email;
      }
      this.toEmail = applicantDetials.email;
      this.applicationId = applicantDetials.id;
      this.$bvModal.show("modal-center");
    }
  }
  public closePopup() {
    this.$bvModal.hide("modal-center");
  }

  public async onSendEmail() {
    this.checkValid = true;
    this.$validator.validate().then((validate: any) => {
      if (validate) {
        this.isSending = true;
        const formdata = new FormData();
        formdata.append("applicationId", this.applicationId);
        formdata.append("email", this.toEmail);
        formdata.append("ccAddress", this.chips);
        formdata.append("subject", this.subjectInput);
        formdata.append(
          "message",
          JSON.parse(
            utils.textAreaTagsConversion(JSON.stringify(this.messageInput))
          )
        );
        for (let index = 0; index < this.files.length; index++) {
          formdata.append("files", this.files[index]);
        }

        notAcceptedEmailApi
          .sendEmailData(formdata)
          .then((data) => {
            this.isSending = false;
            this.$emit("emailSended", true);
          })
          .catch(() => {
            this.$emit("emailSended", false);
            this.isSending = false;
          });
      }
    });
  }

  public onGetEmailHistory() {
    notAcceptedEmailApi
      .getEmailHistory(this.applicationId)
      .then((result) => {
        this.sentEmailHistory = result.data.data;
        this.sentEmailHistory.map((history: any) => {
          history.receivedDate = moment(history.receivedDate).format(
            "MM-DD-YYYY HH:mm:ss:SSS"
          );
        });
        this.isLoading = false;
      })
      .catch((e) => {
        this.isLoading = false;
      });
  }

  public handleFileSelect(evt: any) {
    evt.target.files.forEach((data: File) => {
      this.files.push(data);
    });
  }

  public emailAttachmentPreview(index: number, attachmentIdx: number) {
    this.previewFile = this.sentEmailHistory[index].attachments[attachmentIdx];
    if (this.previewFile) {
      if (
        this.imgType.includes(this.previewFile.uploadedFileName.split(".")[1])
      ) {
        const img = new Image();
        img.src = `${this.S3Path}/${this.previewFile.uploadedFileName}`;
        this.previewFile.width = img.width;
        this.previewFile.height = img.height;
      }
      this.previewFileData = [this.previewFile];
      this.showPreviewPopup = true;
    }
  }

  public preview(index: number) {
    this.previewFile = this.files[index];
    if (this.previewFile) {
      if (this.previewFile.type.split("/")[0] === "application") {
        const file = new Blob([this.previewFile], {
          type: this.previewFile.type,
        });
        const fileURL = URL.createObjectURL(file);
        this.previewFileData = [
          {
            url: fileURL,
            name: this.previewFile.name,
            type: this.previewFile.type,
          },
        ];
        this.showPreviewPopup = true;
      } else {
        const fileURL = URL.createObjectURL(this.previewFile);
        const img = new Image();
        img.src = fileURL;
        this.previewFileData = [
          {
            width: img.width,
            height: img.height,
            url: fileURL,
            name: this.previewFile.name,
            type: this.previewFile.type,
          },
        ];
        this.showPreviewPopup = true;
      }
    }
  }
  public removeImage(index: number) {
    this.files.splice(index, 1);
  }

  public getType(type: string) {
    if (type.includes("pdf" || "doc" || "docx")) {
      return "application";
    } else {
      return "image";
    }
  }

  public hidePreviewPopup(event: boolean) {
    if (event === true) {
      this.showPreviewPopup = false;
      this.previewFileData = [];
    }
  }
  public close() {
    this.$bvModal.hide("email-modal");
  }
}
