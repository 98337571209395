import { Component, Vue } from "vue-property-decorator";
import { loginApi } from "@/providers/apis/login";
import { appConfigService } from "@/providers/services/app/app-config";
import { applicantDetialsApi } from "@/providers/apis/applicant-dashboard";

@Component({})
export default class EmailVerifyComponent extends Vue {
  public emailMessage = "";
  public isSessionExpired = false;
  public profileData = {
    email: "",
  };
  public errMsg = false;
  public created() {
    loginApi.emailVerification(this.$route.query.token).then((res) => {
      this.emailMessage =
        "Thank you for verifying your email address. Please login to continue";
      if (res.response.status === 409) {
        this.emailMessage =
          "Email id already verified. Please login to continue";
      }
      if (res.response.status === 404) {
        this.emailMessage = "Email id not found. Please enter the valid email";
      }
      if (res.response.status === 401) {
        this.isSessionExpired = true;
        this.emailMessage =
          "Session Expired, Please enter your email below to resend the verification email";
      }
    });
  }
  public onSubmit() {
    if (this.isSessionExpired) {
      if (!this.profileData.email) {
        this.errMsg = true;
        return;
      }
      applicantDetialsApi
        .resendProfile(this.profileData)
        // eslint-disable-next-line @typescript-eslint/no-empty-function
        .then((response) => {});
    } else {
      const homeUrl = appConfigService.getStrapiApiUrl();
      window.location.href = `${homeUrl}/login`;
    }
  }
}
