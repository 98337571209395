import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import VuePdfEmbed from "vue-pdf-embed/dist/vue2-pdf-embed";

@Component({
  name: "pdf-viewer",
  components: { VuePdfEmbed },
})
export default class PDFViewerComponent extends Vue {
  @Prop()
  public source!: string;
  @Prop()
  public headerHeight!: number;
  @Prop()
  public applicationName!: string;
  @Prop()
  public isUpdatePageNumber!: boolean;

  public page: any = 1;
  public pageCount = 0;
  public showAllPages = false;
  public isLoading = true;
  public fileType = "";
  public isScroll = false;

  constructor() {
    super();
  }

  public handleScroll() {
    const navbar = document.getElementsByClassName(
      "pdf-viewer-main-container"
    )[0];
    if (navbar) {
      if (navbar.scrollTop > 70) {
        this.isScroll = true;
      } else if (navbar.scrollTop < 70) {
        this.isScroll = false;
      }
    }
  }

  public handleDocumentRender() {
    this.isLoading = false;
    this.pageCount = (this.$refs as any).pdfRef.pageCount;
    this.$emit("getApplicantId", true);
    this.fileType = this.source.split(".")[1];
  }

  @Watch("showAllPages")
  public showAllPage() {
    this.page = this.showAllPages ? null : 1;
  }

  @Watch("isUpdatePageNumber")
  public isUpdatePageNo() {
    this.page = 1;
  }

  public print() {
    (this.$refs as any).pdfRef.print(100, this.applicationName, true);
  }
}
