export const OFFLINE_FORMS_UPLOAD = `OfflineFormsUpload{
    offlineFormsUploads {
        data {
            id
            attributes {
                parent_slug
                form_fields
                form_name
            }
        }
    }
}`;
