var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"experience-main-container"},_vm._l((_vm.experienceData),function(experience,index){return _c('div',{key:index,staticClass:"experience-container"},[(!_vm.isMobileView)?_c('div',{class:experience.attributes?.fields.class},_vm._l((experience.attributes.fields
          .formInputs),function(formInputsData,indexi){return _c('div',{key:indexi,staticClass:"form-inputs-container"},[_c('div',{staticClass:"small-content"},[_c('div',{staticClass:"form-div"},_vm._l((formInputsData),function(formInput,indexj){return _c('div',{key:indexj,staticClass:"form-label"},[(!_vm.isPreviewForm)?[_c('div',{class:formInput.label !== null
                      ? 'label-containe'
                      : 'display-none'},[[(!_vm.checkValidated[formInput.name] && !_vm.isReviwer)?_c('b-icon',{staticClass:"add-icon",attrs:{"icon":"plus-lg"},on:{"click":function($event){return _vm.onClickForm(indexi)}}}):_vm._e(),(_vm.checkValidated[formInput.name])?_c('b-icon',{staticClass:"check-icon",attrs:{"icon":"check-lg"}}):_vm._e(),(formInput.label)?_c('label',{staticClass:"sub-question",on:{"click":function($event){return _vm.onClickForm(indexi)}}},[_vm._v(_vm._s(formInput.label)+" ")]):_vm._e()]],2),(
                    indexi === _vm.activeForm ||
                    _vm.checkValidated[formInput.name] ||
                    _vm.experienceInformationData
                  )?_vm._l((formInput.propBind
                      .fieldGroups),function(fieldsData,indexk){return _c('div',{key:indexk,class:[formInput.fieldClass]},_vm._l((fieldsData.inputs),function(fieldsInput,indexk){return _c('div',{key:indexk,staticClass:"family-main-container"},_vm._l((fieldsInput),function(fields,indexk){return _c('div',{key:indexk},[_c('div',{staticClass:"field-container",class:fields.mainClass},[(fields.label)?_c('label',{staticClass:"field-label"},[_vm._v(_vm._s(fields.label)),(
                                fields.label &&
                                fields?.validate?.required === true
                              )?_c('span',{staticClass:"text-danger"},[_vm._v("*")]):_vm._e()]):_vm._e(),_c('div',[_c(fields.field,_vm._b({directives:[{name:"validate",rawName:"v-validate",value:(fields?.validate?.veeValidate),expression:"fields?.validate?.veeValidate"}],tag:"component",class:fields.class,attrs:{"name":fields?.validate?.name + indexi,"formIndex":indexi,"autocomplete":"off"},nativeOn:{"keypress":function($event){_vm.eventBind[fields?.propBind?.eventName]
                                  ? _vm.fieldValidation(
                                      $event,
                                      fields?.propBind?.eventName,
                                      fields?.propBind.type,
                                      fields?.validate?.maxLength,
                                      fields?.name
                                    )
                                  : _vm.emptyEvent}},model:{value:(
                                _vm.experiencePayload[formInput.name][fields.name]
                              ),callback:function ($$v) {_vm.$set(_vm.experiencePayload[formInput.name], fields.name, $$v)},expression:"\n                                experiencePayload[formInput.name][fields.name]\n                              "}},'component',
                                fields.options
                                  ? formInput.propBind.fieldGroups
                                  : fields.propBind
                              ,false)),_c('span',[_vm._v(_vm._s(fields.subLabel))]),(
                                fields.field === 'b-form-textarea' &&
                                fields.propBind.maxlength
                              )?_c('div',{staticClass:"word-count-div"},[_c('p',{staticClass:"word-count"},[_vm._v(" "+_vm._s(_vm.experiencePayload[formInput.name][ fields.name ]?.length)+"/"+_vm._s(fields.propBind.maxlength)+" ")])]):_vm._e(),(
                                fields?.validate?.required == true &&
                                _vm.errors.has(fields?.validate?.name + indexi)
                              )?_c('span',{staticClass:"is-invalid top"},[_vm._v(_vm._s(fields.propBind.type === "email" ? _vm.$validator.errors.first( fields?.validate?.name + indexi ) : "This field is required"))]):_vm._e()],1)])])}),0)}),0)}):_vm._e()]:_vm._e(),(_vm.isPreviewForm)?[_c('div',{class:formInput.label !== null
                      ? 'label-containe'
                      : 'display-none'},[(_vm.isEditFile)?[(!_vm.checkValidated[formInput.name])?_c('b-icon',{staticClass:"add-icon",attrs:{"icon":"plus-lg"},on:{"click":function($event){return _vm.onClickForm(indexi)}}}):_vm._e(),(_vm.checkValidated[formInput.name])?_c('b-icon',{staticClass:"check-icon",attrs:{"icon":"check-lg"}}):_vm._e(),(formInput.label)?_c('label',{staticClass:"sub-question",on:{"click":function($event){return _vm.onClickForm(indexi)}}},[_vm._v(_vm._s(formInput.label)+" ")]):_vm._e()]:_vm._e(),(!_vm.isEditFile)?[(
                        !_vm.checkValidated[formInput.name] &&
                        _vm.filledForms.includes(formInput.name)
                      )?_c('b-icon',{staticClass:"add-icon",attrs:{"icon":"plus-lg"}}):_vm._e(),(
                        _vm.checkValidated[formInput.name] &&
                        _vm.filledForms.includes(formInput.name)
                      )?_c('b-icon',{staticClass:"check-icon",attrs:{"icon":"check-lg"}}):_vm._e(),(
                        formInput.label &&
                        _vm.filledForms.includes(formInput.name)
                      )?_c('label',{staticClass:"sub-question",on:{"click":function($event){return _vm.onClickForm(indexi)}}},[_vm._v(_vm._s(formInput.label)+" ")]):_vm._e()]:_vm._e()],2),(
                    (indexi === _vm.activeForm ||
                      _vm.checkValidated[formInput.name] ||
                      _vm.experienceInformationData) &&
                    _vm.filledForms.includes(formInput.name)
                  )?_vm._l((formInput.propBind
                      .fieldGroups),function(fieldsData,indexk){return _c('div',{key:indexk,class:[formInput.fieldClass]},_vm._l((fieldsData.inputs),function(fieldsInput,indexk){return _c('div',{key:indexk,staticClass:"family-main-container"},_vm._l((fieldsInput),function(fields,indexk){return _c('div',{key:indexk},[_c('div',{staticClass:"field-container",class:fields.mainClass},[(fields.label)?_c('label',{staticClass:"field-label"},[_vm._v(_vm._s(fields.label)),(
                                fields.label &&
                                fields?.validate?.required === true
                              )?_c('span',{staticClass:"text-danger"},[_vm._v("*")]):_vm._e()]):_vm._e(),_c('div',[_c(fields.field,_vm._b({directives:[{name:"validate",rawName:"v-validate",value:(fields?.validate?.veeValidate),expression:"fields?.validate?.veeValidate"}],tag:"component",class:[formInput.class],attrs:{"name":fields?.validate?.name + indexi,"formIndex":indexi,"autocomplete":"off","editForm":_vm.editForm,"formType":_vm.formType},nativeOn:{"keypress":function($event){_vm.eventBind[fields?.propBind?.eventName]
                                  ? _vm.fieldValidation(
                                      $event,
                                      fields?.propBind?.eventName,
                                      fields?.propBind.type,
                                      fields?.validate?.maxLength,
                                      fields?.name
                                    )
                                  : _vm.emptyEvent}},model:{value:(
                                _vm.experiencePayload[formInput.name][fields.name]
                              ),callback:function ($$v) {_vm.$set(_vm.experiencePayload[formInput.name], fields.name, $$v)},expression:"\n                                experiencePayload[formInput.name][fields.name]\n                              "}},'component',[
                                fields.options
                                  ? formInput.propBind.fieldGroups
                                  : fields.propBind,
                                { disabled: !_vm.editForm ? true : false },
                              ],false)),_c('span',[_vm._v(_vm._s(fields.subLabel))]),(
                                fields?.validate?.required == true &&
                                _vm.errors.has(fields?.validate?.name + indexi)
                              )?_c('span',{staticClass:"is-invalid top"},[_vm._v(_vm._s(fields.propBind.type === "email" ? _vm.$validator.errors.first( fields?.validate?.name + indexi ) : "This field is required"))]):_vm._e()],1)])])}),0)}),0)}):_vm._e()]:_vm._e()],2)}),0)])])}),0):_vm._e(),(_vm.isMobileView)?_c('div',{staticClass:"experience-container-mobile",class:experience.attributes?.fields.class},_vm._l((experience.attributes.fields
          .formInputs),function(formInputsData,indexi){return _c('div',{key:indexi,staticClass:"experience-sub-container"},_vm._l((formInputsData),function(formInput,indexj){return _c('div',{key:indexj,staticClass:"experience-input-container"},[[_c('div',{class:formInput.label !== null ? 'label-containe' : 'display-none'},[[(!_vm.checkValidated[formInput.name] && !_vm.isReviwer)?_c('b-icon',{staticClass:"add-icon",attrs:{"icon":"plus-lg"},on:{"click":function($event){return _vm.onClickForm(indexi)}}}):_vm._e(),(_vm.checkValidated[formInput.name])?_c('b-icon',{staticClass:"check-icon",attrs:{"icon":"check-lg"}}):_vm._e(),(formInput.label)?_c('label',{staticClass:"sub-question",on:{"click":function($event){return _vm.onClickForm(indexi)}}},[_vm._v(_vm._s(formInput.label)+" ")]):_vm._e()]],2),(
                indexi === _vm.activeForm ||
                _vm.checkValidated[formInput.name] ||
                _vm.experienceInformationData
              )?_vm._l((formInput.propBind.fieldGroups),function(fieldsData,indexk){return _c('div',{key:indexk,class:[formInput.fieldClass]},_vm._l((fieldsData.inputs),function(fieldsInput,indexk){return _c('div',{key:indexk,staticClass:"experince-year-month-container"},_vm._l((fieldsInput),function(fields,indexk){return _c('div',{key:indexk,class:fields.mainClass},[(fields.label)?_c('label',{staticClass:"field-label"},[_vm._v(_vm._s(fields.label)),(
                          fields.label && fields?.validate?.required === true
                        )?_c('span',{staticClass:"text-danger"},[_vm._v("*")]):_vm._e()]):_vm._e(),[_c(fields.field,_vm._b({directives:[{name:"validate",rawName:"v-validate",value:(fields?.validate?.veeValidate),expression:"fields?.validate?.veeValidate"}],tag:"component",class:[
                          formInput.class,
                          [
                            _vm.isEditFile === true
                              ? 'edit-form-enable ' + [formInput.class]
                              : 'edit-form-disable',
                          ],
                        ],attrs:{"name":fields?.validate?.name + indexi,"formIndex":indexi,"autocomplete":"off"},on:{"keypress":function($event){formInput.propBind.type === 'number'
                            ? _vm.numberValidation(
                                $event,
                                formInput.validate.maxLength
                              )
                            : true}},model:{value:(
                          _vm.experiencePayload[formInput.name][fields.name]
                        ),callback:function ($$v) {_vm.$set(_vm.experiencePayload[formInput.name], fields.name, $$v)},expression:"\n                          experiencePayload[formInput.name][fields.name]\n                        "}},'component',[
                          fields.options
                            ? formInput.propBind.fieldGroups
                            : fields.propBind,
                          { disabled: !_vm.editForm ? true : false },
                        ],false)),_c('span',[_vm._v(_vm._s(fields.subLabel))]),(
                          fields?.validate?.required == true &&
                          _vm.errors.has(fields?.validate?.name + indexi)
                        )?_c('span',{staticClass:"is-invalid top"},[_vm._v(_vm._s(fields.propBind.type === "email" ? _vm.$validator.errors.first( fields?.validate?.name + indexi ) : "This field is required"))]):_vm._e()]],2)}),0)}),0)}):_vm._e()]],2)}),0)}),0):_vm._e()])}),0)
}
var staticRenderFns = []

export { render, staticRenderFns }