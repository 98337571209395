import { Component, Provide, Vue } from "vue-property-decorator";
import { gql } from "graphql-tag";
import RadioButtonComponent from "../radio-button/radio-button.component.vue";
import CheckboxButtonComponent from "../checkbox-button/checkbox-button.component.vue";
import SingleCheckboxButton from "@/components/single-checkbox-button/single-checkbox-button.component.vue";
import InputOtherComponent from "../input-other/input-other.component.vue";
import SignatureSubmissionComponent from "../signature-submission/signature-submission.component.vue";
import AppLoaderComponent from "../app-loader/app-loader.component.vue";
import EligibilityCriteriaComponent from "../eligibility-criteria/eligibility-criteria.component.vue";
import {
  CertificationForm,
  FormFieldContents,
  FormNavbar,
  MultiFormData,
  addSupervisor,
} from "@/models/certification-form/certification-form.interface";
import InitialPreviewForm from "../initial-preview-form/initial-preview-form.component.vue";
import { certificationFormApi } from "@/providers/apis/certification-form";
import { utils } from "@/utils/utils";
import { INITIAL_APPLICATION_QUERY } from "@/graphql/training-application-form-query";
import DemographicDataComponent from "../demographic-data/demographic-data.component.vue";
import ExperienceInformationComponent from "../experience-information/experience-information.component.vue";
import FileUploadComponent from "../file-upload/file-upload.component.vue";
import ScopeOfActivitiyComponent from "../scope-of-Activitie/scope-of-activitie.component.vue";
import moment from "moment";
import { loginApi } from "@/providers/apis/login";
import { appConfigService } from "@/providers/services/app/app-config";
import ErrorPopupComponent from "../error-popup/error-popup.component.vue";
import FormInputDatepickerComponent from "../form-input-datepicker/form-input-datepicker.component.vue";

export enum ApplicationFormName {
  LEGAL_FORM = "Legal Custody Document",
  AUTHORIZATION_DOCUMENT = "Authorization Document",
}

@Component({
  name: "certification-form",
  components: {
    "b-form-radio-group": RadioButtonComponent,
    "b-form-checkbox": CheckboxButtonComponent,
    "b-form-single-checkbox": SingleCheckboxButton,
    "b-form-checkbox-input": InputOtherComponent,
    "b-form-signature-submission": SignatureSubmissionComponent,
    "b-demographic-form": DemographicDataComponent,
    "experience-information": ExperienceInformationComponent,
    "b-form-upload-file": FileUploadComponent,
    "scope-of-activity": ScopeOfActivitiyComponent,
    "certification-preview-form": InitialPreviewForm,
    "b-form-input-datepicker": FormInputDatepickerComponent,
    AppLoaderComponent,
    EligibilityCriteriaComponent,
    ErrorPopupComponent,
  },
})
export default class CertificationApplicationForm extends Vue {
  public formPagination = 0;
  public formName = "certificationPartTwoApplicationForm";
  public applicationData: Array<CertificationForm> = [];
  public applicationNavData: FormNavbar[] = [];
  public formData: FormFieldContents[] = [];
  public supervisorFormFields: addSupervisor[] = [];
  public addReferenceForm: MultiFormData[] = [];
  public isNavLoad = false;
  public isScroll = false;
  public isValid = false;
  public formDataResult: any;
  public formApplicationId = "";
  public currentForm: any;
  public signatureInvalid = false;
  public saveDataDraft = false;
  public referenceEmailDuplicateIndexs: number[] = [];
  public supervisorEmailDuplicateIndexs: number[] = [];
  public supervisorEndDateShowIdx: number[] = [];
  public supervisorStartDateShowIdx: number[] = [];
  public supervisorUIUpdate = false;
  public fileData: any;
  public isInvalid = false;
  public isEdit = false;
  public isLoading = false;
  public isEligibility = false;
  public isValidExperience!: boolean;
  public certificateApplicantId = "";
  public userDetails: any;
  public isReviewerUser: any;
  public applicantFullDetials: any;
  public errorMessage = "";
  public isReferesh = "false";
  public currentRoute = "";
  public validFile = false;
  public isFileValid = false;
  public validSOA = true;
  public mainId = "app";
  public hideDiv: string[] = [
    "supervisorEmployment",
    "primaryParent",
    "documentofLegalCustody",
    "endDate",
    "demonstratedUpload",
    "biologicalParentDocument",
    "primaryParentApplicationUpload",
    "highSchool",
    "highSchoolNotListed",
  ];
  public checkField: string[] = [
    "volunteerOrApprovedAgency",
    "biologicalParent",
    "primaryParent",
    "currentlyEmployed",
    "demonstrated",
    "biologicalParentDocument",
    "primaryParentApplicationUpload",
    "documentofLegalCustody",
    "highSchool",
    "highSchoolNotListed",
    "highSchoolDiplomaOrGed",
  ];
  public checkSOAValid = false;
  public checkValidForm = false;
  public formCompletedIndex: number[] = [];
  public payLoadData: any = {
    fillStatus: "DRAFT",
    id: "",
    isEligibility: false,
    certificationPartTwoApplicationForm: {
      firstName: "",
      lastName: "",
      addressLine1: "",
      addressLine2: "",
      state: "",
      city: "",
      zip: "",
      phone: "",
      email: "",
      biologicalParent: "",
      primaryParent: "",
      highSchool: [],
      highSchoolNotListed: "",
      documentUploadCheck: "",
      documentofLegalCustody: [],
      primaryParentApplicationUpload: [],
      primaryCaregiver: "",
      diagnosis: "",
      age: "",
      highSchoolDiplomaOrGed: "",
      activelyParticipated: "",
      demonstrated: "",
      demonstratedUpload: [],
      experienceInformation: {
        familySupportSpecialist: {
          experienceYear: "",
          experienceMonth: "",
          employmentType: "",
          currentPosition: "",
          agency: "",
          supervisorName: "",
          positionHeld: "",
        },
        supportGroupFacilitator: {
          experienceYear: "",
          experienceMonth: "",
          employmentType: "",
          currentPosition: "",
          agency: "",
          supervisorName: "",
          positionHeld: "",
        },
        caregiverEducator: {
          experienceYear: "",
          experienceMonth: "",
          employmentType: "",
          currentPosition: "",
          agency: "",
          supervisorName: "",
          positionHeld: "",
        },
        otherRelevantExperience: {
          experienceYear: "",
          experienceMonth: "",
          employmentType: "",
          currentPosition: "",
          agency: "",
          supervisorName: "",
          positionHeld: "",
        },
      },
    },
    certificationStatementOfPersonalExperience: {
      documentationOfDiagnosed: "",
      regularBasisToRecover: "",
      assistingOrAdvocating: "",
      physicianOrLicensed: "",
      disorderExperience: "",
      experiencesAssistingOrAdvocating: "",
      strengthenedResiliency: "",
      familyDrivenAndYouthGuided: "",
      culturallyAndLinguistically: "",
      communityBased: "",
      selfCare: "",
      becomeCfss: "",
      workWithCandidate: "",
      navigating: "",
    },
    certificationApplicationDocument: {
      copyOfEducations: [],
      parentTraining: [],
      competencesCourse: [],
    },
    certificationPartTwoRefererenceInformation: [
      {
        referenceFirstName: "",
        referenceLastName: "",
        agencyOrganization: "",
        yourPosition: "",
        referencePhoneNumber: "",
        referenceEmailAddress: "",
      },
      {
        referenceFirstName: "",
        referenceLastName: "",
        agencyOrganization: "",
        yourPosition: "",
        referencePhoneNumber: "",
        referenceEmailAddress: "",
      },
      {
        referenceFirstName: "",
        referenceLastName: "",
        agencyOrganization: "",
        yourPosition: "",
        referencePhoneNumber: "",
        referenceEmailAddress: "",
      },
    ],
    certificationPartTwoSupervisorInformation: [
      {
        supervisorFirstName: "",
        supervisorLastName: "",
        peerSupportAgencyOrganization: "",
        peerSupportYourPosition: "",
        currentlyEmployed: "",
        startDate: "",
        endDate: "",
        supervisorCredentials: "",
        phone: "",
        supervisorEmail: "",
        descripePeerSupportResponsibity: "",
      },
    ],
    certificationApplicationScopeOfActivities: {
      handbook: {
        scopeOfActivity: {
          scopeOfActivityCurrentCertified: [],
          scopeOfActivityPrincipleCertified: [],
          scopeOfActivityFullName: "",
        },
        codeOfEthics: {
          codeOfEthicsCurrentCertified: [],
          codeOfEthicsPrincipleCertified: [],
          codeOfEthicsFullName: "",
        },
        handBookDocument: {
          handBookAcknowledge: [],
          handBookFullName: "",
        },
      },
    },
    certificationSignature: {
      isSignature: "",
      demographic: {
        hispanicorLatino: {
          hispanicorLatino: "",
        },
        ethnicGroupYourself: {
          centralAmerican: "",
          cuban: "",
          dominican: "",
          mexican: "",
          southAmerican: "",
          others: "",
        },
        raceYourself: {
          blackAmerican: "",
          asian: "",
          nativeHawaiian: "",
          pacificIslander: "",
          white: "",
          americanIndian: "",
          dob: "",
        },
      },
      submissionDate: moment().format("MM-DD-YYYY"),
    },
  };
  public notFilesUploadedKeyNames: string[] = [];
  public formTitle = {
    main_title: "",
    application_name: "",
  };
  public eventBind: any = {
    acceptNumberOnly: this.numberValidation,
    avoidNumbersAndSpecialChar: this.avoidNumbersAndSpecialCharacter,
    telCharacter: this.telCharacterValidate,
    onlyAlphabets: this.onlyAlphabets,
  };
  public async created() {
    loginApi
      .logInWithCredential(
        appConfigService.getClientId(),
        appConfigService.getClientKey(),
        "anonymous"
      )
      .then((data: any) => {
        this.isLoading = false;
        localStorage.setItem("anonymousToken", data.access_token);
      });
    this.currentRoute = this.$route.path;
    let payloadStorage: any = localStorage.getItem("certificationFormPayload");
    if (payloadStorage) {
      payloadStorage = JSON.parse(payloadStorage);
      this.payLoadData = { ...payloadStorage };
    }
    const formFilledIndex: any = localStorage.getItem(
      "certificationFormFilledIndex"
    );
    if (formFilledIndex) {
      formFilledIndex.split(",").forEach((data: string) => {
        this.formCompletedIndex.push(Number(data));
      });
    } else {
      if (!this.formCompletedIndex.includes(this.formPagination)) {
        this.formCompletedIndex.push(this.formPagination);
      }
    }
    if (this.formPagination === 0) {
      this.isNavLoad = true;
    }
    this.$apollo
      .query({
        query: gql`query ${INITIAL_APPLICATION_QUERY}`,
      })
      .then((result) => {
        this.applicationData = result.data.initialApplications.data;
        this.formTitle = {
          main_title: this.applicationData[0].attributes.main_title,
          application_name: this.applicationData[0].attributes.application_name,
        };
        this.applicationNavData = this.applicationData[0].attributes.navbar;
        this.formData = this.applicationData[0].attributes.form_fields;
        if (this.payLoadData.certificationPartTwoSupervisorInformation.length) {
          const addSupervisorForm =
            this.payLoadData.certificationPartTwoSupervisorInformation.length;
          for (let index = 0; index < addSupervisorForm; index++) {
            this.supervisorFormFields.push(
              this.applicationData[0].attributes.form_fields[4].fields
                .multiForm[0]
            );
          }
        }
        for (let index = 0; index < this.checkField.length; index++) {
          for (
            let i = 0;
            i <
            this.payLoadData.certificationPartTwoSupervisorInformation.length;
            i++
          ) {
            this.fieldUpdated(this.checkField[index], i);
          }
        }
      });
    const el = document.getElementById("header");
    if (el) {
      el.classList.add("remove-container");
    }
  }
  public mounted() {
    const mainEl: any = document.querySelector(".main-content");
    if (mainEl) {
      mainEl.addEventListener("keyup", (event: any) => {
        if (event.shiftKey && event.keyCode == 9) {
          const el = event.path[0];
          if (el) {
            el.scrollIntoView({ block: "center", behavior: "smooth" });
          }
        }
      });
    }
    const el = document.getElementById("header");
    if (el) {
      el.classList.add("remove-container");
    }
  }

  public fileValid(event: any) {
    this.validFile = event;
  }

  public allFilesUploaded(event: any) {
    if (this.notFilesUploadedKeyNames.includes(event)) {
      this.notFilesUploadedKeyNames.splice(event, 1);
    }
  }

  public notFileUploaded(event: string) {
    if (!this.notFilesUploadedKeyNames.includes(event)) {
      this.notFilesUploadedKeyNames.push(event);
    }
  }

  public fieldUpdated(groupName = "", supervisorIdx?: number) {
    switch (groupName) {
      case "volunteerOrApprovedAgency":
        if (
          this.payLoadData.certificationPartTwoApplicationForm
            .volunteerOrApprovedAgency === "Yes"
        ) {
          this.hideDiv.splice(this.hideDiv.indexOf("supervisorEmployment"), 1);
        } else {
          if (!this.hideDiv.includes("supervisorEmployment")) {
            this.payLoadData.certificationPartTwoApplicationForm.supervisorEmployment =
              [];
            this.hideDiv.push("supervisorEmployment");
          } else {
            return;
          }
        }
        break;
      case "biologicalParent":
        if (
          this.payLoadData.certificationPartTwoApplicationForm
            .biologicalParent === "No"
        ) {
          this.hideDiv.splice(this.hideDiv.indexOf("primaryParent"), 1);
        } else {
          if (!this.hideDiv.includes("primaryParent")) {
            this.payLoadData.certificationPartTwoApplicationForm.primaryParent =
              "";
            if (!this.hideDiv.includes("primaryParent")) {
              this.hideDiv.push("primaryParent");
            }
            this.payLoadData.certificationPartTwoApplicationForm.biologicalParentDocument =
              "";
            if (!this.hideDiv.includes("biologicalParentDocument")) {
              this.hideDiv.push("biologicalParentDocument");
            }
            this.payLoadData.certificationPartTwoApplicationForm.primaryParentApplicationUpload =
              [];
            if (!this.hideDiv.includes("primaryParentApplicationUpload")) {
              this.hideDiv.push("primaryParentApplicationUpload");
            }
            this.payLoadData.certificationPartTwoApplicationForm.documentofLegalCustody =
              [];
            if (!this.hideDiv.includes("documentofLegalCustody")) {
              this.hideDiv.push("documentofLegalCustody");
            }
          } else {
            return;
          }
        }
        break;
      case "primaryParent":
        if (
          this.payLoadData.certificationPartTwoApplicationForm.primaryParent ===
          "Yes"
        ) {
          this.hideDiv.splice(
            this.hideDiv.indexOf("biologicalParentDocument"),
            1
          );
        } else {
          if (!this.hideDiv.includes("biologicalParentDocument")) {
            this.payLoadData.certificationPartTwoApplicationForm.biologicalParentDocument =
              "";
            if (!this.hideDiv.includes("biologicalParentDocument")) {
              this.hideDiv.push("biologicalParentDocument");
            }
            this.payLoadData.certificationPartTwoApplicationForm.primaryParentApplicationUpload =
              [];
            if (!this.hideDiv.includes("primaryParentApplicationUpload")) {
              this.hideDiv.push("primaryParentApplicationUpload");
            }
            this.payLoadData.certificationPartTwoApplicationForm.documentofLegalCustody =
              [];
            if (!this.hideDiv.includes("documentofLegalCustody")) {
              this.hideDiv.push("documentofLegalCustody");
            }
          } else {
            return;
          }
        }
        break;
      case "biologicalParentDocument":
        this.checkForm();
        break;
      case "currentlyEmployed":
        if (supervisorIdx !== undefined) {
          if (
            this.payLoadData.certificationPartTwoSupervisorInformation[
              supervisorIdx
            ].currentlyEmployed === "No"
          ) {
            if (supervisorIdx !== undefined) {
              if (this.supervisorEndDateShowIdx.length) {
                this.supervisorEndDateShowIdx.push(supervisorIdx);
              } else {
                this.supervisorEndDateShowIdx = [supervisorIdx];
              }
              if (!this.supervisorStartDateShowIdx.includes(supervisorIdx)) {
                if (this.supervisorStartDateShowIdx.length) {
                  this.supervisorStartDateShowIdx.push(supervisorIdx);
                } else {
                  this.supervisorStartDateShowIdx = [supervisorIdx];
                }
              }
            }
            this.supervisorUIUpdate = utils.UIUpdateUsingKey(
              this.supervisorUIUpdate
            );
          } else {
            if (!this.supervisorStartDateShowIdx.includes(supervisorIdx)) {
              if (this.supervisorStartDateShowIdx.length) {
                this.supervisorStartDateShowIdx.push(supervisorIdx);
              } else {
                this.supervisorStartDateShowIdx = [supervisorIdx];
              }
            }
            const idx = this.supervisorEndDateShowIdx.findIndex(
              (element) => element === supervisorIdx
            );
            if (idx > -1) {
              this.supervisorEndDateShowIdx.splice(idx);
            }
            this.supervisorUIUpdate = utils.UIUpdateUsingKey(
              this.supervisorUIUpdate
            );
          }
        }
        break;
      case "demonstrated":
        if (
          this.payLoadData.certificationPartTwoApplicationForm.demonstrated ===
          "Yes"
        ) {
          this.hideDiv.splice(this.hideDiv.indexOf("demonstratedUpload"), 1);
        } else {
          if (!this.hideDiv.includes("demonstratedUpload")) {
            this.payLoadData.certificationPartTwoApplicationForm.demonstratedUpload =
              [];
            this.hideDiv.push("demonstratedUpload");
          } else {
            return;
          }
        }
        break;

      case "highSchoolDiplomaOrGed":
        if (
          this.payLoadData.certificationPartTwoApplicationForm
            .highSchoolDiplomaOrGed === "Yes"
        ) {
          this.hideDiv.splice(this.hideDiv.indexOf("highSchool"), 1);
        } else {
          if (!this.hideDiv.includes("highSchool")) {
            this.payLoadData.certificationPartTwoApplicationForm.highSchool =
              [];

            if (!this.hideDiv.includes("highSchool")) {
              this.hideDiv.push("highSchool");
            }
            this.payLoadData.certificationPartTwoApplicationForm.highSchoolNotListed =
              "";

            if (!this.hideDiv.includes("highSchoolNotListed")) {
              this.hideDiv.push("highSchoolNotListed");
            }
            this.payLoadData.certificationPartTwoApplicationForm.biologicalParentDocument =
              "";
            if (!this.hideDiv.includes("biologicalParentDocument")) {
              this.hideDiv.push("biologicalParentDocument");
            }
            this.payLoadData.certificationPartTwoApplicationForm.primaryParentApplicationUpload =
              [];
            if (!this.hideDiv.includes("primaryParentApplicationUpload")) {
              this.hideDiv.push("primaryParentApplicationUpload");
            }
            this.payLoadData.certificationPartTwoApplicationForm.documentofLegalCustody =
              [];
            if (!this.hideDiv.includes("documentofLegalCustody")) {
              this.hideDiv.push("documentofLegalCustody");
            }
          } else {
            return;
          }
        }
        break;

      case "highSchool":
        this.payLoadData.certificationPartTwoApplicationForm.highSchool
          ? this.payLoadData.certificationPartTwoApplicationForm.highSchool
          : (this.payLoadData.certificationPartTwoApplicationForm.highSchool =
              []);
        if (
          !this.payLoadData.certificationPartTwoApplicationForm.highSchool.includes(
            "Other"
          )
        ) {
          if (!this.hideDiv.includes("highSchoolNotListed")) {
            this.hideDiv.push("highSchoolNotListed");
            this.payLoadData.certificationPartTwoApplicationForm.highSchoolNotListed =
              "";
          } else {
            return;
          }
        } else {
          if (this.hideDiv.indexOf("highSchoolNotListed") >= 0) {
            this.hideDiv.splice(this.hideDiv.indexOf("highSchoolNotListed"), 1);
          }
        }
        break;
    }
  }

  public scopeOfActivityValidation(event: boolean) {
    this.validSOA = event;
  }

  public avoidNumbersAndSpecialCharacter(evt: any) {
    utils.avoidNumbersAndSpecialCharacters(evt);
  }

  public telCharacterValidate(evt: any, length?: number) {
    utils.telCharacterValidate(evt, length);
  }

  public onlyAlphabets(evt: any, fieldName = "") {
    utils.onlyAlphabets(evt, fieldName);
  }

  public fieldValidation(
    value: any,
    eventName: string,
    fieldType: string,
    maxLength = 0,
    fieldName = ""
  ) {
    if (fieldType === "number") {
      this.eventBind[eventName](value, maxLength);
    } else if (fieldName === "state") {
      this.eventBind[eventName](value, fieldName);
    } else {
      this.eventBind[eventName](value);
    }
  }

  public emptyEvent() {
    return;
  }

  checkForm() {
    if (
      this.payLoadData.certificationPartTwoApplicationForm
        .biologicalParentDocument === ApplicationFormName.AUTHORIZATION_DOCUMENT
    ) {
      this.hideDiv.splice(
        this.hideDiv.indexOf("primaryParentApplicationUpload"),
        1
      );
    } else {
      if (!this.hideDiv.includes("primaryParentApplicationUpload")) {
        this.payLoadData.certificationPartTwoApplicationForm.primaryParentApplicationUpload =
          [];
        localStorage.setItem(
          "certificationFormPayload",
          JSON.stringify(this.payLoadData)
        );
        this.hideDiv.push("primaryParentApplicationUpload");
      }
    }
    if (
      this.payLoadData.certificationPartTwoApplicationForm
        .biologicalParentDocument === ApplicationFormName.LEGAL_FORM
    ) {
      this.hideDiv.splice(this.hideDiv.indexOf("documentofLegalCustody"), 1);
    } else {
      if (!this.hideDiv.includes("documentofLegalCustody")) {
        this.payLoadData.certificationPartTwoApplicationForm.documentofLegalCustody =
          [];
        this.hideDiv.push("documentofLegalCustody");
      }
    }
  }
  // NEXT BUTTON function
  public nextButton() {
    this.checkValidForm = true;
    this.isFileValid = !this.isFileValid;
    this.checkSOAValid = !this.checkSOAValid;
    if (this.payLoadData) {
      if (this.formPagination == 6) {
        this.payLoadData.certificationSignature.submissionDate =
          moment().format("MM-DD-YYYY");
        if (this.payLoadData.certificationSignature.isSignature) {
          this.signatureInvalid = false;
        } else {
          this.checkValidForm = true;
          this.signatureInvalid = true;
          setTimeout(() => {
            const el = document.getElementsByClassName("is-invalid")[0];
            if (el) {
              el.scrollIntoView({ behavior: "smooth", block: "end" });
            }
          }, 100);
          return;
        }
      }

      this.$validator.validate().then(async (validate) => {
        if (this.formName === "certificationPartTwoRefererenceInformation") {
          this.referenceEmailDuplicateIndexs = utils.duplicateValueIdx(
            this.payLoadData,
            this.formName,
            true,
            "referenceEmailAddress"
          );
        }
        if (this.formName === "certificationPartTwoSupervisorInformation") {
          this.supervisorEmailDuplicateIndexs = utils.duplicateValueIdx(
            this.payLoadData,
            this.formName,
            true,
            "supervisorEmail"
          );
        }

        if (
          this.referenceEmailDuplicateIndexs.length === 0 &&
          this.supervisorEmailDuplicateIndexs.length === 0 &&
          validate &&
          this.validSOA &&
          !this.notFilesUploadedKeyNames.length
        ) {
          if (this.formPagination == 3) {
            const duplicateEmail =
              this.payLoadData.certificationPartTwoRefererenceInformation.filter(
                (reference: any) => {
                  if (
                    reference.referenceEmailAddress.toLowerCase() ===
                    this.payLoadData.certificationPartTwoApplicationForm.email.toLowerCase()
                  ) {
                    return reference;
                  }
                }
              );
            if (duplicateEmail.length) {
              this.$bvModal.show("error-modal-center");
              this.errorMessage =
                "Applicant email id and reference email id must be different *";
              return;
            }
          }

          if (this.formPagination == 4) {
            const supervisorEmailValidate =
              this.payLoadData.certificationPartTwoSupervisorInformation.filter(
                (supervisor: any) => {
                  if (
                    supervisor.supervisorEmail.toLowerCase() ===
                    this.payLoadData.certificationPartTwoApplicationForm.email.toLowerCase()
                  ) {
                    return supervisor;
                  }
                }
              );
            if (supervisorEmailValidate.length) {
              this.$bvModal.show("error-modal-center");
              this.errorMessage =
                "Supervisor email id and applicant email id must be different *";
              return;
            }

            const referenceEmails =
              this.payLoadData.certificationPartTwoRefererenceInformation.map(
                (reference: any) => {
                  return reference.referenceEmailAddress.toLowerCase();
                }
              );
            const supervisorReferenceDuplicateEmail =
              this.payLoadData.certificationPartTwoSupervisorInformation.filter(
                (supervisor: any) => {
                  if (
                    referenceEmails.includes(
                      supervisor.supervisorEmail.toLowerCase()
                    )
                  ) {
                    return supervisor;
                  }
                }
              );
            if (supervisorReferenceDuplicateEmail.length) {
              this.formPagination = 4;
              this.formName = "certificationPartTwoSupervisorInformation";
              this.$bvModal.show("error-modal-center");
              this.errorMessage =
                "Supervisor email id and reference email id must be different *";
              this.showForm(this.formName);
              return;
            }
          }

          this.isLoading = true;
          this.isValid = true;
          this.checkValidForm = false;
          if (this.formPagination < this.formData.length) {
            this.isNavLoad = true;
            if (this.payLoadData.id == "") {
              await certificationFormApi
                .CertificationApplicationForm(
                  this.payLoadData.fillStatus,
                  this.payLoadData
                )
                .then((result: any) => {
                  this.isLoading = false;
                  this.certificateApplicantId = result.headers.location;
                  localStorage.setItem(
                    "certificateApplicationId",
                    this.certificateApplicantId
                  );
                  this.payLoadData.id = localStorage.getItem(
                    "certificateApplicationId"
                  );
                  localStorage.setItem(
                    "certificationFormPayload",
                    JSON.stringify(this.payLoadData)
                  );
                  if (!this.isEdit) {
                    this.nextPaginationUpdate();
                  }
                  const el = document.getElementById(this.mainId);
                  if (el) {
                    el.scrollIntoView({ behavior: "smooth" });
                  }
                })
                .catch((error) => {
                  this.errorMessage = utils.errorMessage(error);
                  this.isLoading = false;
                  this.$bvModal.show("error-modal-center");
                  setTimeout(() => {
                    this.$bvModal.hide("error-modal-center");
                  }, 3000);
                });
            } else {
              this.payLoadData.id = localStorage.getItem(
                "certificateApplicationId"
              );
              localStorage.setItem(
                "certificationFormPayload",
                JSON.stringify(this.payLoadData)
              );
              const id = this.payLoadData.id;
              await certificationFormApi
                .updateCertificationForm(this.payLoadData, id)
                .then((data) => {
                  this.isLoading = false;
                  if (!this.isEdit) {
                    this.nextPaginationUpdate();
                  }
                  const el = document.getElementById(this.mainId);
                  if (el) {
                    el.scrollIntoView({ behavior: "smooth" });
                  }
                })
                .catch((error) => {
                  this.errorMessage = utils.errorMessage(error);
                  this.isLoading = false;
                  this.$bvModal.show("error-modal-center");
                  setTimeout(() => {
                    this.$bvModal.hide("error-modal-center");
                  }, 3000);
                });
            }
            if (this.isEdit) {
              this.formPagination = 7;
              this.formName = "certificationPartTwoApplicationForm";
            }
            if (!this.formCompletedIndex.includes(this.formPagination)) {
              this.formCompletedIndex.push(this.formPagination);
            }
            this.currentForm = this.payLoadData[this.formName];
            localStorage.setItem(
              "certificationFormFilledIndex",
              this.formCompletedIndex.toString()
            );
          }
        } else {
          setTimeout(() => {
            const el = document.getElementsByClassName("is-invalid")[0];
            if (el) {
              el.scrollIntoView({ behavior: "smooth", block: "end" });
            }
          }, 50);
        }
      });
    }
  }

  public nextPaginationUpdate() {
    const page = this.formPagination + 1;
    if (page == 0) {
      this.formName = "certificationPartTwoApplicationForm";
    } else if (page == 1) {
      this.formName = "certificationStatementOfPersonalExperience";
    } else if (page == 2) {
      this.formName = "certificationApplicationDocument";
    } else if (page == 3) {
      this.formName = "certificationPartTwoRefererenceInformation";
      this.showForm(this.formName);
    } else if (page == 4) {
      this.formName = "certificationPartTwoSupervisorInformation";
    } else if (page == 5) {
      this.formName = "certificationApplicationScopeOfActivities";
    } else if (page == 6) {
      this.formName = "certificationSignature";
    }
    this.formPagination = page;
  }

  // To Edit
  public formEdit(slug: any) {
    this.isEdit = true;
    this.formName = slug;
    if (slug == "certificationPartTwoApplicationForm") {
      this.formPagination = 0;
    } else if (slug == "certificationStatementOfPersonalExperience") {
      this.formPagination = 1;
    } else if (slug == "certificationApplicationDocument") {
      this.formPagination = 2;
    } else if (slug == "certificationPartTwoRefererenceInformation") {
      this.showForm(slug);
      this.formPagination = 3;
    } else if (slug == "certificationPartTwoSupervisorInformation") {
      this.formPagination = 4;
    } else if (slug == "certificationApplicationScopeOfActivities") {
      this.formPagination = 5;
    } else if (slug == "certificationSignature") {
      this.payLoadData.certificationSignature.isSignature = "";
      localStorage.setItem(
        "certificationFormPayload",
        JSON.stringify(this.payLoadData)
      );
      this.formPagination = 7;
    }
    window.scrollTo(0, 0);
  }

  public saveDraft() {
    this.$bvToast.show("example-toast");
    localStorage.setItem(
      "certificationFormPayload",
      JSON.stringify(this.payLoadData)
    );
    this.saveDataDraft = true;
    setTimeout(() => {
      this.saveDataDraft = false;
    }, 1000);
  }

  public closeToast() {
    this.$bvToast.hide("example-toast");
  }

  public previousButton() {
    this.isEdit = false;
    this.validSOA = true;
    this.referenceEmailDuplicateIndexs = [];
    this.notFilesUploadedKeyNames = [];
    if (this.formPagination !== 0) {
      window.scrollTo(0, 0);
      this.formPagination = this.formPagination - 1;
      if (this.formPagination == 0) {
        this.formName = "certificationPartTwoApplicationForm";
      } else if (this.formPagination == 1) {
        this.formName = "certificationStatementOfPersonalExperience";
      } else if (this.formPagination == 2) {
        this.formName = "certificationApplicationDocument";
      } else if (this.formPagination == 3) {
        this.formName = "certificationPartTwoRefererenceInformation";
      } else if (this.formPagination == 4) {
        this.formName = "certificationPartTwoSupervisorInformation";
      } else if (this.formPagination == 5) {
        this.formName = "certificationApplicationScopeOfActivities";
      } else if (this.formPagination == 6) {
        this.formName = "certificationSignature";
      }
    }
  }

  public onSubmit() {
    this.$validator.validate().then((validate: any) => {
      if (validate && !this.notFilesUploadedKeyNames.length) {
        let payloadStorage: any = localStorage.getItem(
          "certificationFormPayload"
        );
        if (payloadStorage) {
          payloadStorage = JSON.parse(payloadStorage);
          this.payLoadData = { ...payloadStorage };
        }
        this.isLoading = true;
        this.payLoadData.fillStatus = "COMPLETED";
        this.payLoadData.id = localStorage.getItem("certificateApplicationId");
        const id = this.payLoadData.id;
        certificationFormApi
          .updateCertificationForm(this.payLoadData, id)
          .then(() => {
            certificationFormApi
              .getCertificationApplicationForm(id)
              .then((result: any) => {
                const applicationCode = result.data.code;
                const someData = {
                  title: "Certified Family Support Specialist Program",
                  SubTitle: "Certification: Initial Application",
                };
                this.$router.push({
                  path: "/form/submitted",
                  query: {
                    subTitle: someData.SubTitle,
                    applicationId: applicationCode,
                  },
                });
                this.isLoading = false;
              })
              .catch((error) => {
                this.errorMessage = utils.errorMessage(error);
                this.isLoading = false;
                this.$bvModal.show("error-modal-center");
                setTimeout(() => {
                  this.$bvModal.hide("error-modal-center");
                }, 3000);
              });
            window.scrollTo(0, 0);
            localStorage.removeItem("certificationFormPayload");
            localStorage.removeItem("certificateApplicationId");
            localStorage.removeItem("certificationFormFilledIndex");
          })
          .catch((error) => {
            this.errorMessage = utils.errorMessage(error);
            this.isLoading = false;
            this.$bvModal.show("error-modal-center");
            setTimeout(() => {
              this.$bvModal.hide("error-modal-center");
            }, 3000);
          });
      }
    });
  }

  public closePopup() {
    this.$bvModal.hide("error-modal-center");
  }

  public isValidFlag(event: any) {
    this.isValidExperience = event;
  }
  public numberValidation(evt: any, length?: number) {
    utils.onlyNumberKey(evt, length);
  }

  public navigateTab(slug: string, index: number) {
    if (this.formCompletedIndex.includes(index)) {
      this.isEdit = false;
      if (index > this.formPagination) {
        this.checkSOAValid = !this.checkSOAValid;

        if (index > 3) {
          this.referenceEmailDuplicateIndexs = utils.duplicateValueIdx(
            this.payLoadData,
            "certificationPartTwoRefererenceInformation",
            true,
            "referenceEmailAddress"
          );
          if (this.referenceEmailDuplicateIndexs.length) {
            this.formPagination = 3;
            this.formName = "certificationPartTwoRefererenceInformation";
            const el = document.getElementById(this.mainId);
            if (el) {
              el.scrollIntoView({ behavior: "smooth" });
            }
            return;
          }
          const referenceEmailValidate =
            this.payLoadData.certificationPartTwoRefererenceInformation.filter(
              (reference: any) => {
                if (
                  reference.referenceEmailAddress.toLowerCase() ===
                  this.payLoadData.certificationPartTwoApplicationForm.email.toLowerCase()
                ) {
                  return reference;
                }
              }
            );
          if (referenceEmailValidate.length) {
            this.formPagination = 3;
            this.formName = "certificationPartTwoRefererenceInformation";
            this.$bvModal.show("error-modal-center");
            this.errorMessage =
              "Reference email id and applicant email id must be different *";
            return;
          }
        }

        if (index > 4) {
          this.supervisorEmailDuplicateIndexs = utils.duplicateValueIdx(
            this.payLoadData,
            "certificationPartTwoSupervisorInformation",
            true,
            "supervisorEmail"
          );
          if (this.supervisorEmailDuplicateIndexs.length) {
            this.formPagination = 4;
            this.formName = "certificationPartTwoSupervisorInformation";
            const el = document.getElementById(this.mainId);
            if (el) {
              el.scrollIntoView({ behavior: "smooth" });
            }
            return;
          }
          const supervisorEmailValidate =
            this.payLoadData.certificationPartTwoSupervisorInformation.filter(
              (supervisor: any) => {
                if (
                  supervisor.supervisorEmail.toLowerCase() ===
                  this.payLoadData.certificationPartTwoApplicationForm.email.toLowerCase()
                ) {
                  return supervisor;
                }
              }
            );
          if (supervisorEmailValidate.length) {
            this.formPagination = 4;
            this.formName = "certificationPartTwoSupervisorInformation";
            this.$bvModal.show("error-modal-center");
            this.errorMessage =
              "Supervisor email id and applicant email id must be different *";
            return;
          }

          const referenceEmails =
            this.payLoadData.certificationPartTwoRefererenceInformation.map(
              (reference: any) => {
                return reference.referenceEmailAddress.toLowerCase();
              }
            );
          const supervisorReferenceDuplicateEmail =
            this.payLoadData.certificationPartTwoSupervisorInformation.filter(
              (supervisor: any) => {
                if (
                  referenceEmails.includes(
                    supervisor.supervisorEmail.toLowerCase()
                  )
                ) {
                  return supervisor;
                }
              }
            );
          if (supervisorReferenceDuplicateEmail.length) {
            this.formPagination = 4;
            this.formName = "certificationPartTwoSupervisorInformation";
            this.$bvModal.show("error-modal-center");
            this.errorMessage =
              "Supervisor email id and reference email id must be different *";
            this.showForm(this.formName);
            return;
          }
        }

        //signature validation
        this.checkValidForm = true;
        if (this.formPagination == 6) {
          if (this.payLoadData.certificationSignature.isSignature) {
            this.signatureInvalid = false;
          } else {
            this.checkValidForm = true;
            this.signatureInvalid = true;
            setTimeout(() => {
              const el = document.getElementsByClassName("is-invalid")[0];
              if (el) {
                el.scrollIntoView({ behavior: "smooth", block: "end" });
              }
            }, 100);
            return;
          }
        }

        this.$validator.validateAll().then((validate: any) => {
          if (
            this.referenceEmailDuplicateIndexs.length === 0 &&
            this.supervisorEmailDuplicateIndexs.length === 0 &&
            validate &&
            this.validSOA
          ) {
            this.checkValidForm = false;
            const el = document.getElementById(this.mainId);
            if (el) {
              el.scrollIntoView({ behavior: "smooth" });
            }
            this.isValid = true;
            localStorage.setItem(
              "certificationFormPayload",
              JSON.stringify(this.payLoadData)
            );

            if (index === 7) {
              this.formPagination = index;
              this.formName = "certificationPartTwoApplicationForm";
            } else {
              this.formName = slug;
              this.formPagination = index;
              this.showForm(this.formName);
            }
          } else {
            const el = document.getElementsByClassName("is-invalid")[0];
            if (el) {
              el.scrollIntoView({ behavior: "smooth", block: "end" });
            }
          }
        });
      } else {
        this.referenceEmailDuplicateIndexs = [];
        this.supervisorEmailDuplicateIndexs = [];
        if (index === 7) {
          this.formPagination = index;
          this.formName = "certificationPartTwoApplicationForm";
        } else {
          this.formName = slug;
          this.formPagination = index;
          this.showForm(this.formName);
        }
        const el = document.getElementById(this.mainId);
        if (el) {
          el.scrollIntoView({ behavior: "smooth" });
        }
      }
    }
  }

  // reference flow

  public showForm(formName: string) {
    const formData = this.payLoadData[formName];
    if (formData.length) {
      if (formName === "certificationPartTwoRefererenceInformation") {
        this.addReferenceForm = [];
      }
      formData.forEach(() => {
        if (formName === "certificationPartTwoRefererenceInformation") {
          this.addReferenceForm.push(this.formData[3].fields.multiForm[0]);
        }
      });
    }
  }
  public pushForm(formName: string) {
    if (formName === "certificationPartTwoRefererenceInformation") {
      this.addReferenceForm.push(this.formData[2].fields.multiForm[0]);
      this.payLoadData.certificationPartTwoRefererenceInformation.push({
        referenceFirstName: "",
        referenceLastName: "",
        agencyOrganization: "",
        yourPosition: "",
        referencePhoneNumber: "",
        referenceEmailAddress: "",
        reference:
          this.payLoadData.certificationPartTwoRefererenceInformation.length +
          1,
      });
    }
  }

  // Supervisor flow

  public addAnother() {
    this.payLoadData.certificationPartTwoSupervisorInformation.push({
      supervisorFirstName: "",
      supervisorLastName: "",
      peerSupportAgencyOrganization: "",
      peerSupportYourPosition: "",
      currentlyEmployed: "",
      startDate: "",
      endDate: "",
      supervisorCredentials: "",
      phone: "",
      supervisorEmail: "",
      descripePeerSupportResponsibity: "",
    });

    this.supervisorFormFields.push(
      this.applicationData[0].attributes.form_fields[4].fields.multiForm[0]
    );
  }

  public removeAddedSupervisor(indexForm: number) {
    this.payLoadData.certificationPartTwoSupervisorInformation.splice(
      indexForm,
      1
    );
    this.supervisorFormFields.splice(indexForm, 1);
  }

  public closdedErrorPopup(event: any) {
    if (event) {
      this.errorMessage = "";
    }
  }

  public classNameChooser(inputClass: string) {
    return utils.classNameChooser(inputClass);
  }
  public goBack() {
    if (this.$route.query.isDraft) {
      localStorage.removeItem("certificationFormPayload");
      localStorage.removeItem("certificationFormFilledIndex");
    }
    this.$router.push({
      path: `/home`,
    });
    const el = document.getElementById("header");
    if (el) {
      el.classList.add("active");
      el.classList.remove("remove-container");
    }
  }
}
