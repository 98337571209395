import Vue from "vue";
import Component from "vue-class-component";
import { Prop } from "vue-property-decorator";

@Component({})
export default class ScoreRatingComponent extends Vue {
  @Prop()
  public value!: number;
  @Prop()
  public options!: {
    key: string;
    value: number;
  };
  @Prop()
  public parentSlug?: any;
  public stars = -1;

  @Prop()
  public isScoringSubmited!: boolean;

  public created() {
    this.stars = this.value || -1;
  }

  public rate(key: string, star: number) {
    if (star <= this.options.value && star >= 0) {
      this.stars = star;
      const result = {
        [this.parentSlug]: {
          [key]: this.stars,
        },
      };
      this.$emit("onChangeScore", result);
      const resultSocre = {
        parentSlug: this.parentSlug,
        scored: [
          {
            key: key,
            points: this.stars,
          },
        ],
      };
      this.$emit("scoredData", resultSocre);
    }
  }
}
