import { Component, Vue } from "vue-property-decorator";
import { utils } from "@/utils/utils";

@Component
export default class App extends Vue {
  public created() {
    const userDetails = utils.getUserDetails();
    const path = this.$route.path;
    if (
      userDetails &&
      !userDetails.roles &&
      path.split("/")[1] === "reviewer-dashboard"
    ) {
      this.$router.push({
        path: `/user-dashboard/home`,
      });
    }
  }
}
