export const ADDITIONAL_REFERENCE_FORM_QUERY = `AdditionalReferenceForms {  
    additionalReferenceForms(sort: "id:ASC"){
        data{
            id
            attributes{
                program_title
                application_name
                form_fields
            }
        }
    }
}`;
