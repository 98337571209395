export const RENEWAL_APPLICATION_FORM_QUERY = `RenewalApplicationForms {  
    renewalApplicationForms(sort: "id:ASC"){
        data{
            id
            attributes{
                programTitle
                formTitle
                renewalNavbar{
                    id
                    name
                    slug    
                },
                form_fields{
                    id
                    parent_slug
                    fields
                    formBeforeText
                }
            }
        }
    }
}`;
