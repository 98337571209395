export const MAIN_PAGE_BANNER_QUERY = `Banner {  
  banners(sort: "id:ASC"){
      data {
        id
        attributes {
          carousel_images {
            description
            image {
              data {
                id
                attributes {
                name
                alternativeText
                formats
                url
                }
              }
            } 
          }
        }
      }
    }
  }`;
