var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return (_vm.linkexpiredForgetPassword)?_c('div',{staticClass:"forgot-email-verify-main-container"},[_c('div',{staticClass:"forgot-email-verify-container"},[_c('div',{staticClass:"background-overlay"}),_c('div',{staticClass:"form-container"},[_c('div',{staticClass:"form-div"},[_c('form',{key:_vm.loginType,staticClass:"login-form",on:{"submit":function($event){$event.preventDefault();return _vm.onSubmit.apply(null, arguments)}}},[_vm._m(0),_c('div',{staticClass:"login"},[_c('div',{staticClass:"content"},[_c('h3',{staticClass:"login-title"},[_vm._v("Resetting your password")]),(!_vm.ResetpasswordSuccessful)?_c('p',{staticClass:"greeting"},[_vm._v(" Please enter your password ")]):_c('p',{staticClass:"resetPasswordSucess-text"},[_vm._v(" Password Reset Successful ")])]),(!_vm.ResetpasswordSuccessful)?_c('div',[_c('div',{staticClass:"form-group"},[_c('label',{class:_vm.activeField === 'new-password' ||
                    _vm.forgetPassword.newPassword
                      ? 'activePassword'
                      : '',attrs:{"for":"password"}},[_vm._v("New Password")]),_c('div',{staticClass:"password-container password-new"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.forgetPassword.newPassword),expression:"forgetPassword.newPassword"},{name:"validate",rawName:"v-validate",value:('required|min:6'),expression:"'required|min:6'"}],staticClass:"password-input",class:{
                      'is-danger': _vm.checkValidation
                        ? _vm.$validator.errors.has('new password')
                        : '',
                    },attrs:{"type":"password","name":"new password"},domProps:{"value":(_vm.forgetPassword.newPassword)},on:{"focusin":function($event){return _vm.activeInput('new-password')},"focusout":function($event){return _vm.valueCheck('new-password')},"input":function($event){if($event.target.composing)return;_vm.$set(_vm.forgetPassword, "newPassword", $event.target.value)}}}),_c('div',{staticClass:"eye-icon-container",class:[
                      _vm.activeField === 'new-password' ||
                      _vm.forgetPassword.newPassword
                        ? 'activePassword'
                        : '',
                      {
                        'is-danger': _vm.checkValidation
                          ? _vm.$validator.errors.has('new password')
                          : '',
                      },
                    ],on:{"click":function($event){return _vm.showAndHide('new')}}},[(!_vm.showandHidePassword.newPassword)?_c('b-icon',{staticClass:"eye-icon",attrs:{"icon":"eye-slash"}}):_vm._e(),(_vm.showandHidePassword.newPassword)?_c('b-icon',{staticClass:"eye-icon",attrs:{"icon":"eye"}}):_vm._e()],1)]),(_vm.checkValidation)?_c('span',{staticClass:"is-invalid"},[_vm._v(_vm._s(_vm.$validator.errors.first("new password")))]):_vm._e()]),_c('div',{staticClass:"form-group"},[_c('label',{class:_vm.activeField === 'confirm-password' || _vm.confirmPassword
                      ? 'activePassword'
                      : '',attrs:{"for":"password"}},[_vm._v("Confirm Password")]),_c('div',{staticClass:"password-container password-confirm"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.confirmPassword),expression:"confirmPassword"},{name:"validate",rawName:"v-validate",value:('required'),expression:"'required'"}],staticClass:"password-input",class:{
                      'is-danger': _vm.checkValidation
                        ? _vm.$validator.errors.has('confirm password')
                        : '',
                    },attrs:{"type":"password","name":"confirm password"},domProps:{"value":(_vm.confirmPassword)},on:{"focusin":function($event){return _vm.activeInput('confirm-password')},"focusout":function($event){return _vm.valueCheck('confirm-password')},"input":function($event){if($event.target.composing)return;_vm.confirmPassword=$event.target.value}}}),_c('div',{staticClass:"eye-icon-container",class:[
                      _vm.activeField === 'confirm-password' || _vm.confirmPassword
                        ? 'activePassword'
                        : '',
                      {
                        'is-danger': _vm.checkValidation
                          ? _vm.$validator.errors.has('confirm password')
                          : '',
                      },
                    ],on:{"click":function($event){return _vm.showAndHide('confirm')}}},[(!_vm.showandHidePassword.confirmPass)?_c('b-icon',{staticClass:"eye-icon",attrs:{"icon":"eye-slash"}}):_vm._e(),(_vm.showandHidePassword.confirmPass)?_c('b-icon',{staticClass:"eye-icon",attrs:{"icon":"eye"}}):_vm._e()],1)]),(_vm.checkValidation)?_c('span',{staticClass:"is-invalid"},[_vm._v(_vm._s(_vm.$validator.errors.first("confirm password")))]):_vm._e(),(!_vm.isconfirmPassValid)?_c('span',{staticClass:"is-invalid"},[_vm._v("Password Mismatched ")]):_vm._e()]),_c('div',{staticClass:"forgot-email-verify-submit-btn"},[_c('button',{staticClass:"btn",class:_vm.isLoading ? 'disable-btn' : '',attrs:{"type":"submit"}},[_c('span',[_vm._v("Submit")]),(!_vm.isLoading)?_c('b-icon',{attrs:{"icon":"arrow-right"}}):_vm._e(),(_vm.isLoading)?_c('AppLoaderComponent',{attrs:{"type":'small'}}):_vm._e()],1)])]):_c('div',{staticClass:"parentBtn"},[_c('button',{staticClass:"btn",attrs:{"type":"button"},on:{"click":function($event){return _vm.forgetpasswordBacktoLogin()}}},[_vm._v(" Back to Login ")])])])])]),_vm._m(1)])])]):_vm._e()
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"program-content"},[_c('a',{staticClass:"image-route",attrs:{"href":"/home"}},[_c('img',{attrs:{"src":require("../../assets/images/cfss-logo.png")}})]),_c('p',{staticClass:"program-title"},[_vm._v(" Certified Family Support Specialist Program ")])])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"login-image-container"},[_c('img',{staticClass:"login-img",attrs:{"src":require("../../assets/images/login-page.png")}}),_c('div',{staticClass:"overlay"})])
}]

export { render, staticRenderFns }