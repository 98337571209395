var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"file-upload-Container",class:[
    _vm.addApplication ? 'width-application-upload' : 'width-upload-div',
    _vm.applicationDownload ? 'file-form' : 'file-document-div',
  ]},[(_vm.applicationDownload)?_c('div',{staticClass:"application-download"},[_vm._m(0),((_vm.applicationDownload && !_vm.isPreviewForm) || _vm.isEditFile)?_c('div',{staticClass:"form-text"},[_c('span',{on:{"click":function($event){return _vm.downloadFile(_vm.attachmentData.authorizationDoc)}}},[_c('b-icon',{attrs:{"icon":"download"}})],1)]):_vm._e()]):_vm._e(),_c('p',{staticClass:"file-name"},[_vm._v(_vm._s(_vm.documentName))]),_c('p',{staticClass:"p-note"},[_vm._v(_vm._s(_vm.documentDecription))]),_c('p',{staticClass:"p-note"},[_vm._v(_vm._s(_vm.documentSubDecription))]),(!_vm.files.length && !_vm.isUploadStart)?_c('div',{staticClass:"file-layer",class:_vm.isPreviewForm && !_vm.isEditFile ? 'pointer-events-none' : ''},[_c('label',{staticClass:"file-upload",class:_vm.addApplication ? 'file-upload' : 'file-upload file-bg',attrs:{"for":_vm.keyName}},[_c('div',{staticClass:"file-select",class:_vm.applicationDownload ? 'file-select-form' : ''},[_c('p',{staticClass:"file-content"},[_vm._v(" "+_vm._s(_vm.applicationDownload ? "Please Sign, Scan and Upload the above form in the Upload Document section" : "Drag and Drop document here")+" ")]),_c('span',{staticClass:"browse"},[_c('input',{directives:[{name:"validate",rawName:"v-validate",value:(_vm.fileValidation ? _vm.fileValidation.veeValidate : ''),expression:"fileValidation ? fileValidation.veeValidate : ''"}],staticClass:"file-input",attrs:{"id":_vm.keyName,"type":"file","name":_vm.keyName,"multiple":_vm.attachmentData && _vm.attachmentData.authorizationDoc
                ? _vm.attachmentData.authorizationDoc?.docKeyName
                : '' || _vm.isSingleFileUpload
                ? false
                : true},on:{"change":function($event){return _vm.handleFileSelect($event)}}}),_c('b-icon',{staticClass:"upload-icon",attrs:{"icon":"upload"}}),_vm._v(_vm._s(_vm.applicationDownload ? "Upload Document" : "Browse"))],1)])]),(_vm.errors.has(_vm.keyName))?_c('p',{staticClass:"is-invalid"},[_vm._v(" This field is required ")]):_vm._e()]):_vm._e(),(_vm.files.length)?_c('div',{staticClass:"fileSelect-div"},_vm._l((_vm.files),function(file,index){return _c('div',{key:index,staticClass:"file-container"},[_c('b-icon',{attrs:{"icon":_vm.fileType === 'application' ? 'file-earmark-text' : 'file-image'}}),_c('p',{staticClass:"file-name"},[_vm._v(_vm._s(file.originalFileName || file.name))]),_c('div',{staticClass:"actions"},[(!file.fileName && !_vm.isWordDoc)?_c('b-icon',{staticClass:"action-icon",attrs:{"icon":"eye"},on:{"click":function($event){return _vm.previewFile(index)}}}):_vm._e(),(file.fileName)?_c('b-icon',{staticClass:"action-icon",attrs:{"icon":"eye"},on:{"click":function($event){return _vm.toggleModal(file)}}}):_vm._e(),((_vm.isForm && !_vm.isPreviewForm) || _vm.isEditFile)?_c('b-icon',{staticClass:"close-icon",attrs:{"icon":"trash"},on:{"click":function($event){return _vm.deleteFile(file, index)}}}):_vm._e()],1)],1)}),0):_vm._e(),(_vm.isUploadStart)?_c('b-progress',{attrs:{"max":100}},[_c('div',{staticClass:"fixed-upload-text"},[_c('p',[_vm._v(" Doument uploading... "),_c('span',[_vm._v(" "+_vm._s(`${_vm.uploadProgress}%`)+" ")])])]),_c('b-progress-bar',{attrs:{"value":_vm.uploadProgress}},[_c('p',[_vm._v(" Doument uploading... "),_c('span',[_vm._v(" "+_vm._s(`${_vm.uploadProgress}%`)+" ")])])])],1):_vm._e(),(
      !_vm.hideAddMoreButton && _vm.files.length && (!_vm.isPreviewForm || _vm.isEditFile)
    )?_c('div',{staticClass:"additional-image-upload"},[(!_vm.attachmentData.authorizationDoc?.docKeyName)?_c('label',{staticClass:"file-name",attrs:{"for":_vm.keyName}},[_c('b-icon',{staticClass:"add-icon",attrs:{"icon":"plus-lg"}}),_vm._v("Add more attachments "),_c('input',{staticClass:"file-input",attrs:{"id":_vm.keyName,"type":"file"},on:{"change":function($event){return _vm.handleFileSelect($event)}}})],1):_vm._e(),(_vm.isUpload && !_vm.addApplication && !_vm.uploadInProgress)?_c('span',{staticClass:"upload",on:{"click":function($event){return _vm.uploadFile()}}},[_vm._v("Upload")]):_vm._e()]):_vm._e(),(_vm.uploadMsgShow && !_vm.isUploadStart)?_c('p',{staticClass:"is-invalid"},[_vm._v(" Please click the upload button ")]):_vm._e(),(_vm.uploadInProgress)?_c('p',{staticClass:"is-invalid"},[_vm._v(" Please wait until upload is complete! ")]):_vm._e(),(_vm.previewData && _vm.showPreviewPopup)?_c('PreviewPopupComponent',{attrs:{"fileDetails":_vm.previewData,"keyName":'file'},on:{"hidePreviewPopup":function($event){return _vm.hidePreviewPopup($event)}}}):_vm._e(),(_vm.errorMessage)?_c('ErrorPopupComponent',{attrs:{"componentName":'file-upload-error-popup',"errorMessage":_vm.errorMessage},on:{"closdedErrorPopup":function($event){return _vm.closdedErrorPopup($event)}}}):_vm._e()],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"form-title"},[_c('span',[_vm._v("Authorization of Support Form Template")])])
}]

export { render, staticRenderFns }