export const HEADER_QUERY = `HeaderMenu {  
  headerMenus(sort: "order_no:ASC"){
    data {
      id
      attributes {
        order_no
            menus {
              label
              url
              slug
              sub_menus {
                label
                url
                slug
              }
            }
      }
    }
  }
}`;

export const HEADER_METADATA_QUERY = `HeaderMetadata {  
  headerMetadatas(sort: "id:ASC"){
      data {
        id
        attributes {
          url
          logo {
            data {
              attributes {
                url
                name
              }
            }
          }
          button {
                url
                label
          }
        }
      }
    }
  }`;
