import { Component, Vue } from "vue-property-decorator";
import { gql } from "graphql-tag";
import { CERTIFICATION_QUERY } from "@/graphql/testimonial-query";
import { CertificationData } from "@/models/cfss-main-content/cfss-main-content.interface";

@Component({})
export default class CfssCertificationComponent extends Vue {
  public certificationContentData: CertificationData[] = [];
  public certificationIcon = {
    name: "",
    url: "",
  };
  public async created() {
    await this.$apollo
      .query({
        query: gql`query ${CERTIFICATION_QUERY}`,
      })
      .then((result) => {
        this.certificationContentData = result.data.certificationContents.data;
      });
  }

  public navigateForm(data: CertificationData) {
    this.$router.push(data.attributes.button.url);
  }
}
