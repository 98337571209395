var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"renewal-employment-application-main-container"},[_c('div',{staticClass:"main-content"},[_c('div',{class:`${_vm.isScroll ? 'stick' : ''}`,attrs:{"id":"sticky"}},[_c('div',{staticClass:"title-header"},[_c('h3',{staticClass:"title"},[_vm._v(_vm._s(_vm.formTitle.main_title))]),_c('h1',{staticClass:"sub-title"},[_vm._v(" "+_vm._s(_vm.formTitle.application_name)+" ")])])]),_vm._l((_vm.formData),function(formContent,index){return _c('div',{key:index,staticClass:"container-form"},[_c('b-form',[_vm._l((_vm.formNames),function(formName,i){return _c('div',{key:i,staticClass:"form-page"},[(formContent.parent_slug === formName)?_c('div',[_c('div',{staticClass:"description"},[_c('p',{class:formContent.fields?.headingBeforeDescription
                      ? 'p-note'
                      : '',domProps:{"innerHTML":_vm._s(formContent.fields?.headingBeforeDescription)}}),_c('p',{class:formContent.fields?.headingSubDescription ? 'p-note' : '',domProps:{"innerHTML":_vm._s(formContent.fields?.headingSubDescription)}}),_c('h1',{staticClass:"form-title"},[_vm._v(" "+_vm._s(formContent.fields.formHeader)+" ")]),_c('p',{class:formContent.fields?.headingAfterDescription
                      ? 'p-note'
                      : '',domProps:{"innerHTML":_vm._s(formContent.fields?.headingAfterDescription)}})]),_vm._l((formContent.fields
                  .formInputs),function(formInputData,indexi){return _c('div',{key:indexi,class:['fields-main-container', formInputData[0].mainClass]},[_c('div',{staticClass:"form-div",class:[
                    formInputData.length == 2
                      ? 'double-input'
                      : formInputData.length > 2
                      ? 'multi-input'
                      : '',
                  ]},_vm._l((formInputData),function(formInput,indexj){return _c('div',{key:indexj,staticClass:"form-label",class:[
                      formInput.fieldClass,
                      formInput.field === 'b-form-textarea'
                        ? 'textarea-field'
                        : '',
                      _vm.classNameChooser(formInput?.class),
                    ]},[(
                        formInput.name !== 'applicantFullName' &&
                        formInput.name !== 'fullName' &&
                        formInput.name !== 'fullAddress'
                      )?[(!formInput.label)?_c('div',{class:'label-container'},[_c('span',{staticClass:"visiblity-hidden label"},[_vm._v(" "+_vm._s(formInputData[0].label)+" ")])]):_vm._e(),(formInput.label)?_c('div',{class:formInput.label !== null
                            ? 'label-container'
                            : 'display-label'},[(formInput.label)?_c('span',{staticClass:"label",domProps:{"innerHTML":_vm._s(formInput.label + `&nbsp;&nbsp;`)}}):_vm._e(),(
                            formInput.label &&
                            formInput?.validate?.required === true
                          )?_c('span',{staticClass:"text-danger"},[_vm._v("*")]):_vm._e()]):_vm._e(),_c(formInput.field,_vm._b({directives:[{name:"validate",rawName:"v-validate",value:(formInput?.validate?.veeValidate),expression:"formInput?.validate?.veeValidate"}],tag:"component",class:formInput.class,attrs:{"name":formInput?.validate?.name,"employmentPayLoadData":_vm.payLoadData[formName],"employmentPayLoadDataAll":_vm.payLoadData,"formInput":formInput.propBind,"autocomplete":"off","formIndex":indexi,"signatureType":{
                          payloadName: 'renewalEmploymentPayLoadData',
                          payloadSignatureKey: formName,
                          resetButton: true,
                        },"signatureLabelAndValidate":{
                          label: formInput?.signatureLabel,
                          validate: formInput?.signatureValidate,
                        },"textAreaMaxlength":formInput?.propBind?.maxlength,"editForm":true,"formType":'default'},on:{"input":function($event){return _vm.fieldUpdate(formInput.name)}},nativeOn:{"keypress":function($event){_vm.eventBind[formInput?.propBind?.eventName]
                            ? _vm.fieldValidation(
                                $event,
                                formInput?.propBind?.eventName,
                                formInput?.propBind.type,
                                formInput?.validate?.maxLength,
                                formInput?.name
                              )
                            : _vm.emptyEvent}},model:{value:(_vm.payLoadData[formName][formInput.name]),callback:function ($$v) {_vm.$set(_vm.payLoadData[formName], formInput.name, $$v)},expression:"payLoadData[formName][formInput.name]"}},'component',{
                          ...formInput.propBind,
                          signatureInvalid: _vm.signatureInvalid,
                        },false)),(
                          formInput.field === 'b-form-textarea' &&
                          formInput.propBind.maxlength
                        )?_c('div',{staticClass:"word-count-div"},[_c('p',{staticClass:"word-count"},[_vm._v(" "+_vm._s(_vm.payLoadData[formName][formInput.name].length)+"/"+_vm._s(formInput.propBind.maxlength)+" ")])]):_vm._e(),_c('label',{staticClass:"label-span"},[_vm._v(_vm._s(formInput.subLabel))]),(
                          _vm.checkValidForm &&
                          _vm.$validator.errors.has(formInput?.validate?.name)
                        )?_c('span',{staticClass:"is-invalid"},[_vm._v(" "+_vm._s(formInput.propBind.type === "email" || formInput.name === "zip" ? _vm.$validator.errors.first( formInput?.validate?.name ) : formInput.propBind.type === "date" ? "Please enter valid date" : formInput.name === "state" ? "This field must be 2 letter" : "This field is required"))]):_vm._e()]:_vm._e()],2)}),0)])})],2):_vm._e()])})],2)],1)}),_c('div',{staticClass:"action-btn",class:_vm.formPagination > 0 ? 'position-change' : ''},[_c('div',{staticClass:"right-btn-div"},[(_vm.formPagination < _vm.formData.length - 2 && !_vm.saveDataDraft)?_c('b-button',{staticClass:"save-draft-btn btn",attrs:{"variant":"outline-primary","type":"button"},on:{"click":_vm.saveDraft}},[_vm._v(" Save Draft "),_c('b-icon',{attrs:{"icon":"file-earmark-medical"}})],1):_vm._e(),(_vm.formPagination < _vm.formData.length && _vm.saveDataDraft)?_c('b-button',{staticClass:"saved-draft-btn btn",attrs:{"variant":"outline-primary","type":"button"}},[_vm._v(" Saved "),_c('b-icon',{staticClass:"check-icon",attrs:{"icon":"check-lg"}})],1):_vm._e(),_c('b-button',{staticClass:"next-btn btn",attrs:{"variant":"outline-primary","type":"button"},on:{"click":_vm.onSubmit}},[_vm._v(" Submit "),_c('b-icon',{attrs:{"icon":"chevron-compact-right"}})],1)],1)]),(_vm.errorMessage)?_c('ErrorPopupComponent',{attrs:{"componentName":'renewal-summary-form-error-popup',"errorMessage":_vm.errorMessage},on:{"closdedErrorPopup":function($event){return _vm.closdedErrorPopup($event)}}}):_vm._e(),_c('b-toast',{attrs:{"id":"example-toast","no-close-button":"","no-hover-pause":"","toaster":"b-toaster-top-center","auto-hide-delay":"2000"}},[_vm._v(" Details Entered are saved... "),_c('button',{staticClass:"close-btn",on:{"click":function($event){return _vm.closeToast()}}},[_vm._v("CLOSE")])])],2),(_vm.isLoading)?_c('AppLoaderComponent',{attrs:{"type":'large'}}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }