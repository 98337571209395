import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import { gql } from "graphql-tag";
import RadioButtonComponent from "../radio-button/radio-button.component.vue";
import CheckboxButtonComponent from "../checkbox-button/checkbox-button.component.vue";
import SingleCheckboxButton from "../single-checkbox-button/single-checkbox-button.component.vue";
import InputOtherComponent from "../input-other/input-other.component.vue";
import SignatureSubmissionComponent from "../signature-submission/signature-submission.component.vue";
import { utils } from "@/utils/utils";
import InputTextAreaComponent from "../input-textarea/input-textarea.component.vue";
import {
  CertificationForm,
  FormNavbar,
  FormFieldContents,
  addSupervisor,
  MultiFormData,
} from "@/models/certification-form/certification-form.interface";
import { certificationFormApi } from "@/providers/apis/certification-form";
import DemographicDataComponent from "../demographic-data/demographic-data.component.vue";
import ExperienceInformationComponent from "../experience-information/experience-information.component.vue";
import ScopeOfActivitiyComponent from "../scope-of-Activitie/scope-of-activitie.component.vue";
import FileUploadComponent from "../file-upload/file-upload.component.vue";
import { INITIAL_APPLICATION_QUERY } from "@/graphql/training-application-form-query";
import { User } from "@/models/common-interface/common.interface";
import AppLoaderComponent from "../app-loader/app-loader.component.vue";
import EditHistoryComponent from "../edit-history/edit-history.component.vue";
import ErrorPopupComponent from "../error-popup/error-popup.component.vue";
import { summary } from "@/models/reviewer-certification-applications/reviewer-certification-applications.interface";
import FormInputDatepickerComponent from "../form-input-datepicker/form-input-datepicker.component.vue";

export enum ApplicationFormName {
  LEGAL_FORM = "Legal Custody Document",
  AUTHORIZATION_DOCUMENT = "Authorization Document",
}
@Component({
  name: "certification-preview-form",
  components: {
    AppLoaderComponent,
    "b-form-radio-group": RadioButtonComponent,
    "b-form-checkbox": CheckboxButtonComponent,
    "b-form-single-checkbox": SingleCheckboxButton,
    "b-form-checkbox-input": InputOtherComponent,
    "b-form-signature-submission": SignatureSubmissionComponent,
    "b-demographic-form": DemographicDataComponent,
    "experience-information": ExperienceInformationComponent,
    "b-form-textarea": InputTextAreaComponent,
    "scope-of-activity": ScopeOfActivitiyComponent,
    "b-form-upload-file": FileUploadComponent,
    "b-form-input-datepicker": FormInputDatepickerComponent,
    EditHistoryComponent,
    ErrorPopupComponent,
  },
})
export default class InitialPreviewForm extends Vue {
  @Prop()
  public previewData: any;
  @Prop()
  public tabSelect!: string;
  @Prop()
  public isReviewerPreviewForm!: string;
  @Prop()
  public referenceData!: any[];
  @Prop()
  public progressStatus: any;
  @Prop()
  public checkFormValidation!: boolean;
  @Prop()
  public isUserApplication!: boolean;
  @Prop()
  public isMigrationForm!: boolean;
  @Prop()
  public supervisorData!: any[];
  @Prop()
  public applicationId!: any;

  public referenceList: any[] = [];
  public hideDiv: string[] = [
    "supervisorEmployment",
    "primaryParent",
    "primaryParentUpload",
    "endDate",
    "demonstratedUpload",
    "documentofLegalCustody",
    "biologicalParentDocument",
    "primaryParentApplicationUpload",
    "highSchool",
    "highSchoolNotListed",
  ];
  public checkField: string[] = [
    "volunteerOrApprovedAgency",
    "biologicalParent",
    "primaryParent",
    "currentlyEmployed",
    "demonstrated",
    "biologicalParentDocument",
    "primaryParentApplicationUpload",
    "documentofLegalCustody",
    "highSchool",
    "highSchoolNotListed",
    "highSchoolDiplomaOrGed",
  ];
  public applicationData: Array<CertificationForm> = [];
  public applicationNavData: Array<FormNavbar> = [];
  public formData: Array<FormFieldContents> = [];
  public supervisorFormFields: addSupervisor[] = [];
  public supervisorList: any[] = [];
  public storeReferenceEmail: string[] = [];
  public notFilesUploadedKeyNames: string[] = [];
  public addReferenceForm: MultiFormData[] = [];
  public supervisorUIUpdate = false;
  public supervisorEndDateShowIdx: number[] = [];
  public supervisorStartDateShowIdx: number[] = [];
  public limitationList: any;
  public currentRoute = "";
  public errorMessage = "";

  public referenceIndex = 0;
  public isClickSavebtn = "false";
  public isMobileView = false;
  public isFileValid = false;
  public editApplication = false;
  public isEditReviewerForm = false;
  public disabled = true;
  public isLoading = true;
  public checkValidation = false;
  public isConfirm = false;
  public editIndex!: number;
  public previewPayload: any = {};
  public buildReferenceObjcet: any = {};
  public userDetails: User = {} as User;
  public originalData: any = {};
  public isLargeLoading = false;
  public eventBind: any = {
    acceptNumberOnly: this.numberValidation,
    avoidNumbersAndSpecialChar: this.avoidNumbersAndSpecialCharacter,
    telCharacter: this.telCharacterValidate,
    onlyAlphabets: this.onlyAlphabets,
  };
  public emptyValue = utils.emptyValues;
  public isEditReference = false;

  public created() {
    this.userDetails = utils.getUserDetails();
    this.isLoading = true;
    if (utils.checkIsMobileView() || utils.checkIsTabView()) {
      this.isMobileView = true;
    } else {
      this.isMobileView = false;
    }
    this.currentRoute = this.$route.path;
    let payloadStorage: any = localStorage.getItem("certificationFormPayload");
    if (payloadStorage && this.currentRoute.split("/")[1] === "form") {
      payloadStorage = JSON.parse(payloadStorage);
      this.previewPayload = { ...payloadStorage };
      this.fullNameAndAddressBuild(this.previewPayload);
    } else {
      this.previewPayload = this.previewData;
      this.fullNameAndAddressBuild(this.previewPayload);
    }
    this.$apollo
      .query({
        query: gql`query ${INITIAL_APPLICATION_QUERY}`,
      })
      .then((result) => {
        this.applicationData = result.data.initialApplications.data;
        this.applicationNavData = this.applicationData[0].attributes.navbar;
        this.formData = this.applicationData[0].attributes.form_fields;

        // for Supervisor Information
        if (this.previewPayload.certificationPartTwoSupervisorInformation) {
          this.showForm("certificationPartTwoSupervisorInformation");
          for (let index = 0; index < this.checkField.length; index++) {
            for (
              let i = 0;
              i <
              this.previewPayload.certificationPartTwoSupervisorInformation
                .length;
              i++
            ) {
              this.fieldUpdated(this.checkField[index], i);
            }
          }
          const supervisorEmail: any[] = [];
          this.supervisorData &&
            this.supervisorData.forEach((data: any) => {
              supervisorEmail.push(data.email);
            });
          this.previewPayload.certificationPartTwoSupervisorInformation.map(
            (supervisor: any) => {
              const refIdx = supervisorEmail.findIndex(
                (supervisorEmail: string) => {
                  return supervisorEmail === supervisor.supervisorEmail;
                }
              );
              if (refIdx > -1) {
                this.supervisorList.push(this.supervisorData[refIdx]);
              }
            }
          );
        }

        // For Reference Information
        if (this.previewPayload.certificationPartTwoRefererenceInformation) {
          this.showForm("certificationPartTwoRefererenceInformation");
          const referenceEmail: any[] = [];
          this.referenceData &&
            this.referenceData.forEach((data: any) => {
              referenceEmail.push(data.email);
            });
          this.previewPayload.certificationPartTwoRefererenceInformation.map(
            (reference: any) => {
              const refIdx = referenceEmail.findIndex((refEmail: string) => {
                return refEmail === reference.referenceEmailAddress;
              });
              if (refIdx > -1) {
                this.referenceList.push(this.referenceData[refIdx]);
              }
            }
          );
        }
        this.limitationList = this.formData.length - 1;
        this.isLoading = false;
        this.originalData = JSON.parse(JSON.stringify(this.previewPayload));
      });
  }

  public showForm(formName: string) {
    const formData = this.previewPayload[formName];
    if (formData.length) {
      if (formName === "certificationPartTwoRefererenceInformation") {
        this.addReferenceForm = [];
      }
      if (formName === "certificationPartTwoSupervisorInformation") {
        this.supervisorFormFields = [];
      }
      formData.forEach(() => {
        if (formName === "certificationPartTwoRefererenceInformation") {
          this.addReferenceForm.push(this.formData[3].fields.multiForm[0]);
        }
        if (formName === "certificationPartTwoSupervisorInformation") {
          this.supervisorFormFields.push(
            this.applicationData[0].attributes.form_fields[4].fields
              .multiForm[0]
          );
        }
      });
    }
  }

  public editForm(slug: string) {
    this.$emit("applicationSlug", slug);
  }

  public references(index: number) {
    this.referenceIndex = index;
  }

  public referenceStatusUpdate(referenceEmail: string, status: string) {
    this.buildReferenceObjcet = Object.assign(
      { [referenceEmail]: status },
      this.buildReferenceObjcet
    );
    this.storeReferenceEmail = Object.keys(this.buildReferenceObjcet);
  }

  public onCilckSave() {
    this.checkValidation = true;
    const id = this.$route.path.split("/")[3];
    if (this.isMigrationForm) {
      this.editApplication = true;
      this.isClickSavebtn = this.changeStateForFieldUpdate(this.isClickSavebtn);
      return;
    }
    if (
      this.previewPayload.certificationPartTwoRefererenceInformation &&
      this.previewPayload.certificationPartTwoRefererenceInformation.length
    ) {
      const referenceData: { oldEmail: any; newEmail: any }[] = [];
      if (
        this.previewPayload.certificationPartTwoRefererenceInformation[
          this.editIndex
        ]?.referenceEmailAddress !==
        this.originalData.certificationPartTwoRefererenceInformation[
          this.editIndex
        ]?.referenceEmailAddress
      ) {
        const data = {
          oldEmail:
            this.originalData.certificationPartTwoRefererenceInformation[
              this.editIndex
            ].referenceEmailAddress,
          newEmail:
            this.previewPayload.certificationPartTwoRefererenceInformation[
              this.editIndex
            ].referenceEmailAddress,
        };
        referenceData.push(data);
      }
      if (referenceData && referenceData.length) {
        this.previewPayload.referralEmails = referenceData;
      }
    }
    if (
      this.previewPayload.certificationPartTwoSupervisorInformation &&
      this.previewPayload.certificationPartTwoSupervisorInformation.length
    ) {
      const supervisorEmails = [];
      if (
        this.previewPayload.certificationPartTwoSupervisorInformation[
          this.editIndex
        ]?.supervisorEmail !==
        this.originalData.certificationPartTwoSupervisorInformation[
          this.editIndex
        ]?.supervisorEmail
      ) {
        const data = {
          oldEmail:
            this.originalData.certificationPartTwoSupervisorInformation[
              this.editIndex
            ].supervisorEmail,
          newEmail:
            this.previewPayload.certificationPartTwoSupervisorInformation[
              this.editIndex
            ].supervisorEmail,
        };
        supervisorEmails.push(data);
      }
      if (supervisorEmails && supervisorEmails.length) {
        this.previewPayload.supervisorEmails = supervisorEmails;
      }
    }
    this.$validator.validateAll().then((validate) => {
      if (validate) {
        const duplicateSupervisorEmail =
          this.previewPayload.certificationPartTwoSupervisorInformation.filter(
            (supervisor: any) => {
              if (
                supervisor.supervisorEmail.toLowerCase() ===
                this.previewPayload.certificationPartTwoApplicationForm.email.toLowerCase()
              ) {
                return supervisor;
              }
            }
          );
        if (duplicateSupervisorEmail.length) {
          this.$bvModal.show("error-modal-center");
          this.errorMessage =
            "Applicant email id and supervisor email id must be different *";
          return;
        }

        const duplicateEmail =
          this.previewPayload.certificationPartTwoRefererenceInformation.filter(
            (reference: any) => {
              if (
                reference.referenceEmailAddress ===
                this.previewPayload.certificationPartTwoApplicationForm.email
              ) {
                return reference;
              }
            }
          );
        if (duplicateEmail.length) {
          this.$bvModal.show("error-modal-center");
          this.errorMessage =
            "Applicant email id and reference email id must be different *";
          return;
        }
        const referenceDuplicateEmail =
          this.previewPayload.certificationPartTwoRefererenceInformation.filter(
            (reference: any) => {
              return this.previewPayload.certificationPartTwoSupervisorInformation.find(
                (supervisor: any) => {
                  return (
                    supervisor.supervisorEmail.toLowerCase() ===
                    reference.referenceEmailAddress.toLowerCase()
                  );
                }
              );
            }
          );
        if (referenceDuplicateEmail.length) {
          this.$bvModal.show("error-modal-center");
          this.errorMessage =
            "Supervisor email id and reference email id must be different *";
          return;
        }
        if (
          this.tabSelect === "certification-references" ||
          this.tabSelect === "certification-supervisor-details"
        ) {
          const originalData =
            this.tabSelect === "certification-references"
              ? this.originalData.certificationPartTwoRefererenceInformation
              : this.originalData.certificationPartTwoSupervisorInformation;

          const previewPayload =
            this.tabSelect === "certification-references"
              ? this.previewPayload.certificationPartTwoRefererenceInformation
              : this.previewPayload.certificationPartTwoSupervisorInformation;
          const duplicateReferenceEmail = originalData.filter(
            (reference: any) => {
              if (this.tabSelect === "certification-references") {
                if (
                  this.previewPayload &&
                  this.previewPayload.referralEmails &&
                  this.previewPayload.referralEmails[0] &&
                  reference.referenceEmailAddress.toLowerCase() ===
                    this.previewPayload.referralEmails[0].newEmail.toLowerCase()
                ) {
                  return reference;
                }
              } else {
                if (
                  this.previewPayload &&
                  this.previewPayload.supervisorEmails &&
                  this.previewPayload.supervisorEmails[0] &&
                  reference.supervisorEmail.toLowerCase() ===
                    this.previewPayload.supervisorEmails[0].newEmail.toLowerCase()
                ) {
                  return reference;
                }
              }
            }
          );
          if (duplicateReferenceEmail.length) {
            const text =
              this.tabSelect === "certification-references"
                ? "Reference"
                : "Supervisor";
            this.errorMessage = `${text} email id must be different *`;
            return;
          }
        }
        if (this.currentRoute.split("/")[2] === "my-application") {
          const id = this.applicationId;
          this.isLargeLoading = true;
          certificationFormApi
            .updatePeerReviewerCertificationForm(this.previewPayload, id)
            .then(() => {
              this.isLargeLoading = false;
              this.isEditReviewerForm = false;
              this.disabled = true;
              if (
                (this.previewPayload.referralEmails &&
                  this.previewPayload.referralEmails.length) ||
                (this.previewPayload.supervisorEmails &&
                  this.previewPayload.supervisorEmails.length)
              ) {
                let type;
                let email;
                if (
                  this.previewPayload.referralEmails &&
                  this.previewPayload.referralEmails.length
                ) {
                  type = "Reference";
                  email = this.previewPayload.referralEmails[0].newEmail;
                }
                if (
                  this.previewPayload.supervisorEmails &&
                  this.previewPayload.supervisorEmails.length
                ) {
                  type = "Supervisor";
                  email = this.previewPayload.supervisorEmails[0].newEmail;
                }
                const data = {
                  isUpdateData: false,
                  isUpdateEmail: true,
                  type: type,
                  email: email,
                };
                this.$emit("getPeerUpdatedData", data);
              } else {
                const data = {
                  isUpdateData: true,
                  isUpdateEmail: false,
                };
                this.$emit("getPeerUpdatedData", data);
              }
              this.$emit("isEditForm", false);
              this.$emit("editIndex", this.editIndex);
              this.previewPayload.referralEmails = [];
              this.previewPayload.supervisorEmails = [];
            })
            .catch((error: any) => {
              this.errorMessage = utils.errorMessage(error);
              this.isLoading = false;
            });
        } else {
          this.editApplication = true;
          this.isClickSavebtn = this.changeStateForFieldUpdate(
            this.isClickSavebtn
          );
        }
      } else {
        const el = document.getElementsByClassName("is-invalid")[0];
        if (el) {
          el.scrollIntoView({ behavior: "smooth" });
        }
      }
    });
  }

  public changeStateForFieldUpdate(value: string) {
    if (value === "false") {
      return "true";
    } else {
      return "false";
    }
  }

  public closePopup() {
    this.$bvModal.hide("error-modal-center");
  }

  // To Edit Application From Admin Flow

  onCilckEdit(index: number) {
    this.editIndex = index;
    this.isEditReviewerForm = true;
    this.disabled = false;
    this.$emit("isEditForm", true);
  }

  onCancelEdit() {
    this.isEditReviewerForm = false;
    this.disabled = true;
    this.editApplication = false;
    if (this.currentRoute.split("/")[2] === "my-application") {
      this.$emit("getPeerUpdatedData", false);
    } else {
      this.$emit("getUpdatedData");
      this.$emit("isEditForm", false);
    }
  }

  public editedPreviewPayloadData(event: any) {
    if (event) {
      this.previewPayload = event;
    }
  }

  public isRevertedChanges(event: boolean) {
    if (event === true) {
      this.$emit("getUpdatedData");
      this.$emit("isEditForm", false);
    }
  }

  updatePreview() {
    this.$emit("certificatePayLoadDataAll", this.previewPayload);
  }

  public isConfirmChanges(event: boolean) {
    this.isConfirm = event;
    if (this.isConfirm) {
      const id = this.$route.path.split("/")[3];
      this.isLargeLoading = true;
      this.checkValidation = false;
      certificationFormApi
        .updateReviewerCertificationForm(this.previewPayload, id)
        .then(() => {
          this.editApplication = false;
          this.isLargeLoading = false;
          this.isEditReviewerForm = false;
          if (
            (this.previewPayload.referralEmails &&
              this.previewPayload.referralEmails.length) ||
            (this.previewPayload.supervisorEmails &&
              this.previewPayload.supervisorEmails.length)
          ) {
            let type;
            let email;
            if (
              this.previewPayload.referralEmails &&
              this.previewPayload.referralEmails.length
            ) {
              type = "Reference";
              email = this.previewPayload.referralEmails[0].newEmail;
            }
            if (
              this.previewPayload.supervisorEmails &&
              this.previewPayload.supervisorEmails.length
            ) {
              type = "Supervisor";
              email = this.previewPayload.supervisorEmails[0].newEmail;
            }
            const data = {
              isUpdateData: false,
              isUpdateEmail: true,
              type: type,
              email: email,
            };
            this.$emit("isUpdateForm", data);
          } else {
            const data = {
              isUpdateData: true,
              isUpdateEmail: false,
            };
            this.$emit("isUpdateForm", data);
          }
          this.$emit("editIndex", this.editIndex);
          this.previewPayload.referralEmails = [];
          this.previewPayload.supervisorEmails = [];
          this.$emit("getUpdatedData");
          this.$emit("isEditForm", false);
        })
        .catch((error) => {
          this.errorMessage = utils.errorMessage(error);
          this.isLoading = false;
          this.$bvModal.show("error-modal-center");
          this.errorMessage = utils.errorMessage(error);
        });
    }
  }

  public fieldShowAndHide(inputName: string, parentSlug = "") {
    if (!this.isEditReviewerForm) {
      const nameNotVisiable = [
        "firstName",
        "lastName",
        "addressLine1",
        "addressLine2",
        "state",
        "city",
        "zip",
        "supervisorFirstName",
        "supervisorLastName",
        "referenceFirstName",
        "referenceLastName",
      ];
      return nameNotVisiable.includes(inputName);
    } else {
      const nameNotVisiable = [
        "fullName",
        "fullAddress",
        "supervisorFullName",
        "referenceFullName",
      ];
      return nameNotVisiable.includes(inputName);
    }
  }

  public fieldUpdated(groupName = "", supervisorIdx?: number) {
    switch (groupName) {
      case "volunteerOrApprovedAgency":
        if (
          this.previewPayload.certificationPartTwoApplicationForm
            .volunteerOrApprovedAgency === "Yes"
        ) {
          this.hideDiv.splice(this.hideDiv.indexOf("supervisorEmployment"), 1);
        } else {
          if (!this.hideDiv.includes("supervisorEmployment")) {
            this.previewPayload.certificationPartTwoApplicationForm.supervisorEmployment =
              [];
            this.hideDiv.push("supervisorEmployment");
          } else {
            return;
          }
        }
        break;
      case "biologicalParent":
        if (
          this.previewPayload.certificationPartTwoApplicationForm
            .biologicalParent === "No"
        ) {
          this.hideDiv.splice(this.hideDiv.indexOf("primaryParent"), 1);
        } else {
          if (!this.hideDiv.includes("primaryParent")) {
            this.previewPayload.certificationPartTwoApplicationForm.primaryParent =
              "";
            if (!this.hideDiv.includes("primaryParent")) {
              this.hideDiv.push("primaryParent");
            }
            this.previewPayload.certificationPartTwoApplicationForm.biologicalParentDocument =
              "";
            if (!this.hideDiv.includes("biologicalParentDocument")) {
              this.hideDiv.push("biologicalParentDocument");
            }
            this.previewPayload.certificationPartTwoApplicationForm.primaryParentApplicationUpload =
              [];
            if (!this.hideDiv.includes("primaryParentApplicationUpload")) {
              this.hideDiv.push("primaryParentApplicationUpload");
            }
            this.previewPayload.certificationPartTwoApplicationForm.documentofLegalCustody =
              [];
            if (!this.hideDiv.includes("documentofLegalCustody")) {
              this.hideDiv.push("documentofLegalCustody");
            }
          } else {
            return;
          }
        }
        break;
      case "primaryParent":
        if (
          this.previewPayload.certificationPartTwoApplicationForm
            .primaryParent === "Yes"
        ) {
          this.hideDiv.splice(
            this.hideDiv.indexOf("biologicalParentDocument"),
            1
          );
        } else {
          if (!this.hideDiv.includes("biologicalParentDocument")) {
            this.previewPayload.certificationPartTwoApplicationForm.biologicalParentDocument =
              "";
            if (!this.hideDiv.includes("biologicalParentDocument")) {
              this.hideDiv.push("biologicalParentDocument");
            }
            this.previewPayload.certificationPartTwoApplicationForm.primaryParentApplicationUpload =
              [];
            if (!this.hideDiv.includes("primaryParentApplicationUpload")) {
              this.hideDiv.push("primaryParentApplicationUpload");
            }
            this.previewPayload.certificationPartTwoApplicationForm.documentofLegalCustody =
              [];
            if (!this.hideDiv.includes("documentofLegalCustody")) {
              this.hideDiv.push("documentofLegalCustody");
            }
          } else {
            return;
          }
        }
        break;
      case "biologicalParentDocument":
        if (
          this.previewPayload.certificationPartTwoApplicationForm
            .biologicalParentDocument ===
          ApplicationFormName.AUTHORIZATION_DOCUMENT
        ) {
          this.hideDiv.splice(
            this.hideDiv.indexOf("primaryParentApplicationUpload"),
            1
          );
        } else {
          if (!this.hideDiv.includes("primaryParentApplicationUpload")) {
            this.previewPayload.certificationPartTwoApplicationForm.primaryParentApplicationUpload =
              [];
            this.hideDiv.push("primaryParentApplicationUpload");
          }
        }
        if (
          this.previewPayload.certificationPartTwoApplicationForm
            .biologicalParentDocument === ApplicationFormName.LEGAL_FORM
        ) {
          this.hideDiv.splice(
            this.hideDiv.indexOf("documentofLegalCustody"),
            1
          );
        } else {
          if (!this.hideDiv.includes("documentofLegalCustody")) {
            this.previewPayload.certificationPartTwoApplicationForm.documentofLegalCustody =
              [];
            this.hideDiv.push("documentofLegalCustody");
          }
        }
        break;
      case "currentlyEmployed":
        if (supervisorIdx !== undefined) {
          if (
            this.previewPayload.certificationPartTwoSupervisorInformation[
              supervisorIdx
            ].currentlyEmployed === "No"
          ) {
            if (supervisorIdx !== undefined) {
              if (this.supervisorEndDateShowIdx.length) {
                this.supervisorEndDateShowIdx.push(supervisorIdx);
              } else {
                this.supervisorEndDateShowIdx = [supervisorIdx];
              }
              if (!this.supervisorStartDateShowIdx.includes(supervisorIdx)) {
                if (this.supervisorStartDateShowIdx.length) {
                  this.supervisorStartDateShowIdx.push(supervisorIdx);
                } else {
                  this.supervisorStartDateShowIdx = [supervisorIdx];
                }
              }
            }
            this.supervisorUIUpdate = utils.UIUpdateUsingKey(
              this.supervisorUIUpdate
            );
          } else {
            if (!this.supervisorStartDateShowIdx.includes(supervisorIdx)) {
              if (this.supervisorStartDateShowIdx.length) {
                this.supervisorStartDateShowIdx.push(supervisorIdx);
              } else {
                this.supervisorStartDateShowIdx = [supervisorIdx];
              }
            }
            const idx = this.supervisorEndDateShowIdx.findIndex(
              (element) => element === supervisorIdx
            );
            if (idx > -1) {
              this.supervisorEndDateShowIdx.splice(idx);
              this.previewPayload.certificationPartTwoSupervisorInformation[
                supervisorIdx
              ].endDate = "";
            }
            this.supervisorUIUpdate = utils.UIUpdateUsingKey(
              this.supervisorUIUpdate
            );
          }
        }
        break;
      case "demonstrated":
        if (
          this.previewPayload.certificationPartTwoApplicationForm
            .demonstrated === "Yes"
        ) {
          this.hideDiv.splice(this.hideDiv.indexOf("demonstratedUpload"), 1);
        } else {
          if (!this.hideDiv.includes("demonstratedUpload")) {
            this.previewPayload.certificationPartTwoApplicationForm.demonstratedUpload =
              [];
            this.hideDiv.push("demonstratedUpload");
          } else {
            return;
          }
        }
        break;

      case "highSchoolDiplomaOrGed":
        if (
          this.previewPayload.certificationPartTwoApplicationForm
            .highSchoolDiplomaOrGed === "Yes"
        ) {
          this.hideDiv.splice(this.hideDiv.indexOf("highSchool"), 1);
        } else {
          if (!this.hideDiv.includes("highSchool")) {
            this.previewPayload.certificationPartTwoApplicationForm.highSchool =
              [];

            if (!this.hideDiv.includes("highSchool")) {
              this.hideDiv.push("highSchool");
            }
            this.previewPayload.certificationPartTwoApplicationForm.highSchoolNotListed =
              "";

            if (!this.hideDiv.includes("highSchoolNotListed")) {
              this.hideDiv.push("highSchoolNotListed");
            }
            this.previewPayload.certificationPartTwoApplicationForm.biologicalParentDocument =
              "";
            if (!this.hideDiv.includes("biologicalParentDocument")) {
              this.hideDiv.push("biologicalParentDocument");
            }
            this.previewPayload.certificationPartTwoApplicationForm.primaryParentApplicationUpload =
              [];
            if (!this.hideDiv.includes("primaryParentApplicationUpload")) {
              this.hideDiv.push("primaryParentApplicationUpload");
            }
            this.previewPayload.certificationPartTwoApplicationForm.documentofLegalCustody =
              [];
            if (!this.hideDiv.includes("documentofLegalCustody")) {
              this.hideDiv.push("documentofLegalCustody");
            }
          } else {
            return;
          }
        }
        break;
      case "highSchool":
        this.previewPayload.certificationPartTwoApplicationForm.highSchool
          ? this.previewPayload.certificationPartTwoApplicationForm.highSchool
          : (this.previewPayload.certificationPartTwoApplicationForm.highSchool =
              []);
        if (
          !this.previewPayload.certificationPartTwoApplicationForm.highSchool.includes(
            "Other"
          )
        ) {
          if (!this.hideDiv.includes("highSchoolNotListed")) {
            this.hideDiv.push("highSchoolNotListed");
            this.previewPayload.certificationPartTwoApplicationForm.highSchoolNotListed =
              "";
          } else {
            return;
          }
        } else {
          if (this.hideDiv.indexOf("highSchoolNotListed") >= 0) {
            this.hideDiv.splice(this.hideDiv.indexOf("highSchoolNotListed"), 1);
          }
        }
        break;
    }
  }

  @Watch("checkFormValidation")
  public validation() {
    this.checkValidation = true;
    this.$validator.validateAll().then((validate: any) => {
      if (!this.isMigrationForm) {
        if (validate) {
          this.$emit("isValidForm", true);
        } else {
          const el = document.getElementsByClassName("is-invalid")[0];
          if (el) {
            el.scrollIntoView({ behavior: "smooth", block: "end" });
          }
          this.$emit("isValidForm", false);
        }
      }
    });
  }

  public fullNameAndAddressBuild(data: any) {
    // Application Information Name Address
    this.previewPayload.certificationPartTwoApplicationForm.fullName = `${
      data.certificationPartTwoApplicationForm.firstName
    } ${
      data.certificationPartTwoApplicationForm.lastName
        ? data.certificationPartTwoApplicationForm.lastName
        : ""
    }`;
    const fullAddress = utils.addressBuilder(
      this.previewPayload,
      "certificationPartTwoApplicationForm",
      "default"
    );
    this.previewPayload.certificationPartTwoApplicationForm.fullAddress =
      fullAddress;
    if (this.previewPayload.certificationPartTwoSupervisorInformation) {
      for (
        let i = 0;
        i <
        this.previewPayload.certificationPartTwoSupervisorInformation.length;
        i++
      ) {
        this.previewPayload.certificationPartTwoSupervisorInformation[
          i
        ].supervisorFullName = `${data.certificationPartTwoSupervisorInformation[i].supervisorFirstName} ${data.certificationPartTwoSupervisorInformation[i].supervisorLastName}`;
      }
    }
    if (this.previewPayload.certificationPartTwoRefererenceInformation) {
      for (
        let i = 0;
        i <
        this.previewPayload.certificationPartTwoRefererenceInformation.length;
        i++
      ) {
        this.previewPayload.certificationPartTwoRefererenceInformation[
          i
        ].referenceFullName = `${this.previewPayload.certificationPartTwoRefererenceInformation[i].referenceFirstName} ${this.previewPayload.certificationPartTwoRefererenceInformation[i].referenceLastName}`;
      }
    }
  }
  public numberValidation(evt: any, length?: number) {
    utils.onlyNumberKey(evt, length);
  }
  public avoidNumbersAndSpecialCharacter(evt: any) {
    utils.avoidNumbersAndSpecialCharacters(evt);
  }

  public telCharacterValidate(evt: any, length?: number) {
    utils.telCharacterValidate(evt, length);
  }

  public emptyEvent() {
    return;
  }

  public onlyAlphabets(evt: any, fieldName = "") {
    utils.onlyAlphabets(evt, fieldName);
  }
  // File Upload and Preview

  public allFilesUploaded(event: any) {
    if (this.notFilesUploadedKeyNames.includes(event)) {
      this.notFilesUploadedKeyNames.splice(event, 1);
    }
  }

  public notFileUploaded(event: string) {
    if (!this.notFilesUploadedKeyNames.includes(event)) {
      this.notFilesUploadedKeyNames.push(event);
    }
  }

  // This method for change status word for class

  public statusWordChange(status: string) {
    return utils.statusCaseChange(status);
  }

  public fieldValidation(
    value: any,
    eventName: string,
    fieldType: string,
    maxLength = 0,
    fieldName = ""
  ) {
    if (fieldType === "number") {
      this.eventBind[eventName](value, maxLength);
    } else if (fieldName === "state") {
      this.eventBind[eventName](value, fieldName);
    } else {
      this.eventBind[eventName](value);
    }
  }

  public classNameChooser(inputClass: string) {
    return utils.classNameChooser(inputClass);
  }

  public closdedErrorPopup(event: any) {
    if (event) {
      this.errorMessage = "";
    }
  }
  public onEditReference(currentIndex: any) {
    this.isEditReference = true;
    this.editIndex = currentIndex ? currentIndex : 0;
    this.isEditReviewerForm = true;
    this.disabled = false;
    this.$emit("isEditForm", true);
  }
}
