var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"activity-main-container"},[(!_vm.isPreviewForm)?_c('div',[_c('div',{staticClass:"card-main-container"},[_c('div',{staticClass:"card-container"},_vm._l((_vm.applicationData),function(application,index){return _c('div',{key:index,staticClass:"card certification-card",on:{"click":function($event){return _vm.showModelPopup(
              application,
              index,
              application.attributes.requirement
            )}}},[_c('div',{staticClass:"card-body"},[_c('img',{staticClass:"update-icon",attrs:{"src":application.attributes.icon_image.data.attributes.url}})]),_c('div',{staticClass:"card-body card-text"},[_c('h5',{staticClass:"card-title"},[_vm._v(" "+_vm._s(application.attributes.title)+" ")])]),(_vm.filledKeyName.includes(application.attributes.requirement))?_c('div',{staticClass:"tick-wrapper"},[_c('svg',{staticClass:"checkmark",attrs:{"xmlns":"http://www.w3.org/2000/svg","viewBox":"0 0 52 52"}},[_c('circle',{staticClass:"checkmark__circle",attrs:{"cx":"26","cy":"26","r":"25","fill":"none"}}),_c('path',{staticClass:"checkmark__check",attrs:{"fill":"none","d":"M14.1 27.2l7.1 7.2 16.7-16.8"}})])]):_vm._e()])}),0),(_vm.isShowErrorMsg)?_c('span',{staticClass:"is-invalid"},[_vm._v("Please read and fill the required fields")]):_vm._e()]),_c('b-modal',{staticClass:"main-content-scope",attrs:{"id":"active-modal-center","scrollable":"","size":"xl","centered":"","hide-footer":"","title":_vm.applicationData &&
        _vm.applicationData[_vm.activeContentIndex]?.attributes?.title}},[(_vm.popupContentData)?_c('div',{staticClass:"popup-body-content model-popup"},[_c('div',{staticClass:"content-div"},[_c('p',{domProps:{"innerHTML":_vm._s(
              _vm.applicationData[_vm.activeContentIndex]?.attributes?.description
            )}}),(
              _vm.applicationData[_vm.activeContentIndex]?.attributes?.button &&
              _vm.applicationData[_vm.activeContentIndex]?.attributes?.requirement ===
                'handBook_document'
            )?_c('b-button',{staticClass:"download-btn btn",attrs:{"variant":"outline-primary","type":"button"},on:{"click":function($event){return _vm.onClickDownload(
                _vm.applicationData[_vm.activeContentIndex]?.attributes?.docKeyName
              )}}},[_vm._v(" "+_vm._s(_vm.applicationData[_vm.activeContentIndex]?.attributes?.button.label)+" "),_c('b-icon',{attrs:{"icon":"download"}})],1):_vm._e()],1),_c('div',{staticClass:"form-div"},[_c('div',{staticClass:"input-container"},_vm._l((_vm.applicationData[
                _vm.activeContentIndex
              ]?.attributes?.form_fields?.fields?.formInputs),function(formInputData,indexi){return _c('div',{key:indexi,staticClass:"margin",class:[
                formInputData.length == 2
                  ? 'double-input'
                  : formInputData.length == 3
                  ? 'multi-input'
                  : '',
                formInputData[0].mainClass,
              ]},_vm._l((formInputData),function(formInput,indexj){return _c('div',{key:indexj,class:formInput.fieldClass},[_c('div',{class:formInput.label !== null
                      ? 'label-containe'
                      : 'display-label'},[(formInput.label)?_c('span',{staticClass:"label-text",domProps:{"innerHTML":_vm._s(formInput.label + `&nbsp;&nbsp;`)}}):_vm._e(),(
                      formInput.label &&
                      formInput?.validate?.required === true
                    )?_c('span',{staticClass:"text-danger"},[_vm._v("*")]):_vm._e()]),_c(formInput.field,_vm._b({directives:[{name:"validate",rawName:"v-validate",value:(formInput?.validate?.veeValidate),expression:"formInput?.validate?.veeValidate"}],key:_vm.softRefersh,tag:"component",class:formInput.class,attrs:{"autocomplete":"off","label":formInput.item,"name":formInput.name},model:{value:(_vm.handBookDocumentPayload[_vm.formName][formInput.name]),callback:function ($$v) {_vm.$set(_vm.handBookDocumentPayload[_vm.formName], formInput.name, $$v)},expression:"handBookDocumentPayload[formName][formInput.name]"}},'component',{
                    ...formInput.propBind,
                    ...formInput.validate,
                    disabled: _vm.isDisable,
                  },false)),(formInput.subLabel)?_c('label',{staticClass:"label-span"},[_vm._v(_vm._s(formInput.subLabel))]):_vm._e(),(
                    formInput?.validate?.required == true &&
                    _vm.$validator.errors.has(formInput?.validate?.name)
                  )?_c('span',{staticClass:"is-invalid"},[_vm._v(" "+_vm._s(formInput.propBind.type === "email" ? _vm.$validator.errors.first(formInput?.validate?.name) : "This field is required"))]):_vm._e()],1)}),0)}),0),_c('div',{staticClass:"button-container"},[_c('div',{staticClass:"right-btn-div"},[_c('b-button',{staticClass:"next-btn btn",attrs:{"variant":"outline-primary","type":"button"},on:{"click":function($event){return _vm.nextButton(_vm.formName)}}},[_vm._v(" Next ")])],1)])])]):_vm._e()])],1):_vm._e(),(_vm.isPreviewForm)?_c('div',{staticClass:"preview-div"},_vm._l((_vm.applicationData),function(application,index){return _c('div',{key:index},[(
          application.attributes.title !== 'Scope Of Activity' &&
          _vm.isPreviewForm
        )?_c('h5',{staticClass:"form-header"},[_vm._v(" "+_vm._s(application.attributes.title)+" ")]):_vm._e(),_vm._l((application.attributes?.form_fields
          ?.fields?.formInputs),function(formInputData,indexi){return _c('div',{key:indexi,class:[
          formInputData.length == 2
            ? 'double-input'
            : formInputData.length == 3
            ? 'multi-input'
            : '',
          formInputData[0].mainClass,
        ]},_vm._l((formInputData),function(formInput,indexi){return _c('div',{key:indexi,staticClass:"input-main-container",class:formInput.fieldClass},[_c('div',{class:formInput.label !== null ? 'label-containe' : 'display-label'},[(formInput.label)?_c('span',{staticClass:"label-text",domProps:{"innerHTML":_vm._s(formInput.label + `&nbsp;&nbsp;`)}}):_vm._e(),(formInput.label && formInput?.validate?.required === true)?_c('span',{staticClass:"text-danger"},[_vm._v("*")]):_vm._e()]),_c(formInput.field,_vm._b({directives:[{name:"validate",rawName:"v-validate",value:(formInput?.validate?.veeValidate),expression:"formInput?.validate?.veeValidate"}],key:_vm.softRefersh,tag:"component",class:[
              formInput.class,
              [
                _vm.isEditFile === true
                  ? 'edit-form-enable ' + [formInput.class]
                  : 'edit-form-disable',
              ],
            ],attrs:{"editForm":_vm.editForm,"formType":_vm.formType,"autocomplete":"off","name":formInput.name,"label":formInput.item,"keyName":formInput.name},model:{value:(
              _vm.handBookDocumentPayload[
                application.attributes?.form_fields.parent_slug
              ][formInput.name]
            ),callback:function ($$v) {_vm.$set(_vm.handBookDocumentPayload[
                application.attributes?.form_fields.parent_slug
              ], formInput.name, $$v)},expression:"\n              handBookDocumentPayload[\n                application.attributes?.form_fields.parent_slug\n              ][formInput.name]\n            "}},'component',{
              ...formInput.propBind,
              ...formInput.validate,
              disabled: !_vm.editForm ? true : false,
            },false)),(
              formInput?.validate?.required == true &&
              _vm.$validator.errors.has(formInput?.validate?.name)
            )?_c('span',{staticClass:"is-invalid"},[_vm._v(_vm._s(formInput.propBind.type === "email" ? _vm.$validator.errors.first(formInput?.validate?.name) : "This field is required"))]):_vm._e()],1)}),0)})],2)}),0):_vm._e(),(_vm.previewData && _vm.showPreviewPopup)?_c('PreviewPopupComponent',{attrs:{"fileDetails":_vm.previewData,"keyName":'file'},on:{"hidePreviewPopup":function($event){return _vm.hidePreviewPopup($event)}}}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }