import { Component, Prop, Vue } from "vue-property-decorator";

@Component({
  name: "b-form-single-checkbox",
})
export default class SingleCheckboxButton extends Vue {
  // ----------------------------------------------------------------
  // Properties
  @Prop()
  public options: any;
  public selectedValue: any = null;
  private selected = "";
  @Prop()
  private value: any;

  check() {
    this.$emit("input", this.selectedValue);
  }
  public created() {
    this.selected = this.value;
    this.selectedValue = this.value;
  }
}
