var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"training-application-main-container"},[_c('div',{staticClass:"main-content"},[_c('div',{class:`${_vm.isScroll ? 'stick' : ''}`,attrs:{"id":"sticky"}},[_c('div',{staticClass:"title-header"},[_c('div',{staticClass:"cfss-logo-img",on:{"click":function($event){return _vm.goBack()}}},[_c('img',{attrs:{"src":require("../../assets/images/cfss-logo.png"),"alt":"logo-img"}})]),_c('div',[_c('h3',{staticClass:"title"},[_vm._v(_vm._s(_vm.formTitle.main_title))]),_c('h1',{staticClass:"sub-title"},[_vm._v(" "+_vm._s(_vm.formTitle.application_name)+" ")])])])]),_vm._l((_vm.formData),function(formContent,index){return _c('div',{key:index,staticClass:"container-form default-form"},[_c('b-form',{class:formContent.fields.class},[_vm._l((_vm.formNames),function(formName,i){return _c('div',{key:i,staticClass:"form-page"},[(formContent.parent_slug === formName)?_c('div',[_c('div',{staticClass:"description"},[(formContent.fields?.headingBeforeDescription)?_c('p',{staticClass:"p-note"},[_vm._v(" "+_vm._s(formContent.fields?.headingBeforeDescription)+" ")]):_vm._e(),(formContent.fields.formHeader)?_c('h1',{staticClass:"form-title"},[_vm._v(" "+_vm._s(formContent.fields.formHeader)+" ")]):_vm._e(),(formContent.fields?.headingAfterDescription)?_c('p',{staticClass:"p-note"},[_vm._v(" "+_vm._s(formContent.fields?.headingAfterDescription)+" ")]):_vm._e()]),_vm._l((formContent.fields
                  .formInputs),function(formInputData,indexi){return _c('div',{key:indexi,class:['fields-main-container', formInputData[0].mainClass]},[_c('div',{staticClass:"form-div",class:[
                    formInputData.length == 2
                      ? 'double-input'
                      : formInputData.length > 2
                      ? 'multi-input'
                      : '',
                  ]},_vm._l((formInputData),function(formInput,indexj){return _c('div',{key:indexj,staticClass:"form-label",class:[
                      formInput.fieldClass,
                      formInput.field === 'b-form-textarea'
                        ? 'textarea-field'
                        : '',
                      formInput?.class
                        ? _vm.classNameChooser(formInput?.class)
                        : '',
                    ]},[(
                        formInput.name !== 'applicantFullName' &&
                        formInput.name !== 'fullName' &&
                        formInput.name !== 'fullAddress'
                      )?[(!formInput.label)?_c('div',{class:'label-container'},[_c('span',{staticClass:"visiblity-hidden label"},[_vm._v(" "+_vm._s(formInputData[0].label)+" ")])]):_vm._e(),(formInput.label)?_c('div',{class:formInput.label !== null
                            ? 'label-container'
                            : 'display-label'},[(formInput.label)?_c('span',{staticClass:"label",domProps:{"innerHTML":_vm._s(formInput.label + `&nbsp;&nbsp;`)}}):_vm._e(),(
                            formInput.label &&
                            formInput?.validate?.required === true
                          )?_c('span',{staticClass:"text-danger"},[_vm._v("*")]):_vm._e(),(formInput.labelDescription)?_c('label',{staticClass:"label-span"},[_vm._v(_vm._s(formInput.labelDescription))]):_vm._e()]):_vm._e(),_c(formInput.field,_vm._b({directives:[{name:"validate",rawName:"v-validate",value:(formInput?.validate?.veeValidate),expression:"formInput?.validate?.veeValidate"}],tag:"component",class:formInput.class,attrs:{"inactivePayloadData":_vm.inactivePayload[formName],"inactivePayloadDataAll":_vm.inactivePayload,"formInput":formInput.propBind,"autocomplete":"off","signatureType":{
                          payloadName: 'inactiveFormPayload',
                          payloadSignatureKey: formName,
                          resetButton: false,
                        },"signatureLabelAndValidate":{
                          label: formInput?.signatureLabel,
                          validate: formInput?.signatureValidate,
                        }},nativeOn:{"keypress":function($event){_vm.eventBind[formInput?.propBind?.eventName]
                            ? _vm.fieldValidation(
                                $event,
                                formInput?.propBind?.eventName,
                                formInput?.propBind.type,
                                formInput?.validate?.maxLength,
                                formInput?.name
                              )
                            : _vm.emptyEvent}},model:{value:(_vm.inactivePayload[formName][formInput.name]),callback:function ($$v) {_vm.$set(_vm.inactivePayload[formName], formInput.name, $$v)},expression:"inactivePayload[formName][formInput.name]"}},'component',{
                          ...formInput.propBind,
                          ...formInput.validate,
                          signatureInvalid: _vm.signatureInvalid,
                        },false)),(
                          formInput.field === 'b-form-textarea' &&
                          formInput.propBind.maxlength
                        )?_c('div',{staticClass:"word-count-div"},[_c('p',{staticClass:"word-count"},[_vm._v(" "+_vm._s(_vm.inactivePayload[formName][formInput.name].length)+"/"+_vm._s(formInput.propBind.maxlength)+" ")])]):_vm._e(),(formInput.subLabel)?_c('label',{staticClass:"label-span"},[_vm._v(_vm._s(formInput.subLabel))]):_vm._e(),(
                          _vm.checkValidForm &&
                          _vm.$validator.errors.has(formInput?.validate?.name)
                        )?_c('span',{staticClass:"is-invalid"},[_vm._v(" "+_vm._s(formInput.propBind.type === "email" || formInput.name === "socialSecurityNumber" || formInput.name === "zip" ? _vm.$validator.errors.first( formInput?.validate?.name ) : formInput.propBind.type === "date" ? "Please enter valid date" : formInput.name === "allVersionNames" && _vm.inactivePayload[formName][formInput.name].length ? "This field must be 4 or less" : formInput.name === "state" ? "This field must be 2 letter" : "This field is required"))]):_vm._e()]:_vm._e()],2)}),0)])})],2):_vm._e()])})],2)],1)}),_c('div',{staticClass:"action-btn"},[_c('div',{staticClass:"right-btn-div"},[_c('b-button',{staticClass:"save-draft-btn btn",attrs:{"variant":"outline-primary","type":"button"},on:{"click":_vm.saveDraft}},[_vm._v(" Save Draft "),_c('b-icon',{attrs:{"icon":"file-earmark-medical"}})],1),_c('b-button',{staticClass:"next-btn btn",attrs:{"variant":"outline-primary","type":"button"},on:{"click":_vm.onSubmit}},[_vm._v(" Submit "),_c('b-icon',{attrs:{"icon":"chevron-compact-right"}})],1)],1)])],2),(_vm.errorMessage)?_c('ErrorPopupComponent',{attrs:{"componentName":'file-upload-error-popup',"errorMessage":_vm.errorMessage},on:{"closdedErrorPopup":function($event){return _vm.closdedErrorPopup($event)}}}):_vm._e(),(_vm.isLoading)?_c('AppLoaderComponent',{attrs:{"type":'large'}}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }