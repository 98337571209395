import { Component, Vue } from "vue-property-decorator";
import ReviewerCertificationForm from "../reviewer-certification-preview/reviewer-certification-preview.component.vue";
import ReviewerInactivePreviewForm from "../reviewer-inactive-preview/reviewer-inactive-preview.component.vue";
import RenewalPreviewApplicationComponent from "../reviewer-renewal-preview/reviewer-renewal-preview.component.vue";

@Component({
  name: "reviewer-peers-preview-application",
  components: {
    ReviewerCertificationForm,
    ReviewerInactivePreviewForm,
    RenewalPreviewApplicationComponent,
  },
})
export default class ReviewerPeersPreviewApplication extends Vue {
  public applicationName: any;

  public async created() {
    this.applicationName = this.$route.query.application;
  }
}
