import { Component, Prop, Vue } from "vue-property-decorator";

@Component({})
export default class ErrorPopupComponent extends Vue {
  @Prop()
  public componentName!: string;
  @Prop()
  public errorMessage!: string;

  public created() {
    this.$nextTick(() => {
      if (this.errorMessage) {
        this.$bvModal.show("error-modal-center");
        setTimeout(() => {
          this.$bvModal.hide("error-modal-center");
        }, 3000);
      }
    });
  }

  public closePopup() {
    this.$emit("closdedErrorPopup", true);
    this.$bvModal.hide("error-modal-center");
  }

  public hide(event: any) {
    if (event) {
      this.$emit("closdedErrorPopup", true);
    }
  }
}
