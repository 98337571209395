import { SCOPE_OF_ACTIVITY_QUERY } from "@/graphql/scope-activity-and-code-ethics-query";
import {
  User,
  docLibraryData,
} from "@/models/common-interface/common.interface";
import {
  ButtonData,
  FormFieldContents,
  ScopeOfActivitieData,
} from "@/models/scope-of-activitie/scope-of-activitie.interface";
import { gql } from "graphql-tag";
import { Component, Inject, Prop, Vue, Watch } from "vue-property-decorator";
import CheckboxSingleComponent from "../checkbox-single-check/checkbox-single-check.component.vue";
import PreviewPopupComponent from "../preview-popup/preview-popup.component.vue";
import { appConfigService } from "@/providers/services/app/app-config";
import { utils } from "@/utils/utils";

@Component({
  name: "scope-of-activity",
  components: {
    "b-form-checkbox-single": CheckboxSingleComponent,
    PreviewPopupComponent,
  },
})
export default class ScopeOfActivitiyComponent extends Vue {
  @Prop()
  public isEditFile!: boolean;
  @Prop()
  public previewScopeOfActivityData: any;
  @Prop()
  public isPreviewForm!: boolean;
  @Prop()
  public isEditReviewerForm!: boolean;
  @Prop()
  public scopeofActivityValidation!: boolean;
  @Prop()
  public applicationName!: string;
  @Prop()
  public isChangedData!: boolean;
  @Prop()
  public scopeOfActivityData!: any;
  @Prop()
  public editForm!: boolean;
  @Prop()
  public formType!: string;
  @Inject("$validator") public $validator: any;

  public popupContentData: ScopeOfActivitieData = {} as ScopeOfActivitieData;
  public formData: FormFieldContents[] = [];
  public applicationData: Array<ScopeOfActivitieData> = [];
  public softRefersh = "false";
  public currentRoute = "";
  public formName = "";
  public imgType = ["jpg", "png", "jpeg", "tiff", "pdf"];
  public filledKeyName: string[] = [];
  public isLoading = true;
  public isShowErrorMsg = false;
  public showPreviewPopup = false;
  public isDisable = false;
  public previewFile: any[] = [];
  public SOAPayloadData: any = {};
  public activeContentIndex: any = null;
  public userDetails: User = {} as User;
  public editedChanges: any[] = [];
  public revertFieldNames: string[] = [];
  public previewData: any[] = [];
  public originalData: any;
  public handBookDocumentPayload: any = {
    scopeOfActivity: {
      scopeOfActivityCurrentCertified: [],
      scopeOfActivityPrincipleCertified: [],
      scopeOfActivityFullName: "",
    },
    codeOfEthics: {
      codeOfEthicsCurrentCertified: [],
      codeOfEthicsPrincipleCertified: [],
      codeOfEthicsFullName: "",
    },
    handBookDocument: {
      handBookAcknowledge: [],
      handBookFullName: "",
    },
  };
  public getAllDoc: docLibraryData[] = [];

  public async created() {
    this.isDisable = utils.disableField(this.editForm, this.formType);
    await utils.getAllDoc().then((data) => {
      this.getAllDoc = data;
    });
    const userDetails = localStorage.getItem("user_details");
    if (userDetails) {
      this.userDetails = utils.getUserDetails();
    }
    this.currentRoute = this.$route.path;
    if (this.previewScopeOfActivityData.payloadName) {
      this.handBookDocumentPayload = this.previewScopeOfActivityData.data;
    } else {
      this.handBookDocumentPayload = this.previewScopeOfActivityData.data;
    }
    this.originalData = JSON.parse(
      JSON.stringify(this.handBookDocumentPayload)
    );
    this.checkAllSectionValid();
    this.$apollo
      .query({
        query: gql`query ${SCOPE_OF_ACTIVITY_QUERY}`,
      })
      .then((result) => {
        this.applicationData = result.data.coeSoas.data;
        this.isLoading = false;
      });
  }

  public showModelPopup(
    popupContent: ScopeOfActivitieData,
    index: number,
    formName: string
  ) {
    switch (formName) {
      case "scope_of_activity":
        this.formName = "scopeOfActivity";
        break;
      case "code_of_ethics":
        this.formName = "codeOfEthics";

        break;
      case "handBook_document":
        this.formName = "handBookDocument";
        break;
      default:
        break;
    }
    this.activeContentIndex = index;
    this.$bvModal.show("active-modal-center");
  }

  @Watch("scopeofActivityValidation")
  public SOAValidCheck() {
    if (this.filledKeyName.length > 2) {
      this.isShowErrorMsg = false;
      this.$emit("SOAValid", true);
    } else {
      this.$emit("SOAValid", false);
      this.isShowErrorMsg = true;
    }
  }

  public checkAllSectionValid() {
    for (const property in this.handBookDocumentPayload) {
      const isEmpty = Object.values(
        this.handBookDocumentPayload[property]
      ).some((data: any) => {
        return data.length === 0;
      });
      const filledField =
        property === "scopeOfActivity"
          ? "scope_of_activity"
          : property === "codeOfEthics"
          ? "code_of_ethics"
          : "handBook_document";
      if (isEmpty) {
        const idx = this.filledKeyName.findIndex((key) => {
          return key === filledField;
        });
        if (idx > -1) {
          this.filledKeyName.splice(idx, 1);
        }
      } else {
        this.filledKeyName.push(filledField);
      }
      this.filledKeyName = [...new Set(this.filledKeyName)];
    }
  }

  public updated() {
    window.document.onclick = (event) => {
      this.outSideClick(event);
    };
  }

  public outSideClick(event: any) {
    if (!this.$el.contains(event.target)) {
      this.checkAllSectionValid();
    }
  }

  // TO SWITCH NEXT PAGE
  public nextButton(formName: string) {
    const filledField =
      formName === "scopeOfActivity"
        ? "scope_of_activity"
        : formName === "codeOfEthics"
        ? "code_of_ethics"
        : "handBook_document";
    this.$validator.validateAll().then((validate: any) => {
      this.checkAllSectionValid();
      if (this.filledKeyName.length > 2) {
        this.isShowErrorMsg = false;
      }
      if (this.filledKeyName.includes(filledField) || validate) {
        this.softRefersh = this.changeStateForTraining(this.softRefersh);

        if (this.activeContentIndex === this.applicationData.length - 1) {
          this.$bvModal.hide("active-modal-center");
        }
        if (this.activeContentIndex < this.applicationData.length - 1) {
          const page = (this.activeContentIndex += 1);
          if (page == 0) {
            this.formName = "scopeOfActivity";
          } else if (page == 1) {
            this.formName = "codeOfEthics";
          } else if (page == 2) {
            this.formName = "handBookDocument";
          }
          this.activeContentIndex = page;
        }
        if (this.activeContentIndex == 0) {
          this.formName = "scopeOfActivity";
        } else if (this.activeContentIndex == 1) {
          this.formName = "codeOfEthics";
        } else if (this.activeContentIndex == 2) {
          this.formName = "handBookDocument";
        }
        const el = document.getElementsByClassName("content-div")[0];
        if (el) {
          el.scrollIntoView({ behavior: "smooth" });
        }
        this.$emit("input", { handbook: this.handBookDocumentPayload });
      } else {
        const el = document.getElementsByClassName("is-invalid")[0];
        if (el) {
          el.scrollIntoView({ behavior: "smooth" });
        }
      }
    });
  }

  public changeStateForTraining(value: string) {
    if (value === "false") {
      return "true";
    } else {
      return "false";
    }
  }

  public onClickDownload(docKeyName: string) {
    if (docKeyName) {
      const docDetials = utils.filterDoc(this.getAllDoc, docKeyName);
      this.previewData = [
        {
          fileName: "",
          width: 0,
          height: 0,
          type: docDetials.mime,
          originalFileName: docDetials.name,
          url: docDetials.url,
        },
      ];
    }
    this.showPreviewPopup = true;
  }

  public hidePreviewPopup(event: boolean) {
    if (event === true) {
      this.showPreviewPopup = false;
      this.previewFile = [];
    }
  }
}
