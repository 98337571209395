export const RENEWAL_REMAINDER = `RenewalReminders{
    renewalReminders {
        data {
            id
            attributes {
                reminder_seq
                days
            }
        }
    }
}`;
