export const ELIGIBILITY_CRITERIA_QUERY = `EligibilityCriterias {  
    eligibilityCriterias(sort: "id:ASC"){
        data{
            id
            attributes{
                criteria {
                    title
                    content
                    checkbox_label
                    form_name
                }
            }
        }
    }
}`;
