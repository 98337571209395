export const INACTIVE_APPLICATION_QUERY = `InactiveApplications {  
    inactiveApplications(sort: "id:ASC"){
      data{
        id
        attributes{
          application_name
          main_title
          form_fields{
            id
            parent_slug
            fields
            formBeforeText
        }
        }
      }
    }
  }`;
