var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"edit-history-main-contianer"},[_c('div',[(_vm.editApplication && _vm.editedFields.length)?_c('b-sidebar',{ref:"mySidebar",attrs:{"id":"edit-application-confirm-sidebar","no-header":"","right":"","backdrop":""}},[(!_vm.isEdited)?[_c('div',{staticClass:"edit-view-container",class:_vm.toogleExpand ? 'sidebar-width-adjust' : ''},[_c('div',{staticClass:"sidebar-heading"},[_c('div',{staticClass:"confirm-header"},[_c('div',{staticClass:"expand-collapse-icons",on:{"click":function($event){_vm.toogleExpand = !_vm.toogleExpand}}},[(!_vm.toogleExpand)?_c('b-icon',{staticClass:"expand-icon",attrs:{"icon":"arrows-expand"}}):_vm._e(),(_vm.toogleExpand)?_c('b-icon',{staticClass:"collapse-icon",attrs:{"icon":"arrows-collapse"}}):_vm._e()],1),_c('h4',{staticClass:"sidebar-title"},[_vm._v("Edit Application Confirmation")]),_c('b-icon',{staticClass:"close",attrs:{"icon":"x-lg"},on:{"click":function($event){return _vm.closeSidebar()}}})],1),_c('p',{staticClass:"note",domProps:{"innerHTML":_vm._s(
                `You've made ${_vm.changesCount} changes. Please review before submit the
              application.`
              )}}),_c('p',{staticClass:"note sub-note"},[_vm._v(" Please Note: The Changes can't be undone ")])]),_c('div',{key:_vm.isFieldsReferesh,staticClass:"sidebar-container"},[_c('form',{staticClass:"form-group-container"},[_vm._l((_vm.editedFields),function(editedData,indexk){return _c('div',{key:indexk,staticClass:"form-group edited-card"},[(
                    editedData.parentSlug !== 'renewalTrainingInformation'
                  )?_c('div',{staticClass:"field-container"},[(editedData.title)?_c('h5',{staticClass:"heading",domProps:{"innerHTML":_vm._s(editedData.title)}}):_vm._e(),_vm._l((editedData.updatedFields),function(fields,index){return _c('div',{key:index,staticClass:"field-changes-container"},[(fields.subFormName)?_c('div',[_c('h5',{staticClass:"sub-heading",domProps:{"innerHTML":_vm._s(fields?.subTitle)}}),_vm._l((fields.data),function(experienceData,experienceIndex){return _c('div',{key:experienceIndex,staticClass:"field-sub-div"},[_c('div',{staticClass:"changes-div old-changes"},[_c('label',{staticClass:"field-label",domProps:{"innerHTML":_vm._s(experienceData.label)}}),_c('p',{staticClass:"value",domProps:{"innerHTML":_vm._s(
                              experienceData.oldValue?.length === 0
                                ? 'No Data'
                                : experienceData.oldValue
                            )}})]),_c('b-icon',{staticClass:"arrow-right",attrs:{"icon":"arrow-right"}}),_c('div',{staticClass:"changes-div new-changes"},[_c('label',{staticClass:"field-label",domProps:{"innerHTML":_vm._s(experienceData.label)}}),_c('p',{staticClass:"value",domProps:{"innerHTML":_vm._s(
                              experienceData.newValue?.length === 0
                                ? 'No Data'
                                : experienceData.newValue
                            )}})])],1)})],2):[_c('div',{staticClass:"changes-div old-changes"},[_c('label',{staticClass:"field-label",domProps:{"innerHTML":_vm._s(fields.data.label)}}),_c('p',{staticClass:"value",domProps:{"innerHTML":_vm._s(
                            fields.data.oldValue?.length === 0
                              ? 'No Data'
                              : fields.data.oldValue
                          )}})]),_c('b-icon',{staticClass:"arrow-right",attrs:{"icon":"arrow-right"}}),_c('div',{staticClass:"changes-div new-changes"},[_c('label',{staticClass:"field-label",domProps:{"innerHTML":_vm._s(fields.data.label)}}),_c('p',{staticClass:"value",domProps:{"innerHTML":_vm._s(
                            fields.data.newValue?.length === 0
                              ? 'No Data'
                              : fields.data.newValue
                          )}})])]],2)})],2):_vm._e(),(
                    editedData.parentSlug === 'renewalTrainingInformation'
                  )?_c('div',{staticClass:"field-container"},[(editedData.title)?_c('h5',{staticClass:"heading",domProps:{"innerHTML":_vm._s(editedData.title)}}):_vm._e(),_vm._l((editedData.updatedFields),function(fields,index){return _c('div',{key:index,staticClass:"field-changes-container sub-div-container"},[(
                        !fields.data.isNewlyAdded && !fields.data.isRemoved
                      )?[_c('h5',{staticClass:"sub-heading",domProps:{"innerHTML":_vm._s(fields.data.trainingName + ' ' + 'Training')}}),_vm._l((fields.data
                          .trainingData),function(trainingData,indexi){return _c('div',{key:indexi,staticClass:"changes-main-container"},[_c('div',{staticClass:"changes-div old-changes"},[_c('label',{staticClass:"field-label",domProps:{"innerHTML":_vm._s(trainingData.label)}}),_c('p',{staticClass:"value",domProps:{"innerHTML":_vm._s(
                              trainingData.oldValue?.length === 0
                                ? 'No Data'
                                : trainingData.oldValue
                            )}})]),_c('b-icon',{staticClass:"arrow-right",attrs:{"icon":"arrow-right"}}),_c('div',{staticClass:"changes-div new-changes"},[_c('label',{staticClass:"field-label",domProps:{"innerHTML":_vm._s(trainingData.label)}}),_c('p',{staticClass:"value",domProps:{"innerHTML":_vm._s(
                              trainingData.newValue?.length === 0
                                ? 'No Data'
                                : trainingData.newValue
                            )}})])],1)})]:_vm._e(),(fields.data.isNewlyAdded)?[_c('p',{domProps:{"innerHTML":_vm._s(
                          fields.data.trainingName + ' ' + 'training is added'
                        )}})]:_vm._e(),(fields.data.isRemoved)?[_c('p',{domProps:{"innerHTML":_vm._s(
                          fields.data.trainingName +
                          ' ' +
                          'training is removed'
                        )}})]:_vm._e()],2)})],2):_vm._e()])}),_c('div',{staticClass:"form-group"},[_c('label',{staticClass:"form-label"},[_vm._v("Please specify the reasons for editing the application "),_c('span',{staticClass:"validate"},[_vm._v("*")])]),_c('textarea',{directives:[{name:"model",rawName:"v-model",value:(_vm.editHistoryComments.comment),expression:"editHistoryComments.comment"}],staticClass:"comment-section",attrs:{"name":"editHistoryComments"},domProps:{"value":(_vm.editHistoryComments.comment)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.editHistoryComments, "comment", $event.target.value)}}}),(_vm.isInvalidComment)?_c('span',{staticClass:"is-invalid"},[_vm._v("This field is required")]):_vm._e()])],2)]),_c('div',{staticClass:"button-container"},[_c('button',{staticClass:"btn dismiss-btn",on:{"click":function($event){return _vm.revertChanges()}}},[_vm._v(" Dismiss ")]),_c('button',{staticClass:"btn",on:{"click":function($event){return _vm.confirmChanges()}}},[_vm._v(" Confirm Changes ")])])])]:_vm._e()],2):_vm._e(),(_vm.isSidebarVisible)?_c('div',{staticClass:"backdrop",on:{"click":_vm.closeSidebar}}):_vm._e()],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }