var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{attrs:{"id":"training-preview-form-container","tabindex":"0"}},[(!_vm.isLoading)?_c('div',{staticClass:"form-content"},[_vm._l((_vm.formData),function(formContent,index){return _c('div',{key:index,staticClass:"preview-form container-form"},[(formContent && index <= _vm.limitationList)?_c('div',[(
            _vm.originalData[formContent.parent_slug] &&
            ((_vm.isReviewerPreviewForm &&
              formContent.fields.parentSlug === _vm.tabSelect) ||
              (_vm.isReviewerPreviewForm && _vm.isMobileView
                ? _vm.isMigrationForm
                  ? formContent.parent_slug !==
                    'certificationInactiveSignature'
                  : true
                : false) ||
              !_vm.isReviewerPreviewForm)
          )?_c('b-form',{staticClass:"preview-form-container",class:formContent.fields.class},[(!_vm.isReviewerPreviewForm)?_c('div',{staticClass:"form-edit"},[_c('div',{staticClass:"description"},[_c('h1',{staticClass:"form-title"},[_vm._v(_vm._s(formContent.fields.formHeader))])]),_c('div',{staticClass:"description"},[_c('h1',{staticClass:"form-title edit",on:{"click":function($event){return _vm.editForm(formContent.parent_slug)}}},[_vm._v(" Edit "),_c('b-icon',{staticClass:"icon-pen",attrs:{"icon":"pencil-fill"}})],1)])]):_vm._e(),(_vm.isReviewerPreviewForm && _vm.isMobileView)?_c('div',{staticClass:"form-edit"},[_c('div',{staticClass:"description"},[_c('h1',{staticClass:"form-title"},[_vm._v(_vm._s(formContent.fields.formHeader))])])]):_vm._e(),(
              _vm.isReviewerPreviewForm &&
              formContent.parent_slug ===
                'certificationInActiveBasicDetails' &&
              (_vm.progressStatus === 'RECEIVED' ||
                _vm.progressStatus === 'PROCESSING' ||
                _vm.progressStatus === 'NEED MORE INFO' ||
                _vm.progressStatus === 'WAITING FOR TRAINING' ||
                _vm.progressStatus === 'COMPLETED TRAINING')
            )?_c('div',{staticClass:"reviewer-form-edit"},[(_vm.isEditReviewerForm && _vm.tabSelect !== 'signature')?_c('b-button',{staticClass:"next-btn btn",attrs:{"variant":"outline-primary","type":"button"},on:{"click":_vm.onCancelEdit}},[_c('b-icon',{staticClass:"check-icon",attrs:{"icon":"x-lg"}}),_vm._v(" Cancel ")],1):_vm._e(),(!_vm.isEditReviewerForm && _vm.tabSelect !== 'signature')?_c('b-button',{staticClass:"next-btn btn",attrs:{"variant":"outline-primary","type":"button"},on:{"click":function($event){return _vm.onCilckEdit(index)}}},[_c('b-icon',{staticClass:"check-icon",attrs:{"icon":"pencil-fill"}}),_vm._v(" Edit ")],1):_vm._e(),(_vm.isEditReviewerForm && _vm.tabSelect !== 'signature')?_c('b-button',{staticClass:"next-btn btn",attrs:{"variant":"outline-primary","type":"button"},on:{"click":_vm.onCilckSave}},[_c('b-icon',{staticClass:"check-icon",attrs:{"icon":"file-earmark-medical"}}),_vm._v(" Save ")],1):_vm._e()],1):_vm._e(),_vm._l((formContent.fields.formInputs),function(formInputData,indexi){return _c('div',{key:indexi,class:['fields-main-container', formInputData[0].mainClass]},[_c('div',{staticClass:"small-content form-div",class:[
                formInputData.length == 2
                  ? 'double-input'
                  : formInputData.length > 2
                  ? 'multi-input'
                  : '',
              ]},_vm._l((formInputData),function(formInput,indexj){return _c('div',{key:indexj,staticClass:"form-label",class:[
                  _vm.isMigrationForm &&
                  !_vm.originalData[formContent.parent_slug][formInput.name]
                    ? 'display-none'
                    : '',
                  _vm.fieldUpdate(formInput.name) === true ? 'display-none' : '',
                  formInput.fieldClass,
                  formInput.field === 'b-form-textarea'
                    ? 'textarea-field'
                    : '',
                  _vm.classNameChooser(formInput?.class),
                ]},[(
                    _vm.isMigrationForm
                      ? _vm.originalData[formContent.parent_slug][formInput.name]
                      : true
                  )?[(!formInput.label)?_c('div',{class:'label-container'},[_c('span',{staticClass:"visiblity-hidden label"},[_vm._v(" "+_vm._s(formInputData[0].label)+" ")])]):_vm._e(),(formInput.label)?_c('div',{class:formInput.label !== null
                        ? 'label-container'
                        : 'display-label'},[(formInput.label)?_c('span',{staticClass:"label",domProps:{"innerHTML":_vm._s(formInput.label + `&nbsp;&nbsp;`)}}):_vm._e(),(
                        formInput.label &&
                        formInput?.validate?.required === true
                      )?_c('span',{staticClass:"text-danger"},[_vm._v("*")]):_vm._e(),(formInput.labelDescription)?_c('label',{staticClass:"label-span"},[_vm._v(_vm._s(formInput.labelDescription))]):_vm._e()]):_vm._e(),_c(formInput.field,_vm._b({directives:[{name:"validate",rawName:"v-validate",value:(formInput?.validate?.veeValidate),expression:"formInput?.validate?.veeValidate"}],key:_vm.isEditReviewerForm,tag:"component",class:[formInput.class],attrs:{"editForm":_vm.isEditReviewerForm === true &&
                      _vm.tabSelect !== 'signature' &&
                      formContent.fields.parentSlug !== 'signature'
                        ? true
                        : false,"formType":'preview',"payloadData":_vm.previewPayload[formContent.parent_slug],"payloadDataAll":_vm.previewPayload,"previewPayload":_vm.previewPayload,"isReviewerPreviewForm":_vm.isReviewerPreviewForm,"isEditEnable":_vm.isEditReviewerForm,"autocomplete":"off","formInput":formInput.propBind,"isEditFile":_vm.isEditReviewerForm,"isPreviewForm":true,"keyName":formInput.name,"signature":_vm.previewPayload['certificationInactiveSignature'],"signatureLabelAndValidate":{
                      label: formInput?.signatureLabel,
                      validate: formInput?.signatureValidate,
                    },"signatureType":_vm.currentRoute.split('/')[1] === 'form'
                        ? {
                            payloadName: 'inactiveFormPayload',
                            payloadSignatureKey:
                              'certificationInactiveSignature',
                          }
                        : {}},nativeOn:{"keypress":function($event){_vm.eventBind[formInput?.propBind?.eventName]
                        ? _vm.fieldValidation(
                            $event,
                            formInput?.propBind?.eventName,
                            formInput?.propBind.type,
                            formInput?.validate?.maxLength,
                            formInput?.name
                          )
                        : _vm.emptyEvent}},model:{value:(
                      _vm.previewPayload[formContent.parent_slug][formInput.name]
                    ),callback:function ($$v) {_vm.$set(_vm.previewPayload[formContent.parent_slug], formInput.name, $$v)},expression:"\n                      previewPayload[formContent.parent_slug][formInput.name]\n                    "}},'component',{
                      ...formInput.propBind,
                      ...formInput.validate,
                      disabled:
                        _vm.isEditReviewerForm === true &&
                        _vm.tabSelect !== 'signature' &&
                        formContent.fields.parentSlug !== 'signature'
                          ? false
                          : true,
                    },false)),(_vm.isEditReviewerForm)?_c('label',{class:formInput.subLabel !== null || formInput.subLabel !== ''
                        ? 'display-none'
                        : 'label-span'},[_vm._v(_vm._s(formInput.subLabel))]):_vm._e(),(
                      formInput.field === 'b-form-textarea' &&
                      formInput.propBind.maxlength &&
                      _vm.isEditReviewerForm &&
                      _vm.isReviewerPreviewForm
                    )?_c('div',{staticClass:"word-count-div"},[_c('p',{staticClass:"word-count"},[_vm._v(" "+_vm._s(_vm.previewPayload[formContent.parent_slug][ formInput.name ].length)+"/"+_vm._s(formInput.propBind.maxlength)+" ")])]):_vm._e(),(
                      _vm.checkValidation &&
                      formInput?.validate?.required == true &&
                      _vm.$validator.errors.has(formInput?.validate?.name)
                    )?_c('p',{staticClass:"is-invalid"},[_vm._v(" "+_vm._s(formInput.propBind.type === "email" || formInput.name === "zip" ? _vm.$validator.errors.first(formInput?.validate?.name) : formInput.propBind.type === "date" ? "Please enter valid date" : formInput.name === "allVersionNames" && _vm.previewPayload[formContent.parent_slug][ formInput.name ].length ? "This field must be 4 or less" : "This field is required")+" ")]):_vm._e()]:_vm._e()],2)}),0)])})],2):_vm._e()],1):_vm._e()])}),(_vm.editApplication)?_c('EditHistoryComponent',{attrs:{"previewPayload":_vm.previewPayload,"formData":_vm.formData,"tabSelect":_vm.tabSelect,"isClickedSave":_vm.isClickSavebtn,"originalData":_vm.originalData},on:{"editedData":function($event){return _vm.editedPreviewPayloadData($event)},"isConfirmChanges":function($event){return _vm.isConfirmChanges($event)},"isRevertedChanges":function($event){return _vm.isRevertedChanges($event)}}}):_vm._e()],2):_vm._e(),(_vm.errorMessage)?_c('ErrorPopupComponent',{attrs:{"componentName":'-error-popup',"errorMessage":_vm.errorMessage},on:{"closdedErrorPopup":function($event){return _vm.closdedErrorPopup($event)}}}):_vm._e(),(_vm.isLoading)?_c('span',{staticClass:"loading-container"},[_c('p',[_vm._v("Loading...")])]):_vm._e(),(_vm.isLargeLoading)?_c('AppLoaderComponent',{attrs:{"type":'large'}}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }