export const ADDITIONAL_RENEWAL_SUPERVISOR_FORM_QUERY = `AdditionalRenewalSupervisorForms {  
    additionalRenewalSupervisorForms(sort: "id:ASC"){
        data{
            id
            attributes{
                program_title
                application_name
                form_fields
            }
        }
    }
}`;
