export const PEER_FLAG_APPLICANT_QUERY = `PeerFlagApplicant {  
    peerFlagApplicant{
        data {
            id
            attributes {
                form_fields
            }
        }
    }
}`;
