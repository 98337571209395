import AdditionalReferenceFormComponent from "@/components/additional-reference-form/additional-reference-form.component.vue";
import AdditionalRenewalSupervisorFormComponent from "@/components/additional-renewal-supervisor-form/additional-renewal-supervisor-form.component.vue";
import AdditionalSupervisorFormComponent from "@/components/additional-supervisor-form/additional-supervisor-form.component.vue";
import ApplicationSubmittedComponent from "@/components/application-submitted/application-submitted.component.vue";
import EmploymentSummaryForm from "@/components/employment-summary-form/employment-summary-form.component.vue";
import InactiveApplication from "@/components/inactive-application/inactive-application.component.vue";
import CertificationApplicationForm from "@/components/initial-application-form /initial-application-form.component.vue";
import LetterOfReferenceForm from "@/components/letter-of-reference-form/letter-of-reference-form.component.vue";
import LoginPageComponent from "@/components/login-page/login-page.component.vue";
import ForgetPageComponenemailverifyt from "@/components/forget-password-emailverify/forget-password-emailverify-component.vue";
import ForgetPageComponent from "@/components/forget-password/forget-password.component.vue";
import RenewalApplicationFormComponent from "@/components/renewal-application-form/renewal-application-form.component.vue";
import RenewalEmploymentSummaryForm from "@/components/renewal-employment-summary-form/renewal-employment-summary-form.component.vue";
import ReviewerAgencyLists from "@/components/reviewer-agency-lists/reviewer-agency-lists.component.vue";
import ReviewerCertificationApplication from "@/components/reviewer-certification-lists/reviewer-certification-lists.component.vue";
import ReviewerCertificationPreview from "@/components/reviewer-certification-preview/reviewer-certification-preview.component.vue";
import ReviewerHomePage from "@/components/reviewer-home-page/reviewer-home-page.component.vue";
import ReviewerInactiveLists from "@/components/reviewer-inactive-lists/reviewer-inactive-list.component.vue";
import ReviewerInactivePreview from "@/components/reviewer-inactive-preview/reviewer-inactive-preview.component.vue";
import ReviewerPeerLists from "@/components/reviewer-peers-lists/reviewer-peers-lists.component.vue";
import ReviewerRenewalLists from "@/components/reviewer-renewal-lists/reviewer-renewal-lists.component.vue";
import ReviewerRenewalPreview from "@/components/reviewer-renewal-preview/reviewer-renewal-preview.component.vue";
import UserEducationComponent from "@/components/user-continuing-education/user-continuing-education.component.vue";
import UserConversationsComponent from "@/components/user-conversations/user-conversations.component.vue";
import UserDasboardComponent from "@/components/user-dasboard/user-dasboard.component.vue";
import UserDocumentsLibraryComponent from "@/components/user-document-library/user-documents-library.component.vue";
import UserHomePageComponent from "@/components/user-home-page/user-home-page.component.vue";
import UserApplicationsComponent from "@/components/users-application/user-applications.component.vue";
import BaseLayout from "@/layouts/base-layout/base-layout.vue";
import HomePage from "@/layouts/home-page/home-page.vue";
import Vue from "vue";
import VueRouter from "vue-router";
import ReviewerPeersPreview from "@/components/reviewer-peers-preview/reviewer-peers-preview.component.vue";
import ReviewerPeersPreviewApplication from "@/components/reviewer-peers-preview-application/reviewer-peers-preview-application.component.vue";
import EmailVerifyComponent from "@/components/email-verify-popup/email-verify.component.vue";
import ForgetPasswordVerifyPopup from "@/components/forgetpassword-verify-popup/forget-password-popup-component.vue";
import ReviewerReportsPage from "@/components/reviewer-reports-page/reviewer-reports-page.component.vue";
Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    redirect: "home",
    component: BaseLayout,
    children: [
      {
        path: "/home",
        name: "home-page",
        component: HomePage,
      },
      {
        path: "/form/inactive-form",
        name: "inactive-application",
        component: InactiveApplication,
      },
      {
        path: "/form/initial-form",
        name: "certification-form",
        component: CertificationApplicationForm,
      },
      {
        path: "/form/renewal-form",
        name: "renewal-form",
        component: RenewalApplicationFormComponent,
      },
      {
        path: "/form/renewal-employment-summaries",
        name: "renewal-employment-summary-form",
        component: RenewalEmploymentSummaryForm,
      },
      {
        path: "/form/employment-summaries",
        name: "employment-summary-form",
        component: EmploymentSummaryForm,
      },
      {
        path: "/form/letter-of-reference",
        name: "letter-of-reference",
        component: LetterOfReferenceForm,
      },
      {
        path: "/form/add-additional-reference",
        name: "additional-reference",
        component: AdditionalReferenceFormComponent,
      },
      {
        path: "/form/add-additional-supervisor",
        name: "additional-supervisor",
        component: AdditionalSupervisorFormComponent,
      },
      {
        path: "form/renewal-add-additional-supervisor",
        name: "additional-renewal-supervisor",
        component: AdditionalRenewalSupervisorFormComponent,
      },
      {
        path: "/form/submitted",
        name: "application-submitted",
        component: ApplicationSubmittedComponent,
      },
    ],
  },
  {
    path: "/login",
    name: "login-page",
    component: LoginPageComponent,
  },
  {
    path: "/forgot-password",
    name: "forget-password",
    component: ForgetPageComponent,
  },
  {
    path: "/form/forgot/email-verify",
    name: "forget-password",
    component: ForgetPageComponenemailverifyt,
  },
  {
    path: "/user-dashboard",
    redirect: "/user-dashboard/home",
    component: UserDasboardComponent,
    children: [
      {
        path: "home",
        name: "user-home-page",
        component: UserHomePageComponent,
      },
      {
        path: "/user-dashboard/my-application",
        name: "user-application",
        component: UserApplicationsComponent,
      },
      {
        path: "/user-dashboard/education",
        name: "user-continuing-education",
        component: UserEducationComponent,
      },
      {
        path: "/user-dashboard/document-library",
        name: "user-document-library",
        component: UserDocumentsLibraryComponent,
      },
      {
        path: "/user-dashboard/conversations",
        name: "user-conversations",
        component: UserConversationsComponent,
      },
    ],
  },
  {
    path: "/reviewer-dashboard",
    redirect: "/reviewer-dashboard/home",
    component: UserDasboardComponent,
    children: [
      {
        path: "home",
        name: "admin-home-page",
        component: ReviewerHomePage,
      },
      {
        path: "certification",
        name: "reviewer-certification-applications",
        component: ReviewerCertificationApplication,
      },
      {
        path: "certification/:applicantId",
        name: "reviewer-certification-preview",
        component: ReviewerCertificationPreview,
      },
      {
        path: "renewal",
        name: "reviewer-renewal-lists",
        component: ReviewerRenewalLists,
      },
      {
        path: "renewal/:applicantId",
        name: "reviewer-renewal-preview",
        component: ReviewerRenewalPreview,
      },
      {
        path: "inactive",
        name: "reviewer-inactive-lists",
        component: ReviewerInactiveLists,
      },
      {
        path: "inactive/:applicantId",
        name: "reviewer-inactive-preview",
        component: ReviewerInactivePreview,
      },
      {
        path: "peers",
        name: "reviewer-peer-lists",
        component: ReviewerPeerLists,
      },
      {
        path: "agency",
        name: "reviewer-agency-lists",
        component: ReviewerAgencyLists,
      },
      {
        path: "peers/:peerId",
        name: "reviewer-peers-preview",
        component: ReviewerPeersPreview,
      },
      {
        path: "peers-preview/:peerId",
        name: "reviewer-peers-preview-application",
        component: ReviewerPeersPreviewApplication,
      },
      {
        path: "reports",
        name: "reviewer-reportd-page",
        component: ReviewerReportsPage,
      },
    ],
  },
  {
    path: "/form/email-verify",
    name: "email-verify",
    component: EmailVerifyComponent,
  },
  {
    path: "/forgot/password-verify",
    name: "forget-possword",
    component: ForgetPasswordVerifyPopup,
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
  scrollBehavior() {
    return { x: 0, y: 0 };
  },
});

export default router;
