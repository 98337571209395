import { Component, Vue } from "vue-property-decorator";
import { gql } from "graphql-tag";
import RadioButtonComponent from "../radio-button/radio-button.component.vue";
import SignatureSubmissionComponent from "../signature-submission/signature-submission.component.vue";
import AppLoaderComponent from "../app-loader/app-loader.component.vue";
import {
  EmploymentSummariesForm,
  FormFieldContents,
} from "@/models/employment-summary-form/employment-summary-form.interface";
import { employmentVolunteerFormApi } from "@/providers/apis/employment-volunteer-form";
import { utils } from "@/utils/utils";
import { RENEWAL_EMPLOYMENT_SUMMARY_FORM_QUERY } from "@/graphql/renewal-employment-summary-form-query";
import { FormNavbar } from "@/models/renewal-form/renewal-form.interface";
import moment from "moment";
import ErrorPopupComponent from "../error-popup/error-popup.component.vue";
import FormInputDatepickerComponent from "../form-input-datepicker/form-input-datepicker.component.vue";

@Component({
  name: "renewal-employment-summary-form",
  components: {
    "b-form-radio-group": RadioButtonComponent,
    "b-form-signature-submission": SignatureSubmissionComponent,
    "b-form-input-datepicker": FormInputDatepickerComponent,
    AppLoaderComponent,
    ErrorPopupComponent,
  },
})
export default class RenewalEmploymentSummaryForm extends Vue {
  public formPagination = 0;
  public formNames = ["employmentApplicantInformation"];
  public applicationData: Array<EmploymentSummariesForm> = [];
  public formData: FormFieldContents[] = [];
  public applicationNavData: FormNavbar[] = [];
  public isScroll = false;
  public isValid = false;
  public signatureInvalid = false;
  public saveDataDraft = false;
  public volunteerOfInformation: any;
  public supervisorOfInformation: any;
  public payLoadData: any = {
    fillStatus: "DRAFT",
    id: "",
    employmentApplicantInformation: {
      firstName: "",
      lastName: "",
      jobTitle: "",
      hours: "",
      certificateExpirationDate: "",
      certificationNumber: "",
      applicantPhone: "",
      applicantEmail: "",
      employedVolunteering: "",
    },
    supervisorContactInformation: {
      firstName: "",
      lastName: "",
      employmentDirectServiceRole: "",
      employmentPosition: "",
      DateOfEmployment: "",
      employmenthoursPerWeek: "",
      employmentCredentials: "",
      organization: "",
      position: "",
      phone: "",
      email: "",
      addressLine1: "",
      addressLine2: "",
      state: "",
      city: "",
      zip: "",
      workResponsibilities: "",
      natureOfYourDirect: "",
      professionalDevelopment: "",
    },
    volunteerSummaryInformation: {
      firstName: "",
      lastName: "",
      volunteerDirectServiceRole: "",
      volunteerPosition: "",
      startDate: "",
      hoursPerWeek: "",
      volunteerCredentials: "",
      organization: "",
      position: "",
      phone: "",
      email: "",
      addressLine1: "",
      addressLine2: "",
      state: "",
      city: "",
      zip: "",
      volunteerResponsibilities: "",
      volunteerInteractions: "",
      volunteerGoalsAgency: "",
    },
    signature: {
      isSignature: "",
      submissionDate: moment().format("MM-DD-YYYY"),
    },
  };
  public formTitle = {
    main_title: "",
    application_name: "",
  };
  public mainId = "app";
  public hideDiv: string[] = ["supervisorEmployment"];
  public checkField: string[] = ["volunteerOrApprovedAgency"];
  public isLoading = false;
  public token: any;
  public applicationDetials: any;
  public applicationDataDetials: any;
  public supervisorData: any;
  public eventBind: any = {
    acceptNumberOnly: this.numberValidation,
    avoidNumbersAndSpecialChar: this.avoidNumbersAndSpecialCharacter,
    telCharacter: this.telCharacterValidate,
    onlyAlphabets: this.onlyAlphabets,
  };
  public checkValidForm = false;
  public errorMessage = "";

  public async created() {
    this.token = this.$route.query?.token;
    let payloadStorage: any = localStorage.getItem(
      "renewalEmploymentPayLoadData"
    );
    if (payloadStorage) {
      payloadStorage = JSON.parse(payloadStorage);
      this.payLoadData = { ...payloadStorage };
    }
    this.$apollo
      .query({
        query: gql`query ${RENEWAL_EMPLOYMENT_SUMMARY_FORM_QUERY}`,
      })
      .then((result) => {
        this.applicationData =
          result.data.employmentVolunteerSummaryForRenewals.data;
        this.formTitle = {
          main_title: this.applicationData[0].attributes.main_title,
          application_name: this.applicationData[0].attributes.application_name,
        };
        this.formData = this.applicationData[0].attributes.form_fields;
      });
    if (this.token) {
      this.isLoading = true;
      employmentVolunteerFormApi
        .getEmploymentVolunteerForm(this.token, true)
        .then((result: any) => {
          this.isLoading = false;
          this.applicationDetials = result.data;
          this.applicationDataDetials =
            this.applicationDetials.data.renewalApplicationInformation;
          const decodeToken = JSON.parse(atob(this.token.split(".")[1]));
          const emailData = decodeToken.email;
          if (this.applicationDetials) {
            this.payLoadData.employmentApplicantInformation.firstName =
              this.applicationDataDetials.firstName;
            this.payLoadData.employmentApplicantInformation.lastName =
              this.applicationDataDetials.lastName;
            this.payLoadData.employmentApplicantInformation.applicantPositionTitle =
              this.applicationDataDetials.position;
            this.payLoadData.employmentApplicantInformation.certificateExpirationDate =
              this.applicationDataDetials.certificateExpirationDate;
            this.payLoadData.employmentApplicantInformation.certificationNumber =
              this.applicationDataDetials.certificationNumber;
            this.payLoadData.employmentApplicantInformation.applicantPhone =
              this.applicationDataDetials.phone;
            this.payLoadData.employmentApplicantInformation.applicantEmail =
              this.applicationDataDetials.email;

            // Supervisour Data
            this.supervisorOfInformation =
              result.data.data.renewalSupervisorInformation;
            this.supervisorOfInformation.map((data: any) => {
              this.supervisorData = data;
              if (data.supervisorEmail == emailData) {
                this.payLoadData.supervisorContactInformation.firstName =
                  this.supervisorData.supervisorFirstName;
                this.payLoadData.supervisorContactInformation.lastName =
                  this.supervisorData.supervisorLastName;
                this.payLoadData.supervisorContactInformation.organization =
                  this.supervisorData.peerSupportAgencyOrganization;
                this.payLoadData.supervisorContactInformation.position =
                  this.supervisorData.peerSupportYourPosition;
                this.payLoadData.supervisorContactInformation.employmentCredentials =
                  this.supervisorData.supervisorCredentials;
                this.payLoadData.supervisorContactInformation.phone =
                  this.supervisorData.phone;
                this.payLoadData.supervisorContactInformation.email =
                  this.supervisorData.supervisorEmail;
                localStorage.setItem(
                  "renewalEmploymentPayLoadData",
                  JSON.stringify(this.payLoadData)
                );
              }
            });

            // Volunteer Data
            this.volunteerOfInformation =
              result.data.data.certificationPartTwoSupervisorInformation;
            this.supervisorOfInformation.map((data: any) => {
              this.supervisorData = data;
              if (data.supervisorEmail == emailData) {
                this.payLoadData.volunteerSummaryInformation.firstName =
                  this.supervisorData.supervisorFirstName;
                this.payLoadData.volunteerSummaryInformation.lastName =
                  this.supervisorData.supervisorLastName;
                this.payLoadData.volunteerSummaryInformation.startDate =
                  this.supervisorData.startDate;
                this.payLoadData.volunteerSummaryInformation.volunteerCredentials =
                  this.supervisorData.supervisorCredentials;
                this.payLoadData.volunteerSummaryInformation.agencyOrOrganization =
                  this.supervisorData.peerSupportAgencyOrganization;
                this.payLoadData.volunteerSummaryInformation.phone =
                  this.supervisorData.phone;
                this.payLoadData.volunteerSummaryInformation.email =
                  this.supervisorData.supervisorEmail;
                localStorage.setItem(
                  "renewalEmploymentPayLoadData",
                  JSON.stringify(this.payLoadData)
                );
              }
            });
          }
        })
        .catch((error) => {
          this.errorMessage = utils.errorMessage(error);
          this.isLoading = false;
        });
    }
  }

  public saveDraft() {
    this.$bvToast.show("example-toast");
    localStorage.setItem(
      "renewalEmploymentPayLoadData",
      JSON.stringify(this.payLoadData)
    );
    this.saveDataDraft = true;
    setTimeout(() => {
      this.saveDataDraft = false;
    }, 1000);
  }

  public onSubmit() {
    this.checkValidForm = true;
    this.$validator.validate().then((validate) => {
      if (validate) {
        if (this.payLoadData.signature.isSignature) {
          this.signatureInvalid = false;
        } else {
          this.signatureInvalid = true;
          setTimeout(() => {
            const el = document.getElementsByClassName("is-invalid")[0];
            if (el) {
              el.scrollIntoView({ behavior: "smooth", block: "end" });
            }
          }, 10);
          return;
        }
        localStorage.setItem(
          "renewalEmploymentPayLoadData",
          JSON.stringify(this.payLoadData)
        );
        this.isLoading = true;
        const el = document.getElementById(this.mainId);
        if (el) {
          el.scrollIntoView({ behavior: "smooth" });
        }
        let payloadStorage: any = localStorage.getItem(
          "renewalEmploymentPayLoadData"
        );
        if (payloadStorage) {
          payloadStorage = JSON.parse(payloadStorage);
          this.payLoadData = { ...payloadStorage };
        }
        employmentVolunteerFormApi
          .postEmploymentApplicationForm(this.token, this.payLoadData, true)
          .then(() => {
            this.checkValidForm = false;
            const someData = {
              title: "Certified Family Support Specialist Program",
              SubTitle: "Employment / Volunteer Form",
            };
            this.$router.push({
              path: "/form/submitted",
              query: {
                subTitle: someData.SubTitle,
              },
            });
            localStorage.removeItem("renewalEmploymentPayLoadData");
            this.isLoading = false;
          })
          .catch((error) => {
            this.errorMessage = utils.errorMessage(error);
            this.isLoading = false;
          });
      } else {
        const el = document.getElementsByClassName("is-invalid")[0];
        if (el) {
          el.scrollIntoView({ behavior: "smooth", block: "end" });
        }
      }
    });
  }

  public closeToast() {
    this.$bvToast.hide("example-toast");
  }

  public closePopup() {
    this.$bvModal.hide("error-modal-center");
  }
  public numberValidation(evt: any, length?: number) {
    utils.onlyNumberKey(evt, length);
  }

  public avoidNumbersAndSpecialCharacter(evt: any) {
    utils.avoidNumbersAndSpecialCharacters(evt);
  }

  public telCharacterValidate(evt: any, length?: number) {
    utils.telCharacterValidate(evt, length);
  }

  public onlyAlphabets(evt: any, fieldName = "") {
    utils.onlyAlphabets(evt, fieldName);
  }

  public fieldValidation(
    value: any,
    eventName: string,
    fieldType: string,
    maxLength = 0,
    fieldName = ""
  ) {
    if (fieldType === "number") {
      this.eventBind[eventName](value, maxLength);
    } else if (fieldName === "state") {
      this.eventBind[eventName](value, fieldName);
    } else {
      this.eventBind[eventName](value);
    }
  }

  public emptyEvent() {
    return;
  }

  public fieldUpdateReference(inputName: string) {
    const nameNotVisiable = ["fullName", "fullAddress"];
    return nameNotVisiable.includes(inputName);
  }

  public fieldUpdate(groupName = "", initial = false) {
    switch (groupName) {
      case "employedVolunteering":
        if (
          this.payLoadData.employmentApplicantInformation
            .employedVolunteering === "employed with your organization"
        ) {
          this.formNames = [
            "employmentApplicantInformation",
            "supervisorContactInformation",
            "signature",
          ];
        } else {
          this.formNames = [
            "employmentApplicantInformation",
            "volunteerSummaryInformation",
            "signature",
          ];
        }
        break;
    }
  }

  public classNameChooser(inputClass: string) {
    return utils.classNameChooser(inputClass);
  }

  public closdedErrorPopup(event: any) {
    if (event) {
      this.errorMessage = "";
    }
  }
}
