import { MORE_INFORMATION_QUERY } from "@/graphql/more-information";
import gql from "graphql-tag";
import Vue from "vue";
import Component from "vue-class-component";
import { InformationlData } from "./more-information.interface";

@Component({})
export default class MoreInfromation extends Vue {
  public informationlData: InformationlData[] = [];
  public currentRoute = "";

  public async created() {
    this.currentRoute = this.$route.path.split("/")[1];
    await this.$apollo
      .query({
        query: gql`query ${MORE_INFORMATION_QUERY}`,
      })
      .then((result) => {
        this.informationlData = result.data.forMoreInformations.data;
      });
  }
}
