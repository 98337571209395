import { render, staticRenderFns } from "./edit-history.component.vue?vue&type=template&id=f4e1e7c4&"
import script from "./edit-history.component.ts?vue&type=script&lang=ts&"
export * from "./edit-history.component.ts?vue&type=script&lang=ts&"
import style0 from "./edit-history.component.scss?vue&type=style&index=0&prod&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports