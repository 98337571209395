import { Component, Prop, Vue } from "vue-property-decorator";

@Component({
  name: "b-form-checkbox-input",
})
export default class InputOtherComponent extends Vue {
  @Prop()
  public value!: string;
  @Prop()
  public payloadData: any;
  @Prop()
  public payloadDataAll: any;
  @Prop()
  public previewPayload: any;

  public text!: string;
  public previewPayLoadData: any;

  public get isShowInput() {
    if (this.payloadData) {
      const { highSchool = [] } = this.payloadData;
      return highSchool.indexOf("Other") !== -1;
    }
  }

  public created() {
    this.text = this.value;
  }
  public updated() {
    if (!this.isShowInput) {
      this.text = "";
    }
    this.$emit("input", this.text);
  }
  onChangeInput() {
    this.$emit("input", this.text);
  }
}
