import { render, staticRenderFns } from "./main-banner.vue?vue&type=template&id=f3be62a2&"
import script from "./main-banner.ts?vue&type=script&lang=ts&"
export * from "./main-banner.ts?vue&type=script&lang=ts&"
import style0 from "./main-banner.scss?vue&type=style&index=0&prod&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports