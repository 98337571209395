import { http } from "@/providers/http";
export class ApplicantDetialsApi {
  private static INSTANCE = new ApplicantDetialsApi();

  static get instance() {
    return this.INSTANCE;
  }

  // GET APPLICANT CERTIFICATE LIST FROM ALL PROGRAM
  public getApplicantCertificate() {
    const endpoint = `cfss/user/certificates`;
    const headers = http.getTokenHeaders();
    return http.get(endpoint, headers).then((response) => {
      return response;
    });
  }

  //GET USER APPLICATIONS
  public getUserApplication() {
    const endpoint = `cfss/user/applications`;
    const headers = http.getTokenHeaders();
    return http.get(endpoint, headers).then((response) => {
      return response;
    });
  }

  //GET USER CONVERSATIONS
  public getUserConversations() {
    const endpoint = `cfss/user/mail/conversations`;
    const headers = http.getTokenHeaders();
    return http.get(endpoint, headers).then((response) => {
      return response;
    });
  }

  // POST Continuing Education
  public createContinuingEducation(educationData: FormData) {
    const endpoint = "cfss/user/educations";
    const headers = {
      ...http.getTokenHeaders(),
      "Content-Type": "multipart/form-data",
    };
    return http
      .post(endpoint, headers, educationData, true)
      .then((response) => {
        return response;
      });
  }

  // GET CONTINUING EDUCATION
  public getContinuingEducation(userId = "") {
    let endpoint = "cfss/user/educations";
    if (userId) {
      endpoint += `?userId=${userId}`;
    }
    const headers = http.getTokenHeaders();
    return http.get(endpoint, headers).then((response) => {
      return response;
    });
  }

  // GET CONTINUING EDUCATION
  public getDocumentsLibrary(filters: any) {
    const filterKeys: string[] = [];
    if (filters["limit"]) {
      const filterKey = `limit=${filters["limit"]}`;
      filterKeys.push(filterKey);
    }
    if (filters["offset"] >= 0) {
      const filterKey = `offset=${filters["offset"]}`;
      filterKeys.push(filterKey);
    }
    const filterStr = filterKeys.join("&");
    const endPoint = `cfss/user/documents?${filterStr}`;
    const headers = http.getTokenHeaders();
    return http.get(endPoint, headers).then((response) => {
      return response.data;
    });
  }
  //UPDATE PROFILE
  public getUpdateProfile(data: any) {
    const endpoint = "user/cfss";
    const headers = http.getTokenHeaders();
    return http.put(endpoint, headers, data).then((response) => {
      return response;
    });
  }
  //RESEND PROFILE
  public resendProfile(data: any) {
    const endpoint = "user/cfss/email/resend";
    const headers = http.getTokenHeaders();
    return http.put(endpoint, headers, data).then((response) => {
      return response;
    });
  }
  public archivedEducation(data: any) {
    const endpoint = "cfss/user/education/archive";
    const headers = http.getTokenHeaders();
    return http.put(endpoint, headers, data).then((response) => {
      return response;
    });
  }
}

export const applicantDetialsApi = ApplicantDetialsApi.instance;
