import { Component, Vue } from "vue-property-decorator";
import { gql } from "graphql-tag";
import {
  CfssHandbookData,
  fields,
} from "@/models/cfss-main-content/cfss-main-content.interface";
import { HANDBOOK_QUERY } from "@/graphql/cfss-main-content-query";
import PreviewPopupComponent from "../preview-popup/preview-popup.component.vue";
import { utils } from "@/utils/utils";
import { docLibraryData } from "@/models/common-interface/common.interface";
@Component({
  components: {
    PreviewPopupComponent,
  },
})
export default class CfssHandbookComponent extends Vue {
  public handBookContentData: CfssHandbookData[] = [];
  public handBookData: any = {};
  public cardData: any = "";
  public showModal = false;
  public showPreviewPopup = false;
  public S3GetPath = "";
  public imgType = ["jpg", "png", "jpeg", "tiff", "pdf"];
  public previewData: any[] = [];
  public previewFile: any[] = [];
  public getAllDoc: docLibraryData[] = [];
  public async created() {
    await utils.getAllDoc().then((data) => {
      this.getAllDoc = data;
    });
    await this.$apollo
      .query({
        query: gql`query ${HANDBOOK_QUERY}`,
      })
      .then((result) => {
        this.handBookContentData = result.data.cfssHandbooks.data;
        this.handBookData = this.handBookContentData[0].attributes;
      });
  }

  public hidePreviewPopup(event: boolean) {
    if (event === true) {
      this.showPreviewPopup = false;
      this.previewFile = [];
    }
  }

  public hideCardModal(e: any) {
    this.showModal = false;
  }

  //MODAL OPEN FUNCTION
  public showPopUp(index: any) {
    const data = this.handBookContentData[0].attributes.card_component[index];
    if (data.slug === "download-handbook") {
      if (data.docKeyName) {
        const docDetials = utils.filterDoc(this.getAllDoc, data.docKeyName);
        this.previewData = [
          {
            fileName: "",
            width: 0,
            height: 0,
            type: docDetials.mime,
            originalFileName: docDetials.name,
            slug: data.slug,
            url: docDetials.url,
          },
        ];
      }
      this.showPreviewPopup = true;
    } else {
      this.cardData =
        this.handBookContentData[0].attributes.card_component[index];
      setTimeout(() => {
        this.$bvModal.show("modal-active-card");
      }, 30);
      this.showModal = true;
    }
  }
}
