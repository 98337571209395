import { http } from "@/providers/http";
import { utils } from "@/utils/utils";
export class InactiveFormApi {
  private static INSTANCE = new InactiveFormApi();

  static get instance() {
    return this.INSTANCE;
  }

  // POST for Inactive application
  public InactiveApplicationForm(
    fillStatus: string,
    inactivePayload: any,
    form = ""
  ) {
    const userDetails = utils.getUserDetails();
    let adminuserDetail: any = localStorage.getItem("admin_user_details");
    if (adminuserDetail) {
      adminuserDetail = JSON.parse(adminuserDetail);
    }
    const data = {
      fillStatus: inactivePayload.fillStatus,
      data: {
        certificationInActiveBasicDetails:
          inactivePayload.certificationInActiveBasicDetails,
        certificationInactiveSignature:
          inactivePayload.certificationInactiveSignature,
      },
      submittedBy:
        userDetails &&
        (!userDetails.roles || userDetails.roles === null) &&
        !localStorage.getItem("impersonateId")
          ? userDetails.userId || userDetails.user_id
          : adminuserDetail && adminuserDetail.userId
          ? adminuserDetail.userId
          : null,
    };
    const endpoint = "cfss/inactive";
    let headers = {};
    if (form === "inactive-form") {
      const anonymousToken = localStorage.getItem("anonymousToken");
      headers = {
        "Content-Type": "application/json",
        Authorization: `Token ${anonymousToken}`,
      };
    } else {
      headers = http.getTokenHeaders();
    }
    return http.post(endpoint, headers, data).then((response) => {
      return response;
    });
  }

  // PUT for Part One application

  public updateInactiveForm(payLoadData: any, id: string) {
    const userDetails = utils.getUserDetails();
    let adminuserDetail: any = localStorage.getItem("admin_user_details");
    if (adminuserDetail) {
      adminuserDetail = JSON.parse(adminuserDetail);
    }
    const endpoint = `cfss/inactive?id=${id}`;

    const data = {
      id: id,
      fillStatus: payLoadData.fillStatus,
      data: {
        certificationInActiveBasicDetails:
          payLoadData.certificationInActiveBasicDetails,
        certificationInactiveSignature:
          payLoadData.certificationInactiveSignature,
      },
      submittedBy:
        userDetails &&
        (!userDetails.roles || userDetails.roles === null) &&
        !localStorage.getItem("impersonateId")
          ? userDetails.userId || userDetails.user_id
          : adminuserDetail && adminuserDetail.userId
          ? adminuserDetail.userId
          : null,
    };
    const headers = http.getTokenHeaders();
    return http.put(endpoint, headers, data).then((response) => {
      return response;
    });
  }

  //GET BY ID for Inactive application
  public getInactiveApplicationForm(applicationID?: string, form = "") {
    const endpoint = `cfss/inactive`;
    const params = {
      id: applicationID,
    };
    let headers = {};
    if (form === "training-form") {
      const anonymousToken = localStorage.getItem("anonymousToken");
      headers = {
        "Content-Type": "application/json",
        Authorization: `Token ${anonymousToken}`,
      };
    } else {
      headers = http.getTokenHeaders();
    }
    return http.get(endpoint, headers, params).then((response) => {
      return response;
    });
  }

  public updateReviewerInactiveForm(payLoadData: any, id: string) {
    const endpoint = `cfss/inactive/application`;

    const payload = {
      id: id,
      fillStatus: payLoadData.fillStatus,
      data: {
        certificationInActiveBasicDetails:
          payLoadData.certificationInActiveBasicDetails,

        certificationInactiveSignature:
          payLoadData.certificationInactiveSignature,
        History: {},
        editedComments: {},
      },
    };
    if (payLoadData.History && payLoadData.editedComments) {
      payload.data.History = payLoadData.History;
      payload.data.editedComments = payLoadData.editedComments;
    }
    const headers = http.getTokenHeaders();
    return http.put(endpoint, headers, payload).then((response) => {
      return response;
    });
  }
  public updatePeerReviewerInactiveForm(payLoadData: any, id: string) {
    const endpoint = `cfss/inactive/applicant/edit`;

    const payload = {
      id: id,
      fillStatus: payLoadData.fillStatus,
      data: {
        certificationInActiveBasicDetails:
          payLoadData.certificationInActiveBasicDetails,

        certificationInactiveSignature:
          payLoadData.certificationInactiveSignature,
        History: {},
        editedComments: {},
      },
    };
    if (payLoadData.History && payLoadData.editedComments) {
      payload.data.History = payLoadData.History;
      payload.data.editedComments = payLoadData.editedComments;
    }
    const headers = http.getTokenHeaders();
    return http.put(endpoint, headers, payload).then((response) => {
      return response;
    });
  }
}

export const inactiveFormApi = InactiveFormApi.instance;
