export const INITIAL_APPLICATION_QUERY = `InitialApplications {  
    initialApplications(sort: "id:ASC"){
        data{
            id
            attributes{
                main_title
                application_name
                navbar{
                    id
                    name
                    slug    
                },
                form_fields{
                    id
                    parent_slug
                    fields
                    formBeforeText
                }
            }
        }
    }
}`;
