import { BASIC_DETAIL_SUB_QUESTIONS_QUERY } from "@/graphql/experience-information-query";
import { User } from "@/models/common-interface/common.interface";
import { ExperienceData } from "@/models/experience-information-interface/experience-information.interface";
import { utils } from "@/utils/utils";
import gql from "graphql-tag";
import { Component, Inject, Prop, Vue, Watch } from "vue-property-decorator";
import RadioButtonComponent from "../radio-button/radio-button.component.vue";

@Component({
  name: "experience-information",
  components: {
    "b-form-radio-group": RadioButtonComponent,
  },
})
export default class ExperienceInformationComponent extends Vue {
  @Prop()
  public experienceKey!: {
    payloadName: string;
    payloadExperienceKey: string;
  };
  @Prop()
  public isChangedData!: boolean;
  @Prop()
  public isEditFile!: boolean;
  @Prop()
  public isPreviewForm!: boolean;

  @Inject("$validator") public $validator: any;

  @Prop()
  public experienceInformationData!: any;

  @Prop()
  public editForm!: boolean;
  @Prop()
  public formType!: string;

  public experienceData: ExperienceData[] = [];
  public editedChanges: any[] = [];
  public revertFieldNames: string[] = [];
  public accordionStatus: any;
  public activeForm: any = null;
  public checkValidated: any = {};
  public isValidFlag = false;
  public isReviwer = false;
  public isDisable = false;
  public isMobileView = false;
  public filledForms: any = [];
  public originalData: any;
  public userDetails: User = {} as User;
  public labels: any = {
    experienceYear: "Experience Year",
    experienceMonth: "Experience Month",
    employmentType: "Employment Type",
    currentPosition: "Current Position",
    agency: "Agency",
    supervisorName: "Supervisor's Name",
    positionHeld: "Position Held",
    responsibilities: "Briefly Describe Your Work Responsibilities",
  };
  public formName: any = {
    familySupportSpecialist: "Family Support Specialist",
    supportGroupFacilitator: "Support Group Facilitator",
    caregiverEducator: "Caregiver Educator",
    otherRelevantExperience: "Other Relevant Experience",
  };
  public experiencePayload: any = {
    familySupportSpecialist: {
      experienceYear: "",
      experienceMonth: "",
      employmentType: "",
      currentPosition: "",
      agency: "",
      supervisorName: "",
      positionHeld: "",
      responsibilities: "",
    },
    supportGroupFacilitator: {
      experienceYear: "",
      experienceMonth: "",
      experience: "",
      employmentType: "",
      currentPosition: "",
      agency: "",
      supervisorName: "",
      positionHeld: "",
      responsibilities: "",
    },
    caregiverEducator: {
      experienceYear: "",
      experienceMonth: "",
      employmentType: "",
      currentPosition: "",
      agency: "",
      supervisorName: "",
      positionHeld: "",
      responsibilities: "",
    },
    otherRelevantExperience: {
      experienceYear: "",
      experienceMonth: "",
      employmentType: "",
      currentPosition: "",
      agency: "",
      supervisorName: "",
      positionHeld: "",
      responsibilities: "",
    },
  };
  public eventBind: any = {
    acceptNumberOnly: this.numberValidation,
    avoidNumbersAndSpecialChar: this.avoidNumbersAndSpecialCharacter,
    onlyAlphabets: this.onlyAlphabets,
  };
  public currentRoute = "";

  public async created() {
    this.isDisable = utils.disableField(this.editForm, this.formType);
    if (utils.checkIsMobileView() || utils.checkIsTabView()) {
      this.isMobileView = true;
    } else {
      this.isMobileView = false;
    }
    const userDetails = localStorage.getItem("user_details");
    if (userDetails) {
      this.userDetails = JSON.parse(userDetails);
    }
    this.currentRoute = this.$route.path;
    if (this.currentRoute.split("/")[1] === "form") {
      let payloadStorage: any = localStorage.getItem(
        this.experienceKey.payloadName
      );
      payloadStorage = JSON.parse(payloadStorage);
      if (
        this.experienceKey.payloadExperienceKey &&
        payloadStorage &&
        payloadStorage[this.experienceKey.payloadExperienceKey]
          .experienceInformation
      ) {
        this.experiencePayload =
          payloadStorage[
            this.experienceKey.payloadExperienceKey
          ].experienceInformation;
      } else {
        this.experiencePayload =
          this.experienceInformationData || this.experiencePayload;
        if (this.isPreviewForm) {
          const experienceData: any = {};
          Object.keys(this.experiencePayload).forEach((PrimaryKey) => {
            Object.keys(this.experiencePayload[PrimaryKey]).forEach(
              (secondaryKey) => {
                if (this.experiencePayload[PrimaryKey][secondaryKey]) {
                  this.filledForms = [
                    ...new Set([...this.filledForms, PrimaryKey]),
                  ];
                  experienceData[PrimaryKey] =
                    this.experiencePayload[PrimaryKey];
                }
              }
            );
          });
        }
      }
      this.onClickForms();
    } else {
      this.isReviwer = true;
      this.experiencePayload = this.experienceInformationData;
      if (this.isPreviewForm) {
        const experienceData: any = {};
        Object.keys(this.experiencePayload).forEach((PrimaryKey) => {
          Object.keys(this.experiencePayload[PrimaryKey]).forEach(
            (secondaryKey) => {
              if (this.experiencePayload[PrimaryKey][secondaryKey]) {
                this.filledForms = [
                  ...new Set([...this.filledForms, PrimaryKey]),
                ];
                experienceData[PrimaryKey] = this.experiencePayload[PrimaryKey];
              }
            }
          );
        });
        if (this.isEditFile) {
          this.filledForms = [];
        }
      }
    }
    await this.$apollo
      .query({
        query: gql`query ${BASIC_DETAIL_SUB_QUESTIONS_QUERY}`,
      })
      .then((result) => {
        this.experienceData = result.data.basicDetailsSubQuestions.data;
        this.accordionStatus =
          this.experienceData[0].attributes.fields.formInputs;
      });
    this.originalData = JSON.parse(JSON.stringify(this.experiencePayload));
  }

  public onClickForm(event: any) {
    this.activeForm = event;
    const fileName = this.accordionStatus[event][0].name;
    this.filledForms.push(fileName);
    this.onClickForms();
  }
  public onClickForms() {
    Object.keys(this.experiencePayload).forEach((PrimaryKey) => {
      Object.keys(this.experiencePayload[PrimaryKey]).forEach(
        (secondaryKey) => {
          if (
            this.experiencePayload[PrimaryKey][secondaryKey] == null ||
            !this.experiencePayload[PrimaryKey][secondaryKey]
          ) {
            this.checkValidated[PrimaryKey] = false;
          } else {
            this.checkValidated[PrimaryKey] = true;
            this.isValidFlag = true;
            this.$emit("isValidFlag", this.isValidFlag);
          }
        }
      );
    });
    this.$emit("input", this.experiencePayload);
  }
  public numberValidation(evt: any, length?: number) {
    utils.onlyNumberKey(evt, length);
  }

  public avoidNumbersAndSpecialCharacter(evt: any) {
    utils.avoidNumbersAndSpecialCharacters(evt);
  }

  public onlyAlphabets(evt: any, fieldName = "") {
    utils.onlyAlphabets(evt, fieldName);
  }

  public fieldValidation(
    value: any,
    eventName: string,
    fieldType: string,
    maxLength = 0,
    fieldName = ""
  ) {
    if (fieldType === "number") {
      this.eventBind[eventName](value, maxLength);
    } else if (fieldName === "state") {
      this.eventBind[eventName](value, fieldName);
    } else {
      this.eventBind[eventName](value);
    }
  }

  public emptyEvent() {
    return;
  }

  @Watch("experiencePayload", { deep: true })
  public updatePayload() {
    this.onClickForms();
  }

  @Watch("isChangedData")
  public changedData() {
    let label = "";
    let formTitle = "";
    for (const property in this.experiencePayload) {
      for (const innerProperty in this.experiencePayload[property]) {
        if (
          this.originalData[property][innerProperty].toString() !==
          this.experiencePayload[property][innerProperty].toString()
        ) {
          const formData = this.experienceData[0].attributes.fields.formInputs;
          const inputDetials: any = formData
            .flat(1)
            .find((item) => item.name === property);
          formTitle = inputDetials.label;
          const fieldGroups = inputDetials.propBind.fieldGroups[0];
          const fieldsDetials: any = fieldGroups.inputs
            .flat(1)
            .find((item: any) => item.name === innerProperty);
          label = fieldsDetials.label;
        } else {
          const exitsDataIdx = this.editedChanges.findIndex((data: any) => {
            return data.fieldName === innerProperty;
          });
          if (exitsDataIdx > -1) {
            if (this.revertFieldNames.length) {
              this.revertFieldNames.push(innerProperty);
            } else {
              this.revertFieldNames = [innerProperty];
            }
            this.revertFieldNames = [...new Set(this.revertFieldNames)];
          }
        }
      }
    }
    this.$emit("revertedFieldNames", this.revertFieldNames);
    this.$emit("changedData", this.editedChanges);
  }
}
