import { STATUS, User } from "@/models/common-interface/common.interface";
import { ReviewerInactivePreviewFormInterface } from "@/models/reviewer-inactive-preview-form/reviewer-inactive-preview-form.interface";
import { inactiveFormApi } from "@/providers/apis/inactive-form";
import { commonService } from "@/providers/services/common-service";
import { utils } from "@/utils/utils";
import moment from "moment";
import { Component, Prop, Vue } from "vue-property-decorator";
import AppLoaderComponent from "../app-loader/app-loader.component.vue";
import InactivePreviewForm from "../inactive-preview-form/inactive-preview-form.component.vue";
import PDFViewerComponent from "../pdf-viewer/pdf-viewer.component.vue";
import ScoreRubicFormComponent from "../score-rubic-form/score-rubic-form.component.vue";
import { appConfigService } from "@/providers/services/app/app-config";
import ErrorPopupComponent from "../error-popup/error-popup.component.vue";

@Component({
  name: "reviewer-renewal-preview",
  components: {
    AppLoaderComponent,
    ScoreRubicFormComponent,
    InactivePreviewForm,
    PDFViewerComponent,
    ErrorPopupComponent,
  },
})
export default class ReviewerInactivePreview extends Vue {
  @Prop()
  public applicationPreviewForm!: ReviewerInactivePreviewFormInterface;
  public isUserApplication!: boolean;
  public applicantId = "";
  public currentPath = "";
  public sectionTabHeight = 0;
  public mobileSectionTabHeight = 0;
  public applicantPreviewForm: ReviewerInactivePreviewFormInterface =
    {} as ReviewerInactivePreviewFormInterface;
  public userDetails!: User;
  public isLoading = true;
  public isMobileView = false;
  public toogleStatus = false;
  public commentEventNone = false;
  public isFormEdit = false;
  public isApproveStatus = true;
  public isSubmit = false;
  public showCommentBox = false;
  public showScoringForm = false;
  public showCommentBoxWithDisable = false;
  public showRubricTab = false;
  public isUpdatePageNumber = false;
  public isFormValid = false;
  public validForm = true;
  public headerHeight = 1;
  public tabSelected = "application-form";
  public applicationsTabSelect = "basic-details";
  public formsSelect = "application-form";
  public formType = "";
  public PDFApplicationPrintName = "";
  public source = "";
  public approveComment = "";
  public approveStatus = "";
  public formSelected = "";
  public applicantFullName = "";
  public mostLastRecord: any = null;
  public historyRecords: any;
  public historys: any = [];
  public editedUserDetails: any[] = [];
  public tabScrollMove: any = {};
  public collapseIdx = "";
  public filesEdit = [
    "certificateFiles",
    "cprsCertificate",
    "cfssCertificate",
    "otherCertificate",
  ];
  public isMigrationForm = false;
  public applicationReceivedDate = "";
  public errorMessage = "";
  public envType = "";
  public certificateApplicationId = "";

  @Prop()
  public isPeerPreview!: any;
  public updateReviewer: any;

  public async created() {
    this.envType = appConfigService.getEnvironmentType();
    this.formType = this.$route.query.formType?.toString();
    this.userDetails = utils.getUserDetails();
    if (utils.checkIsMobileView() || utils.checkIsTabView()) {
      this.isMobileView = true;
    } else {
      this.isMobileView = false;
    }
    this.applicantId = this.$route.path.split("/")[3];
    if (this.$route.path.split("/")[1] === "reviewer-dashboard") {
      this.isUserApplication = false;
      this.applicationsTabChange(
        this.applicationsTabSelect,
        "application",
        true
      );
      await this.getInactiveApplicationData();
    } else {
      this.isUserApplication = true;
      await this.getUserApplicationData();
      this.applicationsTabChange(
        this.applicationsTabSelect,
        "application",
        true
      );
    }
  }

  public getInactiveApplicationData() {
    this.editedUserDetails = [];
    let getAllEditedData: any[] = [];
    let temp: any;
    this.isLoading = true;
    inactiveFormApi
      .getInactiveApplicationForm(this.applicantId)
      .then((result) => {
        const userId = this.userDetails.userId;
        const userTask = result.data.userTask;
        this.historys = userTask.sort((a: any, b: any) => {
          a.createdAt = moment(a.createdAt).format("MM-DD-YYYY HH:mm:ss:SSS");
          b.createdAt = moment(b.createdAt).format("MM-DD-YYYY HH:mm:ss:SSS");
          return (
            new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime()
          );
        });
        this.historys.map((data: any) => {
          data.createdAt = moment
            .utc(data.createdAt)
            .local()
            .format("MM-DD-YYYY HH:mm A");
        });

        // current userTask and findStatus
        const currentUserTask = userTask.filter(
          (task: any) => task.userId === userId
        );
        const mostRecentHistory = currentUserTask.sort((a: any, b: any) => {
          return moment(a.createdAt, "MM-DD-YYYY HH:mm:ss").diff(
            moment(b.createdAt, "MM-DD-YYYY HH:mm:ss")
          );
        });
        this.mostLastRecord = mostRecentHistory[mostRecentHistory.length - 1];
        result.data.createdAt = moment(result.data.createdAt).format(
          "MM-DD-YYYY"
        );

        // this.historys.reverse();
        this.applicantPreviewForm = result.data;
        if (this.applicantPreviewForm.submittedMode === "migration") {
          this.isMigrationForm = true;
        }
        this.applicantFullName = `${this.applicantPreviewForm.data.certificationInActiveBasicDetails.firstName} ${this.applicantPreviewForm.data.certificationInActiveBasicDetails.lastName}`;
        this.applicantPreviewForm.data.certificationInActiveBasicDetails.fullName =
          this.applicantFullName;
        const fullAddress = utils.addressBuilder(
          this.applicantPreviewForm.data,
          "certificationInActiveBasicDetails",
          "default"
        );
        this.applicantPreviewForm.data.certificationInActiveBasicDetails.fullAddress =
          fullAddress;
        this.applicationReceivedDate = moment(
          this.applicantPreviewForm.data.certificationInactiveSignature
            .submissionDate
        ).format("MM-DD-YYYY");
        if (
          this.applicantPreviewForm.progressStatus === STATUS.accepted ||
          this.applicantPreviewForm.progressStatus === STATUS.notAccepted ||
          this.applicantPreviewForm.progressStatus === STATUS.needMoreInfo
        ) {
          this.approveComment = this.applicantPreviewForm.comment;
          this.approveStatus = this.applicantPreviewForm.progressStatus;
        }

        this.statusCheck();
        setTimeout(() => {
          this.getHeaderHeight();
          this.isLoading = false;
        }, 1000);
        this.isLoading = false;
        for (const property in this.applicantPreviewForm.data) {
          if (this.applicantPreviewForm.data["History"]) {
            this.applicantPreviewForm.data["History"].map(
              (historyData: any) => {
                for (const innerProperty in this.applicantPreviewForm.data[
                  property
                ]) {
                  if (
                    historyData[innerProperty] &&
                    historyData[innerProperty].length
                  ) {
                    temp = JSON.parse(
                      JSON.stringify(historyData[innerProperty])
                    );
                    temp.forEach((element: any) => {
                      if (this.filesEdit.includes(innerProperty)) {
                        element.oldValue = element.oldValue.map((data: any) => {
                          return data.originalFileName;
                        });
                        element.newValue = element.newValue.map((data: any) => {
                          return data.originalFileName;
                        });
                      }
                      if (
                        typeof element.oldValue === "object" &&
                        typeof element.newValue === "object"
                      ) {
                        element.oldValue = element.oldValue.length
                          ? element.oldValue.join(", ")
                          : element.oldValue;
                        element.newValue = element.newValue.length
                          ? element.newValue.join(", ")
                          : element.newValue;
                      }
                      temp = element;
                      switch (property) {
                        case "certificationInActiveBasicDetails":
                          temp.mainLabel = "Basic Details";
                          break;
                        default:
                          break;
                      }
                      if (getAllEditedData.length) {
                        getAllEditedData.push(temp);
                      } else {
                        getAllEditedData = [temp];
                      }
                    });
                  }
                }
              }
            );
          }
        }
        getAllEditedData.filter((currentValue, currentIndex) => {
          return getAllEditedData.indexOf(currentValue) !== currentIndex;
        });
        const unique = [
          ...new Set(
            getAllEditedData.map((a) => {
              return a.editDataTime + "/" + a.editorDetails.userId;
            })
          ),
        ];
        unique.forEach((splitUnique: string) => {
          const dateTime = splitUnique.split("/")[0];
          const userId = splitUnique.split("/")[1];
          getAllEditedData.forEach((data: any) => {
            if (
              data.editDataTime === dateTime &&
              data.editorDetails.userId === userId
            ) {
              if (this.editedUserDetails.length) {
                const editedIdx = this.editedUserDetails.findIndex(
                  (editDetail) => {
                    return (
                      editDetail.editorUserId === data.editorDetails.userId &&
                      editDetail.dateTime === data.editDataTime
                    );
                  }
                );
                if (editedIdx > -1) {
                  this.editedUserDetails[editedIdx].changesCount =
                    this.editedUserDetails[editedIdx].changesCount + 1;
                  if (this.editedUserDetails[editedIdx].data.length) {
                    const idx = this.editedUserDetails[
                      editedIdx
                    ].data.findIndex((a: any) => {
                      return a.mainLabel === data.mainLabel;
                    });
                    if (idx > -1) {
                      this.editedUserDetails[editedIdx].data[
                        idx
                      ].changesData.push(data);
                    } else {
                      this.editedUserDetails[editedIdx].data.push({
                        mainLabel: data.mainLabel,
                        changesData: [data],
                      });
                    }
                  }
                } else {
                  this.editedUserDetails.push({
                    editorUserId: data.editorDetails.userId,
                    data: [
                      {
                        mainLabel: data.mainLabel,
                        changesData: [data],
                      },
                    ],
                    dateTime: data.editDataTime,
                    editorName: data.editorDetails.name,
                    changesCount: 1,
                    comment: this.getComment(
                      this.applicantPreviewForm.data,
                      data.editDataTime,
                      data.editorDetails.userId
                    ),
                  });
                }
              } else {
                this.editedUserDetails = [
                  {
                    editorUserId: data.editorDetails.userId,
                    data: [
                      {
                        mainLabel: data.mainLabel,
                        changesData: [data],
                      },
                    ],
                    dateTime: data.editDataTime,
                    editorName: data.editorDetails.name,
                    changesCount: 1,
                    comment: this.getComment(
                      this.applicantPreviewForm.data,
                      data.editDataTime,
                      data.editorDetails.userId
                    ),
                  },
                ];
              }
            }
          });
        });
        this.editedUserDetails = this.editedUserDetails.sort(
          (a: any, b: any) => {
            a.dateTime = moment(a.dateTime).format("MM-DD-YYYY HH:mm:ss:SSS");
            b.dateTime = moment(b.dateTime).format("MM-DD-YYYY HH:mm:ss:SSS");
            return (
              new Date(a.dateTime).getTime() - new Date(b.dateTime).getTime()
            );
          }
        );
        this.editedUserDetails.map((data: any) => {
          data.dateTime = moment(data.dateTime).format(
            "MM-DD-YYYY [|] HH:mm A"
          );
        });
        this.editedUserDetails.reverse();
      })
      .catch((error) => {
        this.errorMessage = utils.errorMessage(error);
        this.isLoading = false;
      });
  }

  public getUserApplicationData() {
    this.applicantPreviewForm = this.applicationPreviewForm;
    if (this.applicationPreviewForm.submittedMode === "migration") {
      this.isMigrationForm = true;
    }
    this.applicantPreviewForm = this.applicationPreviewForm;
    this.applicantFullName = `${this.applicantPreviewForm.data.certificationInActiveBasicDetails.firstName} ${this.applicantPreviewForm.data.certificationInActiveBasicDetails.lastName}`;
    this.applicantPreviewForm.data.certificationInActiveBasicDetails.fullName =
      this.applicantFullName;
    const fullAddress = utils.addressBuilder(
      this.applicantPreviewForm.data,
      "certificationInActiveBasicDetails",
      "default"
    );
    this.applicantPreviewForm.data.certificationInActiveBasicDetails.fullAddress =
      fullAddress;
    this.applicationReceivedDate = moment(
      this.applicantPreviewForm.data.certificationInactiveSignature
        .submissionDate
    ).format("MM-DD-YYYY");
    this.isLoading = false;
    setTimeout(() => {
      this.getHeaderHeight();
    }, 1000);
  }

  public getComment(data: any, dateTime: string, editorId: string) {
    let comment = "";
    for (const property in data) {
      if (property === "editedComments") {
        data[property].filter((data: any) => {
          if (
            data.dateTime === dateTime &&
            data.editorDetails.userId === editorId
          ) {
            comment = data.comment;
          }
        });
      }
    }
    return comment;
  }

  public applicationsTabChange(
    formSelect: string,
    formName = "",
    initialLoad = false
  ) {
    this.isFormValid = !this.isFormValid;
    setTimeout(() => {
      if (this.applicationsTabSelect !== formSelect && this.validForm) {
        this.applicationsTabSelect = formSelect;
        if (this.formType === "offline") {
          this.isUpdatePageNumber = true;
          switch (formName) {
            case "application":
              this.source =
                "https://dno579h3gzw0n.cloudfront.net/files_upload/ee770a79-a1db-4fee-b1af-ef49c0261c9e.pdf";
              break;
            default:
              break;
          }
        }
        if (!initialLoad) {
          this.getHeaderHeight();
        }
      }
    }, 50);
  }

  public formsTabChange(tabSelect: string, formName = "") {
    if (!this.isFormEdit) {
      switch (formName) {
        case "application":
          this.applicationsTabChange("basic-details", "application");
          break;

        default:
          break;
      }
      this.formsSelect = tabSelect;
      setTimeout(() => {
        this.getHeaderHeight();
      }, 50);
    }
  }
  // for mobile preview

  public tabChange(tabSelect: string) {
    this.isFormValid = !this.isFormValid;
    setTimeout(() => {
      if (this.tabSelected !== tabSelect && !this.isFormEdit) {
        this.tabSelected = tabSelect;
        this.getHeaderHeight();
      }
    }, 100);
  }

  public getApplicantId() {
    this.isUpdatePageNumber = false;
    if (this.formSelected === "application-form") {
      this.PDFApplicationPrintName = `Inactive-application-${this.applicantPreviewForm.code}`;
    }
  }

  public getUpdatedData() {
    this.getInactiveApplicationData();
  }

  public statusCheck() {
    const status = utils.scoringSectionHideShow(
      this.applicantPreviewForm.progressStatus,
      this.mostLastRecord.status,
      "inactive"
    );
    this.showCommentBox = status["showCommentBox"];
    this.showRubricTab = status["showRubricTab"];
    this.showCommentBoxWithDisable = status["showCommentBoxWithDisable"];
  }

  public isEditForm(event: any) {
    this.isFormEdit = event;
  }

  public isValidForm(event: any) {
    this.validForm = event;
  }

  public getHeaderHeight() {
    const getHeaderElement = document.querySelector(".header");
    const getSectionTabElement = document.querySelector(".form-tab");
    this.headerHeight = getHeaderElement?.clientHeight as number;
    if (this.isMobileView) {
      const getMainHeaderElementHeight = document.querySelector(
        ".mobile-nav-container"
      )?.clientHeight as number;
      this.headerHeight = this.headerHeight + getMainHeaderElementHeight;
      const getMobileElement = document.querySelector(".mobile-section-tab");
      this.mobileSectionTabHeight = getMobileElement?.clientHeight as number;
    }
    this.sectionTabHeight = getSectionTabElement?.clientHeight as number;
  }

  public reloadPage(event: any) {
    if (event) {
      this.getInactiveApplicationData();
      if (this.formsSelect === "application-form" && this.isMobileView) {
        this.tabSelected = "";
        this.formsSelect = "approve-section";
      }
    }
  }

  public statusUpdate(status: string) {
    this.approveStatus = status;
    this.toogleStatus = !this.toogleStatus;
  }

  public approveSubmit() {
    this.isSubmit = true;
    if (this.approveStatus) {
      this.isLoading = true;
      this.isApproveStatus = true;
      const data = {
        id: this.applicantId,
        status: this.approveStatus.toLocaleUpperCase(),
        comment: this.approveComment,
      };
      commonService
        .approveApplication(data, "inactive")
        .then(() => {
          this.isSubmit = false;
          this.getInactiveApplicationData();
        })
        .catch((error) => {
          this.errorMessage = utils.errorMessage(error);
          this.isLoading = false;
        });
    } else {
      this.isApproveStatus = false;
    }
  }

  public closePopup() {
    this.$bvModal.hide("error-modal-center");
  }

  public closdedErrorPopup(event: any) {
    if (event) {
      this.errorMessage = "";
    }
  }
  public backUrl() {
    return this.isPeerPreview
      ? `/reviewer-dashboard/peers/${this.$route.query.id}`
      : "/reviewer-dashboard/inactive";
  }
  public isUpdateForm(event: any) {
    this.updateReviewer = event;
    this.$nextTick(() => {
      this.$bvModal.show("update-email-center");
    });
  }
  public closeEmailPopup() {
    this.$bvModal.hide("update-email-center");
  }
  public getPeerUpdatedData(event: boolean) {
    this.$emit("getPeerUpdatedData", true);
    if (event) {
      this.$emit("isUpdateForm", event);
    }
  }
}
