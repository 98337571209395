export const SCORING_RUBRIC_QUERY = `scoringRubricForm {  
    scoringRubricForms(sort: "id:ASC"){
      data {
        id
        attributes{
            scoring_rubric
        }
      }
    }
}`;
