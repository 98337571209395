export const DEMOGRAPHIC_QUERY = `DemographicDatas {  
    demographicDatas(sort: "id:ASC"){
        data{
            id
            attributes{
                title
                parent_slug
                fields
            }
        }
    }
}`;
