export const CONTINUING_EDUCATION = `ContinuingEducation{
    continuingEducations {
        data {
            id
            attributes {
                parent_slug
                form_fields
                form_name
            }
        }
    }
}`;
