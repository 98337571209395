import { APPLICATION_SUBMITTED_QUERY } from "@/graphql/application-submitted-query";
import {
  ApplicationSubmittedInterface,
  submittedContent,
} from "@/models/application-submitted/application-submitted.interface";
import gql from "graphql-tag";
import { Component, Vue } from "vue-property-decorator";
import AppLoaderComponent from "../app-loader/app-loader.component.vue";
import MoreInformation from "../more-information/more-information.vue";

@Component({
  name: "application-submitted",
  components: {
    AppLoaderComponent,
    MoreInformation,
  },
})
export default class ApplicationSubmittedComponent extends Vue {
  public applicationSubName: any;
  public applicationId: any;
  public SuccessfulMessage: submittedContent = {} as submittedContent;
  public messageContent = "";
  public showCode = [
    "Certification: Initial Application",
    "Certification: Part Two",
  ];
  public isLoading = true;
  public applicationSubmittedData: ApplicationSubmittedInterface =
    {} as ApplicationSubmittedInterface;

  public async created() {
    const el = document.getElementById("header");
    if (el) {
      el.classList.add("active");
      el.classList.remove("remove-container");
    }
    this.applicationSubName = this.$route.query.subTitle;
    this.applicationId = this.$route.query.applicationId;
    await this.$apollo
      .query({
        query: gql`query ${APPLICATION_SUBMITTED_QUERY}`,
      })
      .then((result) => {
        this.isLoading = false;
        this.applicationSubmittedData = result;
        const currentApplicationSubmitted =
          this.applicationSubmittedData.data.applicationSubmitteds.data.find(
            (result) => {
              if (result.attributes.form === this.applicationSubName) {
                return result;
              }
            }
          );
        if (currentApplicationSubmitted?.attributes) {
          this.SuccessfulMessage = currentApplicationSubmitted?.attributes;
          this.messageContent =
            currentApplicationSubmitted?.attributes.content.replace(
              /<\/?[^>]+(>|$)/g,
              ""
            );
        }
      });
  }
}
