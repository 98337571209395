export const APPLICATION_SUBMITTED_QUERY = `ApplicationSubmitteds {  
    applicationSubmitteds(sort: "id:ASC"){
        data{
            id
            attributes{
                title
                content
                sub_title
                sub_content
                description
                form
            }
        }
    }
}`;
