import { Component, Vue } from "vue-property-decorator";
import { getApplicationDetails } from "@/providers/apis/getApplictionDetails";
import AppLoaderComponent from "../app-loader/app-loader.component.vue";
import {
  filters,
  ReviewerAgencyInterface,
} from "@/models/reviewer-agency-lists/reviewer-agency-lists.interface";
import { utils } from "@/utils/utils";

@Component({
  name: "reviewer-agency-lists",
  components: {
    AppLoaderComponent,
  },
})
export default class ReviewerAgencyLists extends Vue {
  public agencyData: ReviewerAgencyInterface[] = [];
  public limit = 8;
  public currentPage = 1;
  public totalItem = 0;
  public page = 1;
  public offset = 0;
  public isDataEmpty = false;
  public isLoading = true;
  public filterDetails: filters = {} as filters;
  public tableFields = [
    {
      key: "logo",
      label: "Profile Picture",
      sortable: false,
      thClass: "logo-img pointer-events",
      tdClass: "logo-img-td",
    },
    {
      key: "id",
      label: "Agency ID",
      sortable: false,
      thClass: "agency-id pointer-events",
    },
    {
      key: "name",
      label: "Agency Name",
      sortable: true,
      thClass: "applicant-name",
    },
    {
      key: "contactPerson",
      label: "Point of Contact",
      sortable: true,
      thClass: "no-of-peers pointer-events",
    },
    {
      key: "phone",
      label: "Mobile No.",
      sortable: false,
      thClass: "no-of-programs pointer-events",
    },
    {
      key: "action",
      label: "View Profile",
      sortable: false,
      thClass: "application_action pointer-events",
    },
  ];
  public errorMessage = "";

  public created() {
    this.getReviewerAgencyLists();
  }

  public loadContent(page: number) {
    this.page = page;
    this.offset = (this.page - 1) * this.limit;
    this.getReviewerAgencyLists();
  }

  public sortChanged(event: Event) {
    if (event) {
      this.currentPage = 1;
    }
  }

  public navigate(agency: ReviewerAgencyInterface) {
    this.$router.push(`agency/${agency.id}`);
  }

  public getReviewerAgencyLists() {
    this.filterDetails.limit = this.limit;
    this.filterDetails.offset = this.offset;
    getApplicationDetails
      .getAgencyList(this.filterDetails)
      .then((result) => {
        this.isLoading = false;
        this.agencyData = result.list;
        this.totalItem = result.totalCount;
        if (this.agencyData.length > 0) {
          this.isDataEmpty = false;
        } else {
          this.isDataEmpty = true;
        }
      })
      .catch((error) => {
        this.errorMessage = utils.errorMessage(error);
        this.isLoading = false;
        this.isDataEmpty = true;
        this.$bvModal.show("error-modal-center");
        setTimeout(() => {
          this.$bvModal.hide("error-modal-center");
        }, 3000);
      });
  }

  public closePopup() {
    this.$bvModal.hide("error-modal-center");
  }
}
