import { utils } from "@/utils/utils";
import moment from "moment";
import { Component, Prop, Vue } from "vue-property-decorator";

@Component({
  name: "b-form-input-datepicker",
})
export default class FormInputDatepickerComponent extends Vue {
  // ----------------------------------------------------------------
  // Properties
  @Prop()
  public formType!: string;
  @Prop()
  public editForm!: boolean;
  @Prop()
  public keyName!: string;
  @Prop()
  public value!: string;
  @Prop()
  public payloadData: any;
  @Prop()
  public isPreviewForm!: boolean;

  public isDisable = false;
  public formatted = "";
  public selected = "";
  public text: any;

  public created() {
    this.text = this.value;
    this.isDisable = utils.disableField(this.editForm, this.formType);
    this.$watch("formattedSelected", (newVal: string, oldVal: string) => {
      if (newVal !== oldVal) {
        this.$emit("input", newVal);
      }
    });
  }

  onContext(ctx: any) {
    this.selected = ctx.selectedYMD ? ctx.selectedYMD : this.value;
    this.text = this.selected;
    this.$emit("input", this.selected);
  }

  get formattedSelected() {
    return this.selected ? this.formatDate(this.selected) : "";
  }

  formatDate(dateString: string) {
    const formats = ["YYYY-MM-DD", "MM-DD-YYYY", "DD-MM-YYYY", "DD-YYY-MM"];

    let parsedDate;
    for (const format of formats) {
      parsedDate = moment(dateString, format, true);
      if (parsedDate.isValid()) {
        break;
      }
    }

    if (parsedDate && parsedDate.isValid()) {
      return parsedDate.format("MM-DD-YYYY");
    } else {
      console.error("Failed to parse date string:", dateString);
      return dateString;
    }
  }
}
