import { render, staticRenderFns } from "./forget-password-emailverify-component.vue?vue&type=template&id=56078b74&"
import script from "./forget-password-emailverify-component.ts?vue&type=script&lang=ts&"
export * from "./forget-password-emailverify-component.ts?vue&type=script&lang=ts&"
import style0 from "./forget-password-emailverify-component.scss?vue&type=style&index=0&prod&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports