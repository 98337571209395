export const certificates = [
  {
    label: "Certified Family Support Specialist",
    validUpto: "26 July 2022",
    renew: true,
    type: "cfss",
    className: "cyapss-logo",
  },
  {
    label: "Certified Family Support Specialist Program",
    validUpto: "26 March 2023",
    renew: false,
    type: "cyapss",
    className: "cyapss-logo",
  },
];

export const educationCertificate = [
  {
    title: "Building Resilient Families",
    programTitle: "Strategies for Family Support Specialists",
    description:
      "Learn effective strategies to help families facing various challenges build resilience and overcome adversity.",
    date: "26 March 2023",
    isCertificate: true,
  },
  {
    title: "Building Resilient Families",
    programTitle: "Strategies for Family Support Specialists",
    description:
      "Learn effective strategies to help families facing various challenges build resilience and overcome adversity.",
    date: "26 March 2023",
    isCertificate: true,
  },
];
