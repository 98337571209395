import { Component, Vue } from "vue-property-decorator";
import { loginApi } from "@/providers/apis/login";
import AppLoaderComponent from "../app-loader/app-loader.component.vue";
import { utils } from "@/utils/utils";
@Component({ name: "login-page", components: { AppLoaderComponent } })
export default class LoginPageComponent extends Vue {
  public forgetPassword = {
    newPassword: "",
  };
  public isLoading = false;
  public linkexpiredForgetPassword = false;
  public ResetpasswordSuccessful = false;
  public activeField = "";
  public checkValidation = false;
  public loginType: any = "";
  public confirmPassword = "";
  public isconfirmPassValid = true;
  public showandHidePassword = {
    newPassword: false,
    confirmPass: false,
  };
  constructor() {
    super();
  }
  public forgetpasswordBacktoLogin() {
    this.$router.push({
      path: "/login",
    });
  }
  public created() {
    loginApi
      .forgetPasswordVerify(this.$route.query.token)
      .then((res) => {
        if (res.status == 204) {
          this.linkexpiredForgetPassword = true;
        } else if (res.response.data.status == 401) {
          this.$router.push("/forgot/password-verify");
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }

  public onSubmit() {
    this.checkValidation = true;
    this.$validator.validate().then((validate) => {
      if (validate) {
        this.checkValidation = false;
        if (this.forgetPassword.newPassword !== this.confirmPassword) {
          this.isconfirmPassValid = false;
        } else {
          this.isconfirmPassValid = true;
        }
        if (this.forgetPassword.newPassword == this.confirmPassword) {
          this.isLoading = true;
          loginApi
            .resetPassword(
              { newPassword: this.forgetPassword.newPassword },
              this.$route.query.token
            )
            .then((res) => {
              this.isLoading = false;
              if (res.status == 204) {
                this.ResetpasswordSuccessful = true;
              } else if (res.response.data.status == 401) {
                this.$router.push("/forgot/password-verify");
              }
            })
            .catch((error) => {
              this.isLoading = false;
            });
        }
      }
    });
  }

  public activeInput(field: string) {
    this.activeField = field;
  }

  public valueCheck(field: string) {
    if (field === "confirm-password" && !this.confirmPassword) {
      this.activeField = "";
    } else if (field === "new-password" && !this.forgetPassword.newPassword) {
      this.activeField = "";
    }
  }

  public showAndHide(inputField: string) {
    switch (inputField) {
      case "new":
        this.showandHidePassword.newPassword = utils.UIUpdateUsingKey(
          this.showandHidePassword.newPassword
        );
        break;
      case "confirm":
        this.showandHidePassword.confirmPass = utils.UIUpdateUsingKey(
          this.showandHidePassword.confirmPass
        );
        break;
      default:
        break;
    }
    const passwsordElement = document.getElementsByClassName(
      `password-${inputField}`
    )[0];
    const password =
      passwsordElement.getElementsByClassName("password-input")[0];
    const type =
      password.getAttribute("type") === "password" ? "text" : "password";
    password.setAttribute("type", type);
  }
}
