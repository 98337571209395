import { http } from "@/providers/http";
export class ReportsApi {
  private static INSTANCE = new ReportsApi();

  static get instance() {
    return this.INSTANCE;
  }

  // GET for Part One application

  public getReportlist(fromDate = "", toDate = "") {
    const endpoint = `cfss/reports`;
    const params = {
      fromDate: fromDate,
      toDate: toDate,
    };
    const headers = http.getTokenHeaders();
    return http.get(endpoint, headers, params).then((response) => {
      return response;
    });
  }
}

export const reportsApi = ReportsApi.instance;
