export const BASIC_DETAIL_SUB_QUESTIONS_QUERY = `BasicDetailsSubQuestions {  
    basicDetailsSubQuestions(sort: "id:ASC"){
        data{
            id
            attributes{
                title
                parent_slug
                fields
            }
        }
    }
}`;
export const SCOPE_OF_ACTIVITY_QUERY = `ScopeOfActivities {
    scopeOfActivities(sort: "id:ASC"){
        data{
            id
            attributes{
                titleContent{
                    title
                    content
                    Input_fields
                    parent_slug
                }
            }
        }
    }
}`;
