import { AppConfigModel } from "@/models/app-config/app-config.interface";
import { http } from "../http";

export class AppConfigAPI {
  private static INSTANCE = new AppConfigAPI();
  private appConfigNamespace = "config";
  static get instance() {
    return this.INSTANCE;
  }

  public getAppConfiguration(): Promise<AppConfigModel> {
    const endpoint = `${window.location.origin}/${this.appConfigNamespace}/${window.location.hostname}.json`;
    return http.get(endpoint).then(
      (response) => {
        return response.data;
      },
      () => Promise.resolve()
    );
  }
}

export const appConfigAPI = AppConfigAPI.instance;
