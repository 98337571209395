export const SCOPE_OF_ACTIVITY_QUERY = `CoeSoas {  
    coeSoas(sort: "order_no:ASC"){
      data {
        id
        attributes{
          requirement
          docKeyName
            description
            order_no
            form_fields{
              id
              parent_slug
              fields
          }
          title
          icon_image {
            data {
              id
              attributes {
              name
              alternativeText
              formats
              url
              }
            }
          }
          button{
              label
              url
              slug
              uploadedFileName
            }
        }
      }
    }
}`;
