export const LETTER_OF_REFERENCE_QUERY = `LetterOfReferences {  
  letterOfReferences(sort: "id:ASC"){
    data{
      id
      attributes{
        application_name
        main_title
        form_fields{
          id
          parent_slug
          fields
          formBeforeText
      }
      }
    }
  }
}`;
