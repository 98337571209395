import { Component, Vue } from "vue-property-decorator";
import { loginApi } from "@/providers/apis/login";
import AppLoaderComponent from "../app-loader/app-loader.component.vue";
@Component({ name: "login-page", components: { AppLoaderComponent } })
export default class LoginPageComponent extends Vue {
  public loginData = {
    email: "",
  };
  public forgetPasswordEmailVerify = false;
  public isLoading = false;
  public isInvalidUser = false;
  public activeField = "";
  public checkValidation = false;
  public loginType: any = "";
  constructor() {
    super();
  }
  public forgetpasswordBacktoLogin() {
    this.$router.push({
      path: "/login",
    });
  }

  public onSubmit() {
    this.checkValidation = true;
    this.$validator.validate().then((validate) => {
      if (validate) {
        this.isLoading = true;
        loginApi
          .forgetpassword(this.loginData)
          .then((res) => {
            this.isLoading = false;
            if (res.status == 204 || res.status == 200) {
              this.isInvalidUser = false;
              this.forgetPasswordEmailVerify = true;
            }
          })
          .catch((error) => {
            this.isLoading = false;
            if (error.response.data.status == 404) {
              this.isInvalidUser = true;
            } else if (error.response.data.status == 401) {
              this.forgetpasswordBacktoLogin();
            }
          });
      }
    });
  }

  public activeInput(field: string) {
    this.activeField = field;
  }

  public valueCheck(field: string) {
    if (field === "email" && !this.loginData.email) {
      this.activeField = "";
    }
  }
}
