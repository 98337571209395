export const CERTIFICATION_QUERY = `CertificationContents {  
    certificationContents(sort: "id:ASC"){
      data {
        id
        attributes {
            title
            description
            button {
                label
                url
                slug
            }
        }
      }
    }
  }`;

export const STEPS_EARN_QUERY = `StepsToEarningYourCyapsses {
  stepsToEarningYourCyapsses(sort: "id:ASC") {
    data {
      id
      attributes {
        heading
        steps_contents {
          label
          content
          slug
          button {
            url
            label
            slug
          }
          cards {
            label
            content
            slug
            url
          }
        }
      }
    }
  }
}`;
