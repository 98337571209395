var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"additional-renewal-supervisor-form-container"},[_c('div',{staticClass:"additional-renewal-supervisor-form"},[_c('div',{class:`${_vm.isScroll ? 'stick' : ''}`,attrs:{"id":"sticky"}},[_c('div',{staticClass:"title-header"},[_c('h3',{staticClass:"title"},[_vm._v(_vm._s(_vm.formTitle.program_title))]),_c('h1',{staticClass:"sub-title"},[_vm._v(" "+_vm._s(_vm.formTitle.application_name)+" ")])])]),(!_vm.isLoading && _vm.applicationData.length)?_c('div',{staticClass:"container-form default-form"},[_c('b-form',_vm._l((_vm.supervisorFormFields),function(formInputs,indexForm){return _c('div',{key:indexForm},[_c('div',{staticClass:"supervisor-heading-container"},[_c('h1',{staticClass:"form-title"},[_vm._v(" "+_vm._s(formInputs.title + " " + (indexForm + 1))),_c('span',{class:_vm.alreadySubmittedEmailIdx.includes(indexForm)
                    ? 'is-invalid'
                    : '',domProps:{"innerHTML":_vm._s(
                  _vm.alreadySubmittedEmailIdx.includes(indexForm)
                    ? '&nbsp;&nbsp;&nbsp;(This supervisor email address is you already submitted)'
                    : ''
                )}})]),_c('span',{staticClass:"remove-btn btn",attrs:{"type":"button"},on:{"click":function($event){return _vm.removeSupervisor(indexForm)}}},[(indexForm !== 0)?_c('b-icon',{staticClass:"icon-remove",attrs:{"icon":"x-lg"}}):_vm._e()],1)]),_vm._l((formInputs.inputs),function(formValue,indexValue){return _c('div',{key:indexValue,class:['fields-main-container', formValue[0].mainClass]},[_c('div',{staticClass:"form-div",class:[
                formValue.length == 2
                  ? 'double-input'
                  : formValue.length > 2
                  ? 'multi-input'
                  : '',
              ]},_vm._l((formValue),function(formInput,indexInput){return _c('div',{key:indexInput,staticClass:"form-label",class:[
                  formInput.subClass,
                  formInput.field === 'b-form-textarea'
                    ? 'textarea-field'
                    : '',
                  _vm.classNameChooser(formInput?.class),
                ]},[(
                    formInput.name !== 'supervisorfullName' &&
                    formInput.name !== 'fullAddress' &&
                    formInput.name !== 'socialSecurityNumberX' &&
                    !_vm.hideDiv.includes(formInput.name)
                  )?[(!formInput.label)?_c('div',{class:'label-container'},[_c('span',{staticClass:"visiblity-hidden label"},[_vm._v(" "+_vm._s(formValue[0].label)+" ")])]):_vm._e(),(formInput.label)?_c('div',{class:formInput.label !== null
                        ? 'label-container'
                        : 'display-label'},[(formInput.label)?_c('span',{staticClass:"label",domProps:{"innerHTML":_vm._s(formInput.label + `&nbsp;&nbsp;`)}}):_vm._e(),(
                        formInput.label &&
                        formInput?.validate?.required === true
                      )?_c('span',{staticClass:"text-danger"},[_vm._v("*")]):_vm._e()]):_vm._e(),_c(formInput.field,_vm._b({directives:[{name:"validate",rawName:"v-validate",value:(formInput?.validate?.veeValidate),expression:"formInput?.validate?.veeValidate"}],key:indexForm,tag:"component",class:[formInput.class],attrs:{"name":formInput?.validate?.name + ' ' + indexForm,"autocomplete":"off","editForm":true,"formType":'default'},on:{"input":function($event){return _vm.fieldUpdate(formInput.name)}},nativeOn:{"keypress":function($event){_vm.eventBind[formInput?.propBind?.eventName]
                        ? _vm.fieldValidation(
                            $event,
                            formInput?.propBind?.eventName,
                            formInput?.propBind.type,
                            formInput?.validate?.maxLength
                          )
                        : _vm.emptyEvent}},model:{value:(_vm.supervisorPayLoadData[indexForm][formInput.name]),callback:function ($$v) {_vm.$set(_vm.supervisorPayLoadData[indexForm], formInput.name, $$v)},expression:"supervisorPayLoadData[indexForm][formInput.name]"}},'component',formInput.propBind,false)),(formInput.subLabel)?_c('label',{staticClass:"label-span"},[_vm._v(_vm._s(formInput.subLabel))]):_vm._e(),(
                      formInput?.propBind?.type == 'email' &&
                      _vm.supervisorEmailDuplicateIndexs.length &&
                      _vm.supervisorEmailDuplicateIndexs.includes(indexForm)
                    )?_c('span',{staticClass:"is-invalid"},[_vm._v("Email must be unique")]):_vm._e(),(
                      _vm.checkValidForm &&
                      _vm.$validator.errors.has(
                        formInput?.validate?.name + ' ' + indexForm
                      )
                    )?_c('span',{staticClass:"is-invalid"},[_vm._v(" "+_vm._s(formInput.propBind.type === "email" ? _vm.$validator.errors .first( formInput?.validate?.name + " " + indexForm ) .replace(indexForm.toString(), "") : "This field is required"))]):_vm._e()]:_vm._e()],2)}),0)])})],2)}),0)],1):_vm._e(),_c('div',{staticClass:"action-btn"},[_c('div',{staticClass:"right-btn-div"},[_c('b-button',{staticClass:"next-btn btn",attrs:{"type":"button"},on:{"click":_vm.onSubmit}},[_vm._v(" Submit "),_c('b-icon',{attrs:{"icon":"chevron-compact-right"}})],1)],1)])]),(_vm.isLoading)?_c('AppLoaderComponent',{attrs:{"type":'large'}}):_vm._e(),(_vm.errorMessage)?_c('ErrorPopupComponent',{attrs:{"componentName":'-error-popup',"errorMessage":_vm.errorMessage},on:{"closdedErrorPopup":function($event){return _vm.closdedErrorPopup($event)}}}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }