var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"demographic-main-container"},_vm._l((_vm.demographicData),function(demographic,index){return _c('div',{key:index,staticClass:"demographic-container border-top-line"},[_c('label',{staticClass:"label"},[_vm._v(" "+_vm._s(demographic.attributes.title)+" ")]),_c('div',{class:demographic.attributes?.fields.class},_vm._l((demographic.attributes.fields
          .formInputs),function(formInputsData,indexi){return _c('div',{key:indexi,staticClass:"form-inputs-container"},[_c('div',{staticClass:"small-content"},[_c('div',{staticClass:"form-div"},_vm._l((formInputsData),function(formInput,indexj){return _c('div',{key:indexj,staticClass:"form-label",class:_vm.fieldUpdate(formInput.name) ? 'margin-bottom-0' : ''},[(!_vm.fieldUpdate(formInput.name))?[_c('div',{class:formInput.label !== null
                      ? 'label-container'
                      : 'display-none'},[(formInput.label)?_c('label',{staticClass:"label-text"},[_vm._v(_vm._s(formInput.label)+" ")]):_vm._e()]),_vm._l((formInput.propBind.fieldGroups),function(fields,indexk){return _c('div',{key:indexk,staticClass:"field-container",class:[formInput.fieldClass]},[(fields.label)?_c('label',{staticClass:"field-label"},[_vm._v(_vm._s(fields.label))]):_vm._e(),_c(fields.field,_vm._b({tag:"component",class:fields.class,attrs:{"formIndex":indexk,"autocomplete":"off","disabled":_vm.isDisable,"editForm":_vm.editForm,"formType":_vm.formType},model:{value:(_vm.demographicPayload[formInput.name][fields.name]),callback:function ($$v) {_vm.$set(_vm.demographicPayload[formInput.name], fields.name, $$v)},expression:"demographicPayload[formInput.name][fields.name]"}},'component',
                      fields.options
                        ? formInput.propBind.fieldGroups
                        : fields.propBind
                    ,false))],1)})]:_vm._e()],2)}),0)])])}),0)])}),0)
}
var staticRenderFns = []

export { render, staticRenderFns }