import { MAIN_CONTENT_QUERY } from "@/graphql/cfss-main-content-query";
import {
  CfssMainContentData,
  MainContentAttributes,
} from "@/models/cfss-main-content/cfss-main-content.interface";
import gql from "graphql-tag";
import Vue from "vue";
import Component from "vue-class-component";

@Component({
  name: "testimonial-page",
})
export default class CfssMainContent extends Vue {
  public peerSupports: CfssMainContentData[] = [];
  public peerSupportsData!: MainContentAttributes;
  public showModal = false;
  public activetestimonial: any = {};
  public readMoreActivated = false;

  public async created() {
    // MAIN_CONTENT_QUERY
    await this.$apollo
      .query({
        query: gql`query ${MAIN_CONTENT_QUERY}`,
      })
      .then((result) => {
        this.peerSupports = result.data.cfssContents.data;
        this.peerSupportsData = this.peerSupports[0].attributes;
      });
  }
}
