import { http } from "@/providers/http";
export class NotAcceptedEmailApi {
  private static INSTANCE = new NotAcceptedEmailApi();

  static get instance() {
    return this.INSTANCE;
  }

  public sendEmailData(emailData: FormData) {
    const endpoint = "cfss/email";
    const headers = {
      ...http.getTokenHeaders(),
      "Content-Type": "multipart/form-data",
    };
    return http.post(endpoint, headers, emailData, true).then((response) => {
      return response;
    });
  }

  public getEmailHistory(applicationId: any) {
    const endpoint = `cfss/email?applicationId=${applicationId}`;
    const headers = http.getTokenHeaders();
    return http.get(endpoint, headers).then((response) => {
      return response;
    });
  }
}
export const notAcceptedEmailApi = NotAcceptedEmailApi.instance;
