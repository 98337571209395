import { Component, Vue } from "vue-property-decorator";
import { gql } from "graphql-tag";
import RadioButtonComponent from "../radio-button/radio-button.component.vue";
import SignatureSubmissionComponent from "../signature-submission/signature-submission.component.vue";
import AppLoaderComponent from "../app-loader/app-loader.component.vue";
import EligibilityCriteriaComponent from "../eligibility-criteria/eligibility-criteria.component.vue";
import { utils } from "@/utils/utils";
import { INACTIVE_APPLICATION_QUERY } from "@/graphql/inactive-application-query";
import {
  FormFieldContents,
  InactiveApplicationData,
} from "@/models/inactive-application/inactive-application.interface";
import { inactiveFormApi } from "@/providers/apis/inactive-form";
import { loginApi } from "@/providers/apis/login";
import moment from "moment";
import ErrorPopupComponent from "../error-popup/error-popup.component.vue";
import FormInputDatepickerComponent from "../form-input-datepicker/form-input-datepicker.component.vue";

@Component({
  name: "letter-of-reference",
  components: {
    "b-form-radio-group": RadioButtonComponent,
    "b-form-signature-submission": SignatureSubmissionComponent,
    "b-form-input-datepicker": FormInputDatepickerComponent,
    AppLoaderComponent,
    EligibilityCriteriaComponent,
    ErrorPopupComponent,
  },
})
export default class InactiveApplication extends Vue {
  public applicationData: Array<InactiveApplicationData> = [];
  public formData: FormFieldContents[] = [];
  public formNames = [
    "certificationInActiveBasicDetails",
    "certificationInactiveSignature",
  ];
  public currentRoute = "";
  public formApplicationId = "";
  public mainId = "app";
  public errorMessage = "";
  public formPagination = 0;
  public isScroll = false;
  public isLoading = false;
  public checkValidForm = false;
  public isValid = false;
  public saveDataDraft = false;
  public signatureInvalid = false;
  public isInvalid = false;
  public isNavLoad = false;
  public userDetails: any;
  public isReviewerUser: any;
  public inactivePayload: any = {
    fillStatus: "DRAFT",
    certificationInActiveBasicDetails: {
      firstName: "",
      lastName: "",
      positionAgency: "",
      certificationNumber: "",
      renewalApplicationDate: "",
      addressLine1: "",
      addressLine2: "",
      city: "",
      state: "",
      zip: "",
      phoneNumber: "",
      email: "",
      employedForLicensed: "",
      extenuatingCircumstance: "",
    },
    certificationInactiveSignature: {
      isSignature: "",
      submissionDate: moment().format("MM-DD-YYYY"),
    },
  };
  public formTitle = {
    main_title: "",
    application_name: "",
  };
  public eventBind: any = {
    acceptNumberOnly: this.numberValidation,
    avoidNumbersAndSpecialChar: this.avoidNumbersAndSpecialCharacter,
    telCharacter: this.telCharacterValidate,
    onlyAlphabets: this.onlyAlphabets,
  };
  public disableFiled = ["certificationNumber", "certificateExpirationDate"];

  public async created() {
    const userDetails = localStorage.getItem("user_details");
    const token = localStorage.getItem("access_token");
    if (userDetails) {
      this.userDetails = JSON.parse(userDetails);
      const userRolesData = this.userDetails.roles;
      this.isReviewerUser =
        userRolesData && userRolesData.indexOf("REVIEWER") !== -1;
    } else {
      this.isReviewerUser = false;
    }
    let payloadStorage: any = localStorage.getItem("inactiveFormPayload");
    if (payloadStorage) {
      payloadStorage = JSON.parse(payloadStorage);
      this.inactivePayload = { ...payloadStorage };
    }
    if (!this.isReviewerUser && token && !payloadStorage) {
      const userDetailsEmail = this.userDetails.email;
      localStorage.setItem(
        "inactiveFormPayload",
        JSON.stringify(this.inactivePayload)
      );
      this.isNavLoad = false;
    } else {
      if (!payloadStorage) {
        this.$router.push({
          name: "login-page",
          query: { application: "inactive-form" },
        });
      }
    }
    this.inactivePayload.certificationInactiveSignature.submissionDate =
      moment().format("MM-DD-YYYY");
    this.$apollo
      .query({
        query: gql`query ${INACTIVE_APPLICATION_QUERY}`,
      })
      .then((result) => {
        this.isLoading = false;
        this.applicationData = result.data.inactiveApplications.data;
        this.formTitle = {
          main_title: this.applicationData[0].attributes.main_title,
          application_name: this.applicationData[0].attributes.application_name,
        };
        this.formData = this.applicationData[0].attributes.form_fields;
      });
    const el = document.getElementById("header");
    if (el) {
      el.classList.add("remove-container");
    }
  }

  public mounted() {
    const mainEl: any = document.querySelector(".main-content");
    if (mainEl) {
      mainEl.addEventListener("keyup", (event: any) => {
        if (event.shiftKey && event.keyCode == 9) {
          const el = event.path[0];
          if (el) {
            el.scrollIntoView({ block: "center", behavior: "smooth" });
          }
        }
      });
    }
    const el = document.getElementById("header");
    if (el) {
      el.classList.add("remove-container");
    }
  }

  public storeBasicDetais(basicdetails: any) {
    this.inactivePayload.certificationInActiveBasicDetails.firstName =
      this.userDetails.firstName;
    this.inactivePayload.certificationInActiveBasicDetails.lastName =
      this.userDetails.lastName;
    this.inactivePayload.certificationInActiveBasicDetails.email =
      this.userDetails.email;
    this.inactivePayload.certificationInActiveBasicDetails.certificationNumber =
      basicdetails.certificateNumber;
    this.inactivePayload.certificationInActiveBasicDetails.certificateExpirationDate =
      basicdetails.expiryDate;
  }

  public fieldUpdateReference(inputName: string) {
    const nameNotVisiable = [
      "applicantFullName",
      "referenceFullName",
      "fullAddress",
    ];
    return nameNotVisiable.includes(inputName);
  }

  public numberValidation(evt: any, length?: number) {
    utils.onlyNumberKey(evt, length);
  }

  public avoidNumbersAndSpecialCharacter(evt: any) {
    utils.avoidNumbersAndSpecialCharacters(evt);
  }

  public telCharacterValidate(evt: any, length?: number) {
    utils.telCharacterValidate(evt, length);
  }

  public async saveDraft() {
    this.$bvToast.show("example-toast");
    this.isValid = true;
    localStorage.setItem(
      "inactiveFormPayload",
      JSON.stringify(this.inactivePayload)
    );
    this.saveDataDraft = true;
    setTimeout(() => {
      this.saveDataDraft = false;
    }, 1000);
  }

  public nextButton() {
    this.checkValidForm = true;
    if (this.inactivePayload) {
      const el = document.getElementById(this.mainId);
      if (el) {
        el.scrollIntoView({ behavior: "smooth" });
      }
      if (this.formPagination < this.formData.length) {
        const page = this.formPagination + 1;
        this.formPagination = page;
      }
      localStorage.setItem(
        "inactiveFormPayload",
        JSON.stringify(this.inactivePayload)
      );
    }
  }

  public onSubmit() {
    this.checkValidForm = true;
    localStorage.setItem(
      "inactiveFormPayload",
      JSON.stringify(this.inactivePayload)
    );
    this.$validator.validate().then(async (validate) => {
      if (validate) {
        if (this.inactivePayload.certificationInactiveSignature.isSignature) {
          this.signatureInvalid = false;
        } else {
          this.signatureInvalid = true;
          setTimeout(() => {
            const el = document.getElementsByClassName("is-invalid")[0];
            if (el) {
              el.scrollIntoView({ behavior: "smooth", block: "end" });
            }
          }, 10);
          return;
        }
        let payloadStorage: any = localStorage.getItem("inactiveFormPayload");
        if (payloadStorage) {
          payloadStorage = JSON.parse(payloadStorage);
          this.inactivePayload = { ...payloadStorage };
        }
        this.inactivePayload.fillStatus = "COMPLETED";
        if (this.inactivePayload.id == null) {
          this.isLoading = true;
          await inactiveFormApi
            .InactiveApplicationForm(
              this.inactivePayload.fillStatus,
              this.inactivePayload,
              this.currentRoute.split("/")[2]
            )
            .then((result: any) => {
              this.isLoading = false;
              const someData = {
                title: "Certified Family Support Specialist Program",
                SubTitle: "Certification: Inactive Application",
              };
              this.$router.push({
                path: "/form/submitted",
                query: {
                  subTitle: someData.SubTitle,
                },
              });
              this.isLoading = false;
              const el = document.getElementById(this.mainId);
              if (el) {
                el.scrollIntoView({ behavior: "smooth", block: "end" });
              }
              localStorage.removeItem("inactiveFormPayload");
              localStorage.removeItem("renewalApplicationId");
            })
            .catch((error) => {
              this.errorMessage = utils.errorMessage(error);
              this.isLoading = false;
            });
        } else {
          this.isLoading = false;
          setTimeout(() => {
            const el = document.getElementsByClassName("is-invalid")[0];
            if (el) {
              el.scrollIntoView({ behavior: "smooth", block: "end" });
            }
          }, 10);
        }
      } else {
        setTimeout(() => {
          const el = document.getElementsByClassName("is-invalid")[0];
          if (el) {
            el.scrollIntoView({ behavior: "smooth", block: "end" });
          }
        }, 50);
      }
    });
  }

  public closePopup() {
    this.$bvModal.hide("error-modal-center");
  }

  public onlyAlphabets(evt: any, fieldName = "") {
    utils.onlyAlphabets(evt, fieldName);
  }

  public fieldValidation(
    value: any,
    eventName: string,
    fieldType: string,
    maxLength = 0,
    fieldName = ""
  ) {
    if (fieldType === "number") {
      this.eventBind[eventName](value, maxLength);
    } else if (fieldName === "state") {
      this.eventBind[eventName](value, fieldName);
    } else {
      this.eventBind[eventName](value);
    }
  }

  public closdedErrorPopup(event: any) {
    if (event) {
      this.errorMessage = "";
    }
  }

  public emptyEvent() {
    return;
  }

  public classNameChooser(inputClass: string) {
    return utils.classNameChooser(inputClass);
  }
  public goBack() {
    this.$router.push({
      path: `/home`,
    });
    const el = document.getElementById("header");
    if (el) {
      el.classList.add("active");
      el.classList.remove("remove-container");
    }
  }
}
